import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  NameFormFields as CardNameFields,
  BillingAddressFormFields as CardBillingAddressFields,
  CreditCardFormFields as CardPaymentInfoFields,
} from '../../../Elements/IrisForms'
import IrisButton from '../../../Elements/IrisButton'
import { updateCardBySubscriptionId } from '../../../../actions'
import { _createStripeToken } from './util'
import { validateOnClient, validateOnServer } from './validate'
import './CardDetailsForm.css'

class CardDetailsForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    onSuccessfulUpdate: PropTypes.func.isRequired,
    updateCardBySubscriptionId: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  state = { error: '' }

  updateCard = data => {
    this.setState({ error: '' })

    const cardData = {
      // Card numeric details
      number: data.cardNumber,
      cvc: data.cvc,
      exp_month: data.month,
      exp_year: data.year,

      // Card billing info
      name: `${data.firstName} ${data.lastName}`,
      address_line1: data.addressLine1,
      address_line2: data.addressLine2,
      address_city: data.city,
      address_state: data.state,
      address_zip: data.zipcode,
      address_country: data.country,
    }

    return _createStripeToken(cardData)
      .then(res => this.onTokenCreationSuccess(res.id))
      .catch(err => this.handleErrorMessage(err.message))
  }

  onTokenCreationSuccess = tempStripeToken => {
    // Use token to update card via iris API
    const {
      token,
      orgId,
      subscriptionId,
      updateCardBySubscriptionId,
      onSuccessfulUpdate,
    } = this.props

    return updateCardBySubscriptionId(
      token,
      orgId,
      subscriptionId,
      tempStripeToken
    )
      .then(validateOnServer)
      .then(res => onSuccessfulUpdate())
      .catch(this.handleErrorMessage)
  }

  handleErrorMessage = error =>
    this.setState({ error: `${error} Please try again.` })

  render() {
    const { handleSubmit, submitting } = this.props
    const { error } = this.state
    const submittingClass = submitting ? 'form-submitting' : ''
    return (
      <form
        className={`card-details-form ${submittingClass}`}
        onSubmit={handleSubmit(this.updateCard)}
      >
        <CardNameFields />
        <CardBillingAddressFields />
        <CardPaymentInfoFields />
        {error && (
          <div className="error-container">
            <p className="error">{error}</p>
          </div>
        )}
        <IrisButton
          buttonText="Save Edits"
          onClick={handleSubmit(this.updateCard)}
        />
      </form>
    )
  }
}

const CardDetailsReduxForm = reduxForm({
  form: 'cardDetailsForm',
  validate: validateOnClient,
})(CardDetailsForm)

const mapStateToProps = (state, ownProps) => {
  const { address } = ownProps
  return {
    token: state.auth.token,
    orgId: state.orgs.selectedOrg._id,
    initialValues: { ...address },
  }
}

export default connect(mapStateToProps, { updateCardBySubscriptionId })(
  CardDetailsReduxForm
)
