import React from 'react'
import PropTypes from 'prop-types'
import metadata from './metadata'
import { _calcDaysRemaining } from './util'
import { getFrontendRedirectBaseUrl } from '../../../../../util'
import './TrialProgressView.css'

const TrialProgressView = ({ productType, access }) => {
  const data = metadata[productType]
  const daysLeft = _calcDaysRemaining(access.current_term_end)
  return (
    <div className="trial-progress-view-container">
      <div className="image-container">
        <img src={data.trialImage} alt={data.altText} />
      </div>
      <div className="description-container">
        <p>
          You have <span className="days-left">{daysLeft}</span> days left in
          your trial of {data.trialType}{' '}
        </p>
      </div>
      <div className="cta-container">
        <a href={`${getFrontendRedirectBaseUrl()}/pricing`} target="_blank">
          <button className="iris-cta-button">{data.ctaText}</button>
        </a>
      </div>
      <div className="secondary-cta-container">
        <a href="mailto:sales@irisvr.com">Talk to our Team</a>
      </div>
    </div>
  )
}

TrialProgressView.propTypes = {
  productType: PropTypes.string.isRequired,
  access: PropTypes.shape({}).isRequired,
}

export default TrialProgressView
