import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TrialStartView from './TrialStartView'
import TrialProgressView from './TrialProgressView'
import TrialExpiredView from './TrialExpiredView'
import './Trial.css'

class TrialContainer extends Component {
  static propTypes = {
    productType: PropTypes.string.isRequired,
    access: PropTypes.shape({}).isRequired,
  }

  state = {
    planAccess: {},
    userTrialType: '',
    userType: '',
  }

  componentWillMount() {
    const { access, productType } = this.props
    const planAccess = this.getPlanAccess(access, productType)
    this.getUserTrialTypeFromAccess(planAccess)
  }

  componentWillReceiveProps(nextProps) {
    const { access, productType } = nextProps
    const planAccess = this.getPlanAccess(access, productType)
    this.getUserTrialTypeFromAccess(planAccess)
  }

  getPlanAccess = (access, productType) => {
    const planAccess = access.access.plan_access[productType]
    const userType = access.org_id ? 'org' : 'personal'
    this.setState({ planAccess, userType })
    return planAccess
  }

  getUserTrialTypeFromAccess(planAccess) {
    let userTrialType = ''
    const noAccess = !planAccess.access_level && !planAccess.status
    if (noAccess) userTrialType = ''
    const isActiveTrial =
      planAccess.is_trial &&
      planAccess.status === 'in_trial' &&
      planAccess.has_access
    if (isActiveTrial) userTrialType = 'active'
    const isExpiredTrial =
      planAccess.is_trial &&
      planAccess.status === 'non_renewing' &&
      !planAccess.has_access
    if (isExpiredTrial) userTrialType = 'expired'
    this.setState({ userTrialType })
  }

  renderViewType = ({ planAccess, productType, userTrialType, userType }) => {
    switch (userTrialType) {
      case 'active':
        return (
          <TrialProgressView productType={productType} access={planAccess} />
        )
      case 'expired':
        return <TrialExpiredView productType={productType} />
      default:
        return <TrialStartView productType={productType} userType={userType} />
    }
  }

  render() {
    const { planAccess, userTrialType, userType } = this.state
    const { productType } = this.props
    return (
      <div className="trial-view-container">
        {this.renderViewType({
          planAccess,
          productType,
          userTrialType,
          userType,
        })}
      </div>
    )
  }
}

export default TrialContainer
