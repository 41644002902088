import {
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/auth`

export const verifyTokenRequest = () => ({
  type: VERIFY_TOKEN_REQUEST,
})

export const verifyTokenSuccess = payload => {
  return {
    type: VERIFY_TOKEN_SUCCESS,
    payload,
  }
}

export const verifyTokenFailure = error => {
  return {
    type: VERIFY_TOKEN_FAILURE,
    payload: { error },
  }
}

export const verifyToken = (token, userId) => dispatch => {
  dispatch(verifyTokenRequest())
  if (!token || !userId) {
    return Promise.resolve(dispatch(verifyTokenFailure()))
  }
  const url = `${baseurl}/token/refresh/${userId}`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(verifyTokenSuccess(payload)))
    .catch(e => {
      switch (e.code) {
        case 203:
          return dispatch(
            verifyTokenFailure({
              statusText: e.error_message,
            })
          )
        case 207:
          return dispatch(
            verifyTokenFailure({
              statusText: e.error_message,
            })
          )
        default:
          return dispatch(
            verifyTokenFailure({
              statusText: 'Failed to verify cached token',
            })
          )
      }
    })
}
