import { createApolloFetch } from 'apollo-fetch'
import { getConfig } from '.'
import { getIrisJWT, httpLink } from '../../index'

export const fetch = createApolloFetch({
  link: httpLink,
  uri: getConfig().graphQLURI,
})

fetch.use(({ request, options }, next) => {
  if (!options.headers) {
    options.headers = {}
  }
  var jwt = getIrisJWT()
  options.headers['authorization'] = `Bearer ${jwt}`
  next()
})

export const disconnectForge = ({ onSuccess = () => {} }) => {
  fetch({
    query: `
            mutation {
                result: ForgeDisconnect {
                    success
                    message
                }
            }
        `,
  }).then(res => {
    if (res.errors && res.errors.length > 0) {
      console.error(res.errors[0])
      onFailure(res)
    } else {
      onSuccess({ result: res.data.result })
    }
  })
}

export const exchangeAccessCode = ({
  code,
  irisToken,
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  fetch({
    query: `
        mutation ForgeExchangeCode($code: string!, $token: string!) {
            result: ForgeExchangeCode(code: $code, token: $token) {
                message
                status
                success
                token {
                    accessToken
                    expiry
                    refreshToken
                    tokenType
                }
                profile {
                    email
                    countryCode
                    emailVerified
                    firstName
                    language
                    lastModified
                    lastName
                    optin
                    twoFaEnabled
                    userId
                    userName
                }
            }
        }
        `,
    variables: {
      token: '',
      code: code,
    },
  }).then(res => {
    console.log(res)
    if (res.errors && res.errors.length > 0) {
      console.error(res.errors[0])
      onFailure(res)
    } else {
      onSuccess({ data: res.data.result })
    }
  })
}
