import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSubscriptionsForOrg, getOrgLicenses } from '../../../actions'
import IrisTitleContainer from '../../Elements/IrisTitleContainer'
import BillingContactFooter from '../../Shared/PageFooter/BillingContactFooter'
import SubscriptionsGrid from './SubscriptionsGrid'
import './Billing.css'

class BillingPage extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      getSubscriptionsForOrg: PropTypes.func.isRequired,
      getOrgLicenses: PropTypes.func.isRequired,
    }).isRequired,
    subscriptions: PropTypes.array.isRequired,
    licenses: PropTypes.array.isRequired,
  }

  componentWillMount() {
    const { token, orgId, actions } = this.props
    actions
      .getOrgLicenses(token, orgId)
      .then(() => actions.getSubscriptionsForOrg(token, orgId))
  }

  render() {
    const { subscriptions, licenses } = this.props
    return (
      <div className="billing-page app-inner-content">
        <div className="billing-view-container">
          <IrisTitleContainer title="Billing" />
          <SubscriptionsGrid
            subscriptions={subscriptions}
            licenses={licenses}
          />
        </div>
        <BillingContactFooter />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  orgId: state.orgs.selectedOrg._id,
  subscriptions: state.orgs.subscriptions,
  licenses: state.orgs.licenses,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSubscriptionsForOrg,
      getOrgLicenses,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage)
