import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}
  if (!values.currentPassword)
    errors.currentPassword = 'Please enter your current password'
  if (!values.newPassword) errors.newPassword = 'Please enter your new password'
  if (values.newPassword && values.newPassword.length < 8)
    errors.newPassword = 'Your password must be at least 8 characters long'
  if (!values.confirmNewPassword)
    errors.confirmNewPassword = 'Please confirm your new password'
  if (values.confirmNewPassword && values.confirmNewPassword.length < 8)
    errors.confirmNewPassword =
      'Your password must be at least 8 characters long'
  if (values.newPassword !== values.confirmNewPassword)
    errors.confirmNewPassword = 'Your password must match'

  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (
      response.payload &&
      response.payload.error &&
      response.payload.error.field
    ) {
      throw new SubmissionError({
        [response.payload.error.field]: response.payload.error.statusText,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
