import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { resendEmailInvite } from '../../../actions'
import IrisButton from '../../Elements/IrisButton'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './ResendEmailInviteModal.css'

class ResendEmailInviteModal extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    resendEmailInvite: PropTypes.func.isRequired,
  }

  state = { emailSent: false }

  resendEmailInvite = () => {
    const { token, orgId, userId, resendEmailInvite } = this.props
    return resendEmailInvite(token, orgId, userId).then(() =>
      this.setState({ emailSent: true })
    )
  }

  render() {
    const { username } = this.props
    const { emailSent } = this.state

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Remind User to Join Your Org</p>
        </div>
        <div className="modal-body-container">
          <p className="body-content">
            Click the button below to resend an email invitation to{' '}
            <span className="emphasis">{username}</span>
          </p>
        </div>
        <IrisButton
          buttonText="Resend Invite"
          onClick={this.resendEmailInvite}
        />
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage
        text={`A reminder email is on the way for ${username}`}
      />
    )

    return (
      <div className="resend-email-invite-modal">
        <IrisTransition
          didTransition={emailSent}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

export default connect(null, { resendEmailInvite })(ResendEmailInviteModal)
