import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Grid from '../../../../Shared/Grid'
import GridHeader from '../../../../Shared/Grid/GridHeader'
import LicenseGridRowList from '../../../../Shared/Grid/GridRowList/LicenseGridRowList'
import NullLicensesView from '../../../../Shared/NullLicensesView'
import { _extractRelevantMetadata } from './util'
import { _capitalize } from '../../../../../util'

class LicensesGrid extends Component {
  static propTypes = {
    productType: PropTypes.string.isRequired,
    licenses: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }

  state = { licenses: [] }

  componentWillMount() {
    const { productType, licenses } = this.props
    this.extractRelevantMetadata(productType, licenses)
  }

  componentWillReceiveProps(nextProps) {
    const { productType, licenses } = nextProps
    this.extractRelevantMetadata(productType, licenses)
  }

  extractRelevantMetadata(productType, licenses) {
    const formattedLicenses = _extractRelevantMetadata(productType, licenses)
    this.setState({ licenses: formattedLicenses })
  }

  render() {
    const { licenses } = this.state
    const { isAdmin, productType } = this.props
    const productName = _capitalize(productType)
    return (
      <Grid>
        <GridHeader
          columns={[
            {
              label: 'Access',
              className: 'license-type',
            },
            {
              label: 'Start',
              className: 'term-start',
            },
            {
              label: 'Term End',
              className: 'term-end',
            },
            {
              label: 'Status',
              className: 'subscription-status',
            },
          ]}
        />
        {licenses.length ? (
          <LicenseGridRowList
            rowData={licenses}
            rowColumns={[
              {
                dataKey: 'name',
                className: 'license-type',
              },
              {
                dataKey: 'startDate',
                className: 'term-start',
              },
              {
                dataKey: 'endDate',
                className: 'term-end',
              },
              {
                dataKey: 'status',
                className: 'subscription-status',
              },
            ]}
          />
        ) : isAdmin ? (
          <NullLicensesView>
            <p>
              You don't have access to any licenses to {productName}. You can
              manage your licenses <Link to="/licenses">here</Link>
            </p>
          </NullLicensesView>
        ) : (
          <NullLicensesView>
            <p>You don't have access to any licenses {productName}.</p>
            <p>Contact your IrisVR Account Admin to get access.</p>
          </NullLicensesView>
        )}
      </Grid>
    )
  }
}

export default LicensesGrid
