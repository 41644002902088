import { getPersonalUserData } from '../user'
import { getOrgsForUser, selectOrg } from '../orgs'
import {
  getPersonalUserSubscriptionStatus,
  getUserSubscriptionStatusWithinOrg,
  getUserLicensesWithinOrg,
} from '../billing'

// Natural login flow:
// 1) Get user data with token
// 2) Get orgs for user
// 3a) If no orgs are detected, fetch their personal subscription status
// 3b) If a single org is detected, auto-fetch subscriptions and licenses for that org
// 3c) If multiple orgs are detected, presumably display selection view
export const fetchRelevantUserAndOrgData = token => (dispatch, getState) =>
  dispatch(getPersonalUserData(token))
    .then(() => dispatch(getOrgsForUser(token)))
    .then(() => {
      const state = getState()
      const userId = state.auth.user && state.auth.user._id
      const orgs = state.orgs.orgs
      const numOrgs = orgs.length
      if (!numOrgs) {
        return dispatch(getPersonalUserSubscriptionStatus(token))
      } else if (numOrgs === 1) {
        // Auto-select the single org and push user through
        return dispatch(
          selectOrgAndFetchRelevantBillingData(token, orgs[0]._id, userId)
        )
      } else if (numOrgs > 1) {
        // This situation is handled in the main Login.js
        // component. In the future we probably want to do
        // something in the store.
      }
    })

// Shortcut for selecting an org and fetching the user's
// subscription + license status within the org
export const selectOrgAndFetchRelevantBillingData = (
  token,
  orgId,
  userId
) => dispatch =>
  dispatch(selectOrg(orgId))
    .then(() =>
      dispatch(getUserSubscriptionStatusWithinOrg(token, orgId, userId))
    )
    .then(() => dispatch(getUserLicensesWithinOrg(token, orgId, userId)))

// Used if user knows which org they're trying to access
export const fetchUserAndOrgData = (token, orgId, userId) => dispatch =>
  dispatch(getPersonalUserData(token))
    .then(() => dispatch(getOrgsForUser(token)))
    .then(() =>
      dispatch(selectOrgAndFetchRelevantBillingData(token, orgId, userId))
    )
