import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { registerLicense } from '../../../actions/orgs/licenses'
import AssignLicenseForm from './AssignLicenseForm'
import { validateOnServer } from './validate'
import './AssignLicenseModal.css'

class AssignLicenseModal extends Component {
  state = { error: '' }

  assignLicenseToUser = data => {
    const { token, orgId, licenseId, registerLicense, closeModal } = this.props
    return registerLicense(token, orgId, licenseId, data.accessId)
      .then(validateOnServer)
      .then(closeModal)
      .catch(e => {
        const error =
          'Failed to assign the license due to lack of floating license, invalid subscription ID or invalid access document ID. Please contact support@irisvr.com if this problem persists.'
        this.setState({ error })
        throw e
      })
  }

  linkToTeamPage = () => {
    this.props.closeModal()
    this.context.router.history.push('/team')
  }

  render() {
    const { licenseType, orgUsers } = this.props
    const { error } = this.state
    return (
      <div className="assign-license-modal">
        <div className="modal-title-container">
          <p className="modal-title">Assign {licenseType} License:</p>
        </div>
        <div className="modal-body-container">
          <AssignLicenseForm
            orgUsers={orgUsers}
            assignLicense={this.assignLicenseToUser}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="footer-container">
          <p>
            Don't see who you're looking for? Make sure they've been added to
            your <a onClick={this.linkToTeamPage}>Team</a>.
          </p>
        </div>
      </div>
    )
  }
}

AssignLicenseModal.contextTypes = {
  router: PropTypes.shape({}).isRequired,
}

AssignLicenseModal.propTypes = {
  token: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  licenseId: PropTypes.string.isRequired,
  licenseType: PropTypes.string.isRequired,
  orgUsers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      accessId: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  registerLicense: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  token: state.auth.token,
})

export default connect(mapStateToProps, { registerLicense })(AssignLicenseModal)
