import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ApolloProvider, gql, useQuery } from '@apollo/client'

import { GetClient } from './client'
import { actions } from './actions'

import { SummaryView } from '../bim360'
import { ErrorDisplay } from '../iris-ui/errors'

export { Callback } from './exchangeToken'
export { getConfig } from './config'

export {
  DebugFeaturesToggleList,
  DebugFeaturesToggleListRaw,
} from './components/debug'

const BaseView = props => {
  return (
    <ApolloProvider client={GetClient({ token: props.token })}>
      {/* Purpose: to Render the currently available projects/models/views available in BIM360 */}
      <BIM360FileBrowser
        enabled={props.enabled}
        expandedProjectIds={props.expandedProjectIds}
        handleModuleClick={props.handleModuleClick}
        setSyncState={props.setSyncState}
        onProjectSelected={props.onProjectSelected}
      />
    </ApolloProvider>
  )
}

const BIM360FileBrowser = ({}) => {
  const projectsQueryDefaultState = {
    identity: {
      userId: '',
    },
    oAuthConfig: {
      authScope: '',
      redirectUrl: '',
      clientId: '',
      apiUrl: '',
    },
    projects: [],
  }
  const projectsQuery = gql`
    query {
      bim360 {
        oAuthConfig: properties {
          authScope
          redirectUrl
          clientId
          apiUrl: authUrl
        }
        identity: me {
          firstName
          lastName
          email
          userId
          userName
        }
        projects {
          id
          attributes {
            name
          }
          rootFolder {
            id
          }
        }
      }
    }
  `
  const { loading, error, data } = useQuery(projectsQuery, {
    variables: {},
    pollInterval: 10 * 1000,
  })

  var integrationData

  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (loading) {
    // if we are still loading pass along a default state
    integrationData = projectsQueryDefaultState
  } else {
    integrationData = data.bim360
  }
  return <Browser data={integrationData} />
}

export const Browser = connect(
  state => {
    const syncEnabledItemIdErrorMessages = Object.keys(
      state.integrations.syncEnabledItems
    ).reduce((acc, key) => {
      const item = state.integrations.syncEnabledItems[key]
      const itemErrorMessage = item && item['errorMessage']
      if (Boolean(itemErrorMessage)) {
        return { ...acc, [key]: itemErrorMessage }
      }
      return acc
    }, {})
    return {
      syncEnabledItemIdsCurrentlyLoading:
        state.integrations.syncEnabledItemIdsCurrentlyLoading,
      syncEnabledItemIdErrorMessages: syncEnabledItemIdErrorMessages,
      expandedProjectIds: state.integrations.expandedProjectIds,
      enabled: true,
      expanded: true,
      searchQuery: state.integrations.searchQuery,
    }
  },
  dispatch => ({
    onProjectSelected: ({ id }) => {
      // alert(`Connected onProjectSelected ${id}`)
      dispatch(actions.setExpandedState({ id }))
    },
    onModelViewsToggle: ({ id }) => {
      console.log(`onModelViewsToggle ${id}`)
      dispatch(actions.setExpandedState({ id }))
    },
    setSyncState: props => {
      // const { urn, projectID, enabled } = props
      // alert(`&&& !!!!! set sync ${enabled === true ? "ENABLED" : "DISABED"} for urn: ${urn} projectID: ${projectID} `)
      console.log(props)
      dispatch(
        actions.setSyncState({
          payload: props,
          dispatch: dispatch,
        })
      )
    },
    disconnectIntegration: () => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          modalType: 'confirmationModal',
          modalProps: {
            buttons: [
              {
                label: 'Cancel',
                onClick: () => {},
              },
              {
                label: 'Unlink',
                onClick: () => {
                  actions.disconnectIntegration()
                },
              },
            ],
          },
        },
      })
    },
    updateSyncEnabledItemsLoadingList: itemsList => {
      dispatch(actions.updateSyncEnabledItemsLoadingList(itemsList, dispatch))
    },
    addSyncEnabledItemIdToLoadingList: syncEnabledItemId => {
      dispatch(actions.addSyncEnabledItemIdToLoadingList(syncEnabledItemId))
    },

    handleModuleClick: props => {
      // alert('handleModuleClick')
      dispatch(
        actions.setExpandedState({
          id: 'bim360_integration',
          expanded: true,
        })
      )
    },
    searchQueryUpdated: ({ query }) => {
      dispatch(actions.setSearchQuery(query))
    },
  })
)(SummaryView)

// Enhance the base view with the auth token in the root context
export const BIM360Integration = connect(
  state => ({
    token: state.auth.token,
    pollingEnabled: state.integrations.pollingEnabled,
    pollingInterval: state.integrations.pollingInterval,
    showSyncItemSummary: state.integrations.showSyncItemSummary,
  }),
  dispatch => ({
    hydrateSyncState: syncState => {
      console.log('Base received hydrateSyncState', syncState)
      dispatch(actions.hydrateSyncState(syncState))
    },
    setPollingState: enabled => {
      console.log('Set Polling State', enabled)
      dispatch(actions.setPollingEnabled(enabled))
    },
    setPollingInterval: delayInMs => {
      dispatch(actions.setPollingInterval(delayInMs))
    },
    setSyncItemSummaryVisible: isVisible => {
      dispatch(actions.setSyncItemSummaryVisible(isVisible))
    },
    openDebugModal: () => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          modalType: 'debugModal',
        },
      })
    },
    openSuccessModal: () => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          modalType: 'genericModal',
        },
      })
    },
  })
)(BaseView)

export const IntegrationsView = props => (
  <div
    style={{
      position: 'absolute',
      left: 260,
      top: 60,
    }}
  >
    <div
      style={{
        marginLeft: 60,
        color: '#534DC9',
        marginBottom: 40,
        marginTop: 48,
      }}
    >
      <h1>
        <div style={{ fontSize: 42 }}>Integrations</div>
      </h1>
    </div>
    <div
      style={{
        minWidth: 800,
      }}
    >
      {(isDevelopmentEnvironment || isStagingEnvironment) && (
        <button
          style={{
            position: 'fixed',
            left: 30,
            bottom: 40,
            borderRadius: 8,
            zIndex: 2,
            border: 0,
            cursor: 'pointer',
            width: 180,
            height: 40,
            border: '2px solid black',
          }}
          onClick={props.openDebugModal}
        >
          {'🐜 Debug Settings'}
        </button>
      )}
      <BIM360Integration />
    </div>
  </div>
)

export const Integrations = connect(
  state => ({}),
  dispatch => ({
    openDebugModal: () => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          modalType: 'debugModal',
        },
      })
    },
    openSuccessModal: () => {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          modalType: 'genericModal',
        },
      })
    },
  })
)(IntegrationsView)

export const isDevelopmentEnvironment = process.env.NODE_ENV === 'development'
const isStagingEnvironment = process.env.NODE_ENV === 'staging'

export default Integrations
