import React from 'react'
import PropTypes from 'prop-types'
import Section from './SectionContainers/Section'
import metadata from './metadata'
import './ProductBanner.css'

const ProductBanner = ({ productType }) => {
  // Only display the library section if user is on Scope tab
  const sectionData = metadata[productType]
  const sectionTypes = [
    ...(productType === 'scope' ? ['library'] : []),
    'download',
    'support',
  ]

  return (
    <div className="product-banner">
      {sectionTypes.map(type => (
        <Section
          key={type}
          productType={productType}
          sectionType={type}
          {...sectionData[type]}
        />
      ))}
    </div>
  )
}

ProductBanner.propTypes = {
  productType: PropTypes.string.isRequired,
}

export default ProductBanner
