// parses a URL query string (e.g., '?foo=bar') to an object (e.g., {foo: 'bar'}).
export const _searchToJson = search => {
  const params = {}
  if (!search) return params
  const stripped = search.replace(/^\?/, '')
  const splitAmp = stripped.split('&')
  splitAmp.forEach(param => {
    const splitEq = param.split(/=(.+)/)
    params[splitEq[0]] = splitEq[1]
  })
  return params
}
