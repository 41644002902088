import {
  REQUEST_SIGNED_URL_FOR_UPLOAD_REQUEST,
  REQUEST_SIGNED_URL_FOR_UPLOAD_SUCCESS,
  REQUEST_SIGNED_URL_FOR_UPLOAD_FAILURE,
  UPLOAD_FILE_TO_SIGNED_URL_REQUEST,
  UPLOAD_FILE_TO_SIGNED_URL_SUCCESS,
  UPLOAD_FILE_TO_SIGNED_URL_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/orgs`

export const requestSignedUrlForUploadRequest = () => ({
  type: REQUEST_SIGNED_URL_FOR_UPLOAD_REQUEST,
})

export const requestSignedUrlForUploadSuccess = url => ({
  type: REQUEST_SIGNED_URL_FOR_UPLOAD_SUCCESS,
  payload: url,
})

export const requestSignedUrlForUploadFailure = error => ({
  type: REQUEST_SIGNED_URL_FOR_UPLOAD_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const requestSignedUrlForUpload = (
  token,
  orgId,
  extension
) => dispatch => {
  dispatch(requestSignedUrlForUploadRequest())
  const url = `${baseurl}/${orgId}/logo/signed-url?extension=${extension}`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(requestSignedUrlForUploadSuccess(payload.signedUrl))
    )
    .catch(error => dispatch(requestSignedUrlForUploadFailure(error)))
}

export const uploadFileToSignedUrlRequest = () => ({
  type: UPLOAD_FILE_TO_SIGNED_URL_REQUEST,
})

export const uploadFileToSignedUrlSuccess = url => ({
  type: UPLOAD_FILE_TO_SIGNED_URL_SUCCESS,
  payload: url,
})

export const uploadFileToSignedUrlFailure = error => ({
  type: UPLOAD_FILE_TO_SIGNED_URL_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const uploadFileToSignedUrl = (url, file) => dispatch => {
  dispatch(uploadFileToSignedUrlRequest())

  const req = request
    .put(url)
    .set('Content-Type', file.type)
    .set('x-goog-acl', 'public-read')
    .send(file)

  return req
    .end()
    .then(() => dispatch(uploadFileToSignedUrlSuccess(url.split('?')[0])))
    .catch(e => dispatch(uploadFileToSignedUrlFailure(e.message)))
}
