import React, { useState } from 'react'

import SummaryView from './summary'
import { ProjectModelViewsList } from './projectModels'
import { SearchBar } from './search-bar'

import Chevron from './chevron'
import { ProjectsZeroState } from './project'
import OpenInBIM360Icon from './elements/openInBim360Icon'

export { Chevron, SummaryView }
export { ProjectDetailView } from './project'

export const Folder = ({ color = '#B5B5B5' }) => (
  // eslint-disable-next-line
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g x="0" y="0" width="24" height="24">
      // eslint-disable-next-line
      <path
        d="M12 5H20C21.1 5 22 5.9 22 7V17C22 18.1 21.1 19 20 19H4C2.9 19 2 18.1 2 17L2.01 5C2.01 3.9 2.9 3 4 3H10L12 5Z"
        fill={color}
      />
    </g>
  </svg>
)

export const RootProjects = ({
  items, // items = `projects` from query
  activeProjectId,
  expandedProjectIds,
  setSyncState,
  addSyncEnabledItemIdToLoadingList,
  updateSyncEnabledItemsLoadingList,
  syncEnabledItemIdsCurrentlyLoading,
  syncEnabledItemIdErrorMessages,
  onProjectSelected,
  onModelViewsToggle,
  searchQuery = '',
  searchQueryUpdated = ({ query }) => console.log('Filter: ', query),
}) => {
  return items.length == 0 ? (
    <ProjectsZeroState />
  ) : (
    <div style={{ border: '1px solid #C4C4C4' }}>
      <SearchBar query={searchQuery} onChange={searchQueryUpdated} />
      <ProjectListing
        items={items}
        expandedProjectIds={expandedProjectIds}
        onProjectSelected={onProjectSelected}
        onModelViewsToggle={onModelViewsToggle}
        setSyncState={setSyncState}
        addSyncEnabledItemIdToLoadingList={addSyncEnabledItemIdToLoadingList}
        updateSyncEnabledItemsLoadingList={updateSyncEnabledItemsLoadingList}
        syncEnabledItemIdsCurrentlyLoading={syncEnabledItemIdsCurrentlyLoading}
        syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
        searchQuery={searchQuery}
      />
    </div>
  )
}

const ProjectListing = ({
  items, // `projects`
  onProjectSelected,
  onModelViewsToggle,
  activeProjectId,
  expandedProjectIds,
  setSyncState,
  addSyncEnabledItemIdToLoadingList,
  updateSyncEnabledItemsLoadingList,
  syncEnabledItemIdsCurrentlyLoading,
  syncEnabledItemIdErrorMessages,
  searchQuery = '',
}) => {
  return items.map(project => {
    const { id } = project
    // console.log(expandedProjectIds)
    const expanded = expandedProjectIds.includes(id)

    // TODO: filtering should likely be done inside a reducer
    if (searchQuery.length > 0) {
      const terms = searchQuery.toLowerCase()
      const filteredProject = {
        ...project,
        models:
          (project.models &&
            project.models.filter(
              entry => entry.filename.toLowerCase().indexOf(terms) !== -1
            )) ||
          [],
      }
      if (
        filteredProject.models.length === 0 &&
        project.attributes.name.toLowerCase().indexOf(terms) === -1
      ) {
        return
      }
      return (
        <ProjectCard
          id={id}
          key={id}
          selected={id === activeProjectId}
          expanded={expanded}
          expandedIds={expandedProjectIds}
          project={filteredProject}
          searchQuery={searchQuery}
          onProjectSelected={onProjectSelected}
          onModelViewsToggle={onModelViewsToggle}
          setSyncState={setSyncState}
          addSyncEnabledItemIdToLoadingList={addSyncEnabledItemIdToLoadingList}
          updateSyncEnabledItemsLoadingList={updateSyncEnabledItemsLoadingList}
          syncEnabledItemIdsCurrentlyLoading={
            syncEnabledItemIdsCurrentlyLoading
          }
          syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
        />
      )
    }

    return (
      <ProjectCard
        id={id}
        key={id}
        selected={id === activeProjectId}
        expanded={expanded}
        expandedIds={expandedProjectIds}
        project={project}
        searchQuery={searchQuery}
        onProjectSelected={onProjectSelected}
        onModelViewsToggle={onModelViewsToggle}
        setSyncState={setSyncState}
        addSyncEnabledItemIdToLoadingList={addSyncEnabledItemIdToLoadingList}
        updateSyncEnabledItemsLoadingList={updateSyncEnabledItemsLoadingList}
        syncEnabledItemIdsCurrentlyLoading={syncEnabledItemIdsCurrentlyLoading}
        syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
      />
    )
  })
}

export const ProjectCard = props => {
  const [isHovered, setIsHovered] = useState(false)

  const {
    project,
    id,
    selected = false,
    expanded,
    setSyncState,
    addSyncEnabledItemIdToLoadingList,
    updateSyncEnabledItemsLoadingList,
    syncEnabledItemIdsCurrentlyLoading,
    syncEnabledItemIdErrorMessages,
    onProjectSelected,
    onModelViewsToggle,
    expandedIds,
  } = props

  const { attributes } = project
  const projectId = id.substr(2)
  const projectFilesFolderId = project.rootFolder && project.rootFolder.id
  const bim360FolderURL =
    'https://docs.b360.autodesk.com/projects/' +
    projectId +
    '/folders/' +
    projectFilesFolderId +
    '/detail'

  return (
    <div
      key={id}
      style={{
        position: 'relative',
        minHeight: 20,
        left: 0,
        width: '100%',
        background: 'transparent',
        color: 'black',
        marginBottom: 2,
      }}
    >
      <div>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            backgroundColor: '#FBFAFD',
            fontSize: 16,
            paddingTop: 12,
            paddingBottom: 11,
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #F4F4F4',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              onProjectSelected({ id: id })
            }}
          >
            <div
              style={{
                marginRight: 12,
                marginLeft: 17,
              }}
            >
              <Chevron direction={expanded ? 'down' : 'right'} />
            </div>
            <div
              style={{
                marginRight: 12,
                marginBottom: -6,
              }}
            >
              <Folder color={selected ? '#4D9DE3' : '#B5B5B5'} />
            </div>
            <div style={{ fontWeight: 'bold' }}>{attributes.name}</div>
          </div>
          {isHovered && (
            <span style={{ marginLeft: 20 }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(bim360FolderURL, projectId)
                }}
              >
                <OpenInBIM360Icon />
              </span>
            </span>
          )}
        </div>
        {expanded && (
          <ProjectModelViewsList
            projectId={id}
            expandedIds={expandedIds}
            setSyncState={setSyncState}
            onModelViewsToggle={onModelViewsToggle}
            addSyncEnabledItemIdToLoadingList={
              addSyncEnabledItemIdToLoadingList
            }
            syncEnabledItemIdsCurrentlyLoading={
              syncEnabledItemIdsCurrentlyLoading
            }
            updateSyncEnabledItemsLoadingList={
              updateSyncEnabledItemsLoadingList
            }
            syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
          />
        )}
      </div>
    </div>
  )
}
