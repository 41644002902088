import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getOrgUsersAndWorkstations, getOrgLicenses } from '../../../actions'
import LicensesGrid from './LicensesGrid'
import IrisTitleContainer from '../../Elements/IrisTitleContainer'
import BillingContactFooter from '../../Shared/PageFooter/BillingContactFooter'
import './Licenses.css'

class LicensesPage extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    orgEntities: PropTypes.shape({
      users: PropTypes.array.isRequired,
      workstations: PropTypes.array.isRequired,
    }).isRequired,
    orgLicenses: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      getOrgUsersAndWorkstations: PropTypes.func.isRequired,
      getOrgLicenses: PropTypes.func.isRequired,
    }).isRequired,
  }

  componentWillMount() {
    const { token, orgId, actions } = this.props
    actions.getOrgUsersAndWorkstations(token, orgId)
    actions.getOrgLicenses(token, orgId)
  }

  render() {
    const { orgId, orgEntities, orgLicenses } = this.props
    return (
      <div className="licenses-page app-inner-content">
        <div className="licenses-view-container">
          <IrisTitleContainer title="Licenses" />
          <LicensesGrid
            orgId={orgId}
            orgEntities={orgEntities}
            licenses={orgLicenses}
          />
        </div>
        <BillingContactFooter />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  orgId: state.orgs.selectedOrg._id,
  orgEntities: state.orgs.orgEntities,
  orgLicenses: state.orgs.licenses,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getOrgUsersAndWorkstations,
      getOrgLicenses,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(LicensesPage)
