import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { renameWorkstation } from '../../../../../actions'
import { validateOnClient, validateOnServer } from './validate'
import { IrisTextField } from '../../../../Elements/IrisFormFields'
import IrisButton from '../../../../Elements/IrisButton'
import './RenameWorkstationForm.css'

class RenameWorkstationForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    workstationId: PropTypes.string.isRequired,
    renameWorkstation: PropTypes.func.isRequired,
    onRenameSuccess: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  renameWorkstation = data => {
    const {
      token,
      orgId,
      workstationId,
      renameWorkstation,
      onRenameSuccess,
    } = this.props
    return renameWorkstation(token, orgId, workstationId, data)
      .then(validateOnServer)
      .then(onRenameSuccess)
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const submittingClass = submitting ? 'form-submitting' : ''
    return (
      <form
        className={`rename-workstation-form ${submittingClass}`}
        onSubmit={handleSubmit(this.renameWorkstation)}
      >
        <div className="form-fields-container">
          <div className="form-fields">
            <IrisTextField name="name" label="Name" fullWidth />
          </div>
        </div>
        <IrisButton
          buttonText="Confirm"
          onClick={handleSubmit(this.renameWorkstation)}
        />
      </form>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  orgId: state.orgs.selectedOrg._id,
})

const RenameWorkstationReduxForm = reduxForm({
  form: 'renameWorkstation',
  validate: validateOnClient,
})(RenameWorkstationForm)

export default connect(mapStateToProps, { renameWorkstation })(
  RenameWorkstationReduxForm
)
