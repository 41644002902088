import {
  GET_PERSONAL_USER_DATA_SUCCESS,
  UPDATE_PERSONAL_USER_DATA_SUCCESS,
} from '../constants'
import { createReducer } from './util'

const initialState = {
  data: {},
}

export default createReducer(initialState, {
  [GET_PERSONAL_USER_DATA_SUCCESS]: (state, payload) => {
    return { data: payload.data }
  },
  [UPDATE_PERSONAL_USER_DATA_SUCCESS]: (state, payload) => {
    return { data: payload.data }
  },
})
