import {
  JOIN_ORG_REQUEST,
  JOIN_ORG_SUCCESS,
  JOIN_ORG_FAILURE,
  JOIN_REQUEST,
  JOIN_SUCCESS,
  JOIN_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = getBaseUrl()

export const joinRequest = () => ({
  type: JOIN_REQUEST,
})

export const joinSuccess = () => ({
  type: JOIN_SUCCESS,
})

export const joinFailure = error => ({
  type: JOIN_FAILURE,
  payload: { error },
})

export const joinOrgRequest = () => ({
  type: JOIN_ORG_REQUEST,
})

export const joinOrgSuccess = () => ({
  type: JOIN_ORG_SUCCESS,
})

export const joinOrgFailure = error => ({
  type: JOIN_ORG_FAILURE,
  payload: { error },
})

export const confirmAccount = ({
  userData,
  orgId,
  inviteToken,
}) => dispatch => {
  const requestAction = orgId ? joinOrgRequest : joinRequest
  const requestSuccess = orgId ? joinOrgSuccess : joinSuccess
  const requestFailure = orgId ? joinOrgFailure : joinFailure
  const url = orgId
    ? `${baseurl}/orgs/${orgId}/users/join/invite-token/${inviteToken}`
    : `${baseurl}/users/join/${inviteToken}`
  dispatch(requestAction())
  return request
    .post(url)
    .type('json')
    .send(userData)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(requestSuccess(payload)))
    .catch(e => {
      switch (e.code) {
        // find more error cases from server
        case 140:
        case 141:
        case 142:
        case 143:
        case 144:
        case 145:
        case 146:
          return dispatch(
            requestFailure({
              statusText: e.error_message,
              field: e.error_field,
            })
          )
        case 502:
          return dispatch(
            requestFailure({
              statusText: e.error_message,
              field: 'username',
            })
          )
        default:
          // Includes 505 expired token
          return dispatch(
            requestFailure({
              statusText: e.error_message || 'Unknown error occurred',
              field: 'terms',
            })
          )
      }
    })
}
