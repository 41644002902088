import React from 'react'
import PropTypes from 'prop-types'

const defaultStyles = {
  fontSize: 14,
  fontWeight: 'bold',
  minHeight: 36,
  minWidth: 144,
  borderRadius: 4,
  paddingLeft: 16,
  paddingRight: 16,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  letterSpacing: 0.5,
  cursor: 'pointer',
}

export const IrisPDLButton = props => {
  const customStyles = {
    width: props.width,
    ...props.style,
  }
  const labelStyles = props.labelStyle ? props.labelStyle : {}
  const noop = () => {}

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        id={props.id}
        style={{
          ...defaultStyles,
          ...customStyles,
        }}
        onClick={props.disabled ? noop : props.onClick}
      >
        <div
          style={{
            textTransform: 'uppercase',
            ...labelStyles,
          }}
        >
          {props.label}
        </div>
      </div>
    </div>
  )
}

IrisPDLButton.propTypes = {
  label: PropTypes.any,
  onClick: PropTypes.func,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  disabled: PropTypes.any,
  id: PropTypes.string,
  secondary: PropTypes.bool,
  width: PropTypes.number,
  icon: PropTypes.any,
}
