import {
  SIGNUP_USER_REQUEST,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = getBaseUrl()

export const signupRequest = () => ({
  type: SIGNUP_USER_REQUEST,
})

export const signupSuccess = () => ({
  type: SIGNUP_USER_SUCCESS,
})

export const signupFailure = error => ({
  type: SIGNUP_USER_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const signup = userData => dispatch => {
  dispatch(signupRequest())
  const url = `${baseurl}/users`
  return request
    .post(url)
    .type('json')
    .send(userData)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(signupSuccess(payload)))
    .catch(e => {
      switch (e.code) {
        case 202:
          return dispatch(
            signupFailure({
              statusText:
                "The username is already registered with IrisVR. If you're looking for a confirmation e-mail, try checking your spam folder.",
              field: 'username',
            })
          )
        case 142:
          return dispatch(
            signupFailure({
              statusText: e.error_message,
              field: e.error_field,
            })
          )
        default:
          if (e.error_field) {
            return dispatch(
              signupFailure({
                statusText: e.error_message,
                field: e.error_field,
              })
            )
          }
          return dispatch(
            signupFailure({
              statusText: e.error_message || 'Unknown error occurred',
              field: 'terms',
            })
          )
      }
    })
}
