import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dismissNotification } from '../../../actions/notifications'
import EULA from './banners/EULA'
import './Notification.css'

class Notification extends Component {
  static propTypes = {
    closed: PropTypes.bool.isRequired,
    dismissNotification: PropTypes.func.isRequired,
  }

  state = {
    closed: false,
  }

  componentWillMount() {
    this.setState({ closed: this.props.closed })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.closed !== this.props.closed) {
      this.setState({ closed: nextProps.closed })
    }
  }

  render() {
    const { closed } = this.state
    const { dismissNotification } = this.props

    if (closed) return null

    return (
      <div className="notification-container">
        <EULA dismiss={dismissNotification} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // closed: state.notifications.dismissed // Uncomment to enable notifications
  closed: true, // Uncomment to disable notifications
})

export default connect(mapStateToProps, {
  dismissNotification,
})(Notification)
