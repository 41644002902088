import {
  ADD_USER_TO_ORG_REQUEST,
  ADD_USER_TO_ORG_SUCCESS,
  ADD_USER_TO_ORG_FAILURE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
  RESEND_EMAIL_INVITE_REQUEST,
  RESEND_EMAIL_INVITE_SUCCESS,
  RESEND_EMAIL_INVITE_FAILURE,
  REMOVE_USER_FROM_ORG_REQUEST,
  REMOVE_USER_FROM_ORG_SUCCESS,
  REMOVE_USER_FROM_ORG_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/orgs`

export const addUserToOrgRequest = () => ({
  type: ADD_USER_TO_ORG_REQUEST,
})

export const addUserToOrgSuccess = payload => ({
  type: ADD_USER_TO_ORG_SUCCESS,
  payload,
})

export const addUserToOrgFailure = error => ({
  type: ADD_USER_TO_ORG_FAILURE,
  payload: { error },
})

export const addUserToOrg = (token, orgId, email) => dispatch => {
  dispatch(addUserToOrgRequest())
  const url = `${baseurl}/${orgId}/users/add/email`
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send({
      email_address: email,
      send_email: true,
    })
    .end()
    .then(parseResponse)
    .then(payload => dispatch(addUserToOrgSuccess(payload)))
    .catch(error => dispatch(addUserToOrgFailure(error)))
}

export const updateUserRoleRequest = () => ({
  type: UPDATE_USER_ROLE_REQUEST,
})

export const updateUserRoleSuccess = access => ({
  type: UPDATE_USER_ROLE_SUCCESS,
  payload: access,
})

export const updateUserRoleFailure = error => ({
  type: UPDATE_USER_ROLE_FAILURE,
  payload: { error },
})

export const updateUserRole = (token, orgId, userId, role) => dispatch => {
  dispatch(updateUserRoleRequest())
  const url = `${baseurl}/${orgId}/users/${userId}/role`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send({ role })
    .end()
    .then(parseResponse)
    .then(payload => dispatch(updateUserRoleSuccess(payload)))
    .catch(error => dispatch(updateUserRoleFailure(error)))
}

export const resendEmailInviteRequest = () => ({
  type: RESEND_EMAIL_INVITE_REQUEST,
})

export const resendEmailInviteSuccess = payload => ({
  type: RESEND_EMAIL_INVITE_SUCCESS,
  payload,
})

export const resendEmailInviteFailure = error => ({
  type: RESEND_EMAIL_INVITE_FAILURE,
  payload: { error },
})

export const resendEmailInvite = (token, orgId, userId) => dispatch => {
  dispatch(resendEmailInviteRequest())
  const url = `${baseurl}/${orgId}/users/${userId}/remind`
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(resendEmailInviteSuccess(payload)))
    .catch(error => dispatch(resendEmailInviteFailure(error)))
}

export const removeUserFromOrgRequest = () => ({
  type: REMOVE_USER_FROM_ORG_REQUEST,
})

export const removeUserFromOrgSuccess = userId => ({
  type: REMOVE_USER_FROM_ORG_SUCCESS,
  payload: { userId },
})

export const removeUserFromOrgFailure = error => ({
  type: REMOVE_USER_FROM_ORG_FAILURE,
  payload: { error },
})

export const removeUserFromOrg = (token, orgId, userId) => dispatch => {
  dispatch(removeUserFromOrgRequest())
  const url = `${baseurl}/${orgId}/users/${userId}/remove`
  return request
    .del(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(removeUserFromOrgSuccess(userId)))
    .catch(error => dispatch(removeUserFromOrgFailure(error)))
}
