import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RenameWorkstationForm from './RenameWorkstationForm'
import './RenameWorkstationModal.css'

class RenameWorkstationModal extends Component {
  static propTypes = {
    workstationId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  render() {
    const { workstationId, closeModal } = this.props

    return (
      <div className="rename-workstation-modal">
        <div className="modal-title-container">
          <p className="modal-title">Rename Workstation</p>
        </div>
        <RenameWorkstationForm
          workstationId={workstationId}
          onRenameSuccess={closeModal}
        />
      </div>
    )
  }
}

export default RenameWorkstationModal
