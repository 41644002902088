import React from 'react'

export const CheckmarkIcon = () =>
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.34863 5.5L5.59254 9.375L14.0803 1.625" stroke="#534DC9" strokeWidth="3" />
    </svg>

export class Checkmark extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hover: false }

        this.mouseOut = this.mouseOut.bind(this)
        this.mouseOver = this.mouseOver.bind(this)
    }
    getInitialState() {
        return { hover: false }
    }
    mouseOver() {
        this.setState({ hover: true })
    }
    mouseOut() {
        this.setState({ hover: false })
    }
    render() {
        return <div
            onMouseOver={this.mouseOver}
            onMouseOut={this.mouseOut}
            style={{
                width: '100%',
                height: 'auto',
            }}
        >
            {this.state.hover === true ? <span style={{ pointerEvents: 'none', color: 'red', fontWeight: 'bold' }}>DISABLE</span> : <CheckmarkIcon />}
        </div>
    }
}
export class CancelHover extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hover: false,
            label: props.label,
        }
        this.mouseOut = this.mouseOut.bind(this)
        this.mouseOver = this.mouseOver.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.label !== nextProps.label) {
            this.setState({ label: nextProps.label })
        }
    }
    mouseOver() {
        this.setState({ hover: true })
    }
    mouseOut() {
        this.setState({ hover: false })
    }
    render() {
        return <div
            onMouseOver={this.mouseOver}
            onMouseOut={this.mouseOut}
            style={{
                width: '100%',
                height: 'auto',
            }}
        >
            {this.state.hover === true ? <span style={{ pointerEvents: 'none', fontWeight: 'bold' }}>{"CANCEL"}</span> : this.state.label}
        </div>
    }
}
