import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserRole, showModal } from '../../../../../actions'
import GridRow from '../../../../Shared/Grid/GridRowList/GridRow'
import './MemberLicenseGridRowList.css'

class MemberLicenseGridRowList extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    access: PropTypes.shape({
      role: PropTypes.number.isRequired,
      user_id: PropTypes.string.isRequired,
    }).isRequired,
    rowData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        role: PropTypes.number.isRequired,
        roleReadable: PropTypes.string.isRequired,
        accessList: PropTypes.string.isRequired,
        isPending: PropTypes.bool.isRequired,
        isActive: PropTypes.bool.isRequired,
      })
    ).isRequired,
    rowColumns: PropTypes.arrayOf(
      PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    actions: PropTypes.shape({
      updateUserRole: PropTypes.func.isRequired,
      showModal: PropTypes.func.isRequired,
    }).isRequired,
  }

  updateUserRole = (role, userId) => {
    const { token, orgId, actions } = this.props
    return actions.updateUserRole(token, orgId, userId, role)
  }

  removeUser = row => {
    const { token, orgId, actions } = this.props
    actions.showModal('removeUserFromOrg', {
      token,
      orgId,
      accessId: row.id,
      userId: row.userId,
      username: row.username.split(' ')[0], // remove 'Pending' if present
    })
  }

  resendEmailInvite = row => {
    const { token, orgId, actions } = this.props
    actions.showModal('resendEmailInvite', {
      token,
      orgId,
      userId: row.userId,
      username: row.username.split(' ')[0], // remove 'Pending' if present
    })
  }

  getMenuOptions = row => {
    const { role: managerRole, user_id: managerUserId } = this.props.access

    // Users should not be able to manage themselves
    const userId = row.userId
    const isSelf = managerUserId === userId
    if (isSelf) return null

    // Users should not be able to manage anyone with a higher role
    const userRole = row.role
    if (managerRole > userRole) return null

    const userAlreadyJoined = !row.isPending
    const options = [
      {
        label: 'Change role to Owner',
        onClick: this.updateUserRole.bind(null, 1, userId),
        ignore: userRole === 1 || managerRole > 1,
      },
      {
        label: 'Change role to Admin',
        onClick: this.updateUserRole.bind(null, 2, userId),
        ignore: userRole === 2,
      },
      {
        label: 'Change role to Member',
        onClick: this.updateUserRole.bind(null, 5, userId),
        ignore: userRole === 5,
      },
      {
        label: 'Resend email invite',
        onClick: this.resendEmailInvite.bind(null, row),
        ignore: userAlreadyJoined,
      },
      {
        label: 'Remove user',
        onClick: this.removeUser.bind(null, row),
        ignore: false,
      },
    ]

    return options.filter(o => !o.ignore)
  }

  render() {
    const { rowData, rowColumns } = this.props
    return (
      <div className="row-data-list-container member-grid-row-list">
        {rowData.map(r => (
          <GridRow
            key={r.id}
            rowMeta={r}
            rowColumns={rowColumns}
            menuOptions={this.getMenuOptions(r)}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  access: state.access.data,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateUserRole,
      showModal,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberLicenseGridRowList)
