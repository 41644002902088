export const enforceLimitedDigits = limit => (value, previousValue) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if (value.length > limit) return previousValue
  return onlyNums
}

export const validateOnClient = values => {
  const errors = {}

  const requiredFields = [
    // Name fields
    'firstName',
    'lastName',

    // Billing address fields
    'addressLine1',
    'country',
    'state',
    'city',

    // Payment information fields
    'cardNumber',
    'month',
    'year',
    'cvc',
  ]

  requiredFields.forEach(
    field => (errors[field] = !values[field] ? 'Required' : null)
  )

  return errors
}

export const validateOnServer = response =>
  new Promise((resolve, reject) => {
    if (response.payload && response.payload.error) {
      reject(response.payload.error.error_message)
    } else {
      resolve(response.payload)
    }
  })
