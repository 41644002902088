import React from 'react'
import PropTypes from 'prop-types'
import AntMenu from './AntMenu'
import './GridRow.css'

const GridRow = ({ rowMeta, rowColumns, icon, menuOptions }) => (
  <div
    className={`
      grid-row
      ${rowMeta.isActive ? '' : 'inactive'}
    `}
  >
    {rowColumns.map((col, i) => {
      const cellContent = rowMeta[col.dataKey]
      const cellClassName = col.className
      return (
        <div key={cellClassName} className="cell-container">
          {!i && icon && icon()}
          {typeof cellContent === 'object' ? (
            <div className={cellClassName}>{cellContent}</div>
          ) : (
            <p className={cellClassName}>{cellContent}</p>
          )}
        </div>
      )
    })}
    {menuOptions && <AntMenu options={menuOptions} />}
  </div>
)

GridRow.propTypes = {
  rowMeta: PropTypes.shape({}).isRequired,
  rowColumns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  icon: PropTypes.func,
  menuOptions: PropTypes.array,
}

export default GridRow
