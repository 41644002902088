import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './NestedListElement.css'

const NestedListElement = ({ expanded, links, location }) => {
  const expandedClass = expanded
    ? 'expanded-menu-items'
    : 'collapsed-menu-items'

  return (
    <ul className={`nested-menu-items ${expandedClass}`}>
      {links.map(l => {
        const selectedClass = location === l.link ? 'selected-nested-link' : ''
        return (
          <Link
            to={l.link}
            className={`nested-link ${selectedClass}`}
            key={l.name}
          >
            <li className="nested-menu-item">{l.name}</li>
          </Link>
        )
      })}
    </ul>
  )
}

NestedListElement.propTypes = {
  expanded: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  location: PropTypes.string.isRequired,
}

export default NestedListElement
