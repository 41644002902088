import { ApolloProvider, gql, useQuery } from '@apollo/client'

export const GET_FEATURE_FLAGS_QUERY = gql`
  query {
    me {
      debug {
        featureFlags {
          name
          enabled
          description
        }
      }
    }
  }
`
