import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PrimaryContactForm from './PrimaryContactForm'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './EditPrimaryContactModal.css'

class EditPrimaryContactModal extends Component {
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
    primaryContact: PropTypes.shape({}),
  }

  state = { saveSuccess: false }

  confirmSaveSuccess = () => this.setState({ saveSuccess: true })

  render() {
    const { saveSuccess } = this.state
    const { subscriptionId, primaryContact } = this.props

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Edit Primary Contact</p>
        </div>
        <div className="modal-body-container">
          <PrimaryContactForm
            subscriptionId={subscriptionId}
            primaryContact={primaryContact}
            onSuccessfulUpdate={this.confirmSaveSuccess}
          />
        </div>
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage text="Primary contact was successfully updated" />
    )

    return (
      <div className="edit-primary-contact-modal">
        <IrisTransition
          didTransition={saveSuccess}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

export default EditPrimaryContactModal
