import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { deregisterWorkstationKey } from '../../../../actions/orgs/workstations'
import {
  _copyTextValueToClipboard,
  _splitWorkstationKey,
} from '../../../../util'
import IrisTransition from '../../../Elements/IrisTransition'
import ModalContent from './ModalContent'
import '../ManageWorkstationModal.css'

class ReleaseWorkstationKeyModal extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    workstationId: PropTypes.string.isRequired,
    workstationKey: PropTypes.string.isRequired,
    actions: PropTypes.shape({}).isRequired,
  }

  state = {
    released: false,
    ctaOverrideText: '',
  }

  releaseWorkstationKey = () => {
    const { token, orgId, workstationId, actions } = this.props
    return actions
      .deregisterWorkstationKey(token, orgId, workstationId)
      .then(() => this.setState({ released: true }))
  }

  copyWorkstationKey = () => {
    const { workstationKey } = this.props
    _copyTextValueToClipboard(workstationKey)
    this.setState({ ctaOverrideText: 'Copied to Clipboard' })
    setTimeout(() => this.setState({ ctaOverrideText: '' }), 3000)
  }

  cta = () => {
    const { released } = this.state
    if (released) return this.copyWorkstationKey()
    else return this.releaseWorkstationKey()
  }

  getCtaText = () => {
    const { released, ctaOverrideText } = this.state
    if (ctaOverrideText) return ctaOverrideText
    if (released) return 'Copy Workstation Key'
    return 'Release Workstation Key'
  }

  render() {
    const { released } = this.state
    const { workstationKey } = this.props
    const splitKey = _splitWorkstationKey(workstationKey)

    const modalContent = (
      <ModalContent
        released={released}
        splitKey={splitKey}
        renderCtaText={this.getCtaText}
        onClick={this.cta}
      />
    )

    return (
      <div
        className="
        manage-workstation-modal
        release-workstation-key-modal
      "
      >
        <IrisTransition
          didTransition={released}
          initialComponent={modalContent}
          finalComponent={modalContent}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  orgId: state.orgs.selectedOrg._id,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      deregisterWorkstationKey,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseWorkstationKeyModal)
