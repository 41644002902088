import React from 'react'
import PropTypes from 'prop-types'
import AsyncAuthComponent from './AsyncAuthComponent'
import { Route, Redirect } from 'react-router-dom'
import {
  _findSavedAuthSession,
  _syncUserAccessStatus,
  _verifyUserRole,
} from '../../../../store/auth'
import {
  _resolvePromisesParallel,
  _resolvePromisesSequentially,
} from '../../../../util'

/**
 * Redirects the user to the login page while caching
 * the original redirect as a URL query, so they can
 * return to their intended location upon login.
 */
const LoginRedirect = ({ location }) => (
  <Redirect
    to={{
      pathname: '/login',
      search: `?redirect=${location.pathname.replace(/^\//, '')}`,
      state: { attemptedLogin: true },
    }}
  />
)
LoginRedirect.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

/**
 * Renders a PrivateRoute that is protected by a custom
 * authentication method. The user will be passed through
 * to the desired component or redirected to the login page
 * depending on how auth goes.
 */
const PrivateRoute = ({ component, requiredRole, ...rest }) => {
  /**
   * PrivateRoute will utilize a set of authentication handlers
   * to determine a user's access to the Component. Validators
   * are divided into two types:
   *
   * 1) Blockers
   * Contains synchronous methods that are typically fast and run
   * on locally cached data. Blockers will run in parallel assuming
   * no individual method is dependent on the result of another.
   *
   * 2) Background
   * Contains async methods that require the latest information from
   * the server. These functions should fire *without* blocking the
   * main thread as to prevent coupling render-time with server
   * response time. We run these methods sequentially since user
   * role verification depends on their latest access status.
   *
   * Both validator arrays will be Promisified via utility handlers
   * based on execution type and bundled into a master `authenticators`
   * object. AsyncAuthComponent will then execute all tasks attached
   * to this object and reveal the authenticated route accordingly.
   * If it is discovered that a background validator failed after a
   * successful transition, AsyncAuthComponent will dynamically swap
   * in the `failureComponent` to follow an "eventually consistent"
   * design pattern.
   */
  const blockingValidators = []
  const backgroundValidators = [
    _findSavedAuthSession,
    _syncUserAccessStatus,
    requiredRole ? _verifyUserRole.bind(null, requiredRole) : null,
  ]
  const authenticators = {
    blockers: _resolvePromisesParallel(blockingValidators),
    background: _resolvePromisesSequentially(backgroundValidators),
  }

  return (
    <Route
      render={props => {
        return (
          <AsyncAuthComponent
            authenticators={authenticators}
            successComponent={component}
            failureComponent={LoginRedirect}
            {...props}
          />
        )
      }}
      {...rest}
    />
  )
}

export default PrivateRoute
