import React from 'react'
import PropTypes from 'prop-types'

const ScopeIcon = ({ fill }) => (
  <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Orgs_Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Admin_BILLING_Collapsed"
        transform="translate(-35.000000, -184.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M45.5,184 C39.7010101,184 35,188.70101 35,194.5 C35,200.29899 39.7010101,205 45.5,205 C51.2989899,205 56,200.29899 56,194.5 C55.9924356,188.704146 51.2958537,184.007564 45.5,184 Z M45.5,185.652538 C49.6522365,185.657586 53.2443554,188.54471 54.1423077,192.598692 C53.6520385,192.202923 53.0066923,191.845115 52.2183846,191.538192 C50.4156154,190.834692 48.0305,190.447808 45.5,190.447808 C42.9695,190.447808 40.5876154,190.834692 38.7856538,191.536577 C37.9965385,191.8435 37.3511923,192.201308 36.8617308,192.597077 C37.7600054,188.54521 41.3497622,185.659308 45.5,185.652538 Z M54.2182308,194.559769 C54.2182308,196.013615 50.6385385,197.638692 45.5016154,197.638692 C40.3646923,197.638692 36.7858077,196.016038 36.7858077,194.559769 C36.7858077,193.1035 40.3655,191.480846 45.5,191.480846 C50.6345,191.480846 54.2182308,193.1035 54.2182308,194.559769 Z M45.5,203.350692 C41.4041443,203.344214 37.8462379,200.531948 36.8940385,196.548308 C37.3786538,196.933577 38.0143077,197.2825 38.7856538,197.582962 C40.5876154,198.284846 42.9727308,198.670923 45.5016154,198.670923 C48.0305,198.670923 50.4156154,198.284846 52.2175769,197.582962 C52.9889231,197.2825 53.6229615,196.936808 54.1091923,196.548308 C53.1567106,200.53312 49.5970628,203.345706 45.5,203.350692 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

ScopeIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default ScopeIcon
