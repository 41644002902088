import React from 'react'
import PropTypes from 'prop-types'

const ProspectIcon = props => ProspectIconOutline(props)

// https://www.figma.com/file/FJHwfvJUpcd5Z3difWCr1c/Prospect-Brand-Consistency?node-id=212%3A183
const ProspectIconOutline = ({ fill }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    stroke={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.0156 11.4767C21.0156 5.94746 16.5332 1.46509 11.0039 1.46509L11.0195 6.50761C13.7164 6.50761 16.0058 8.71416 16.0058 11.4767C16.0058 14.2393 13.7665 16.4787 11.0039 16.4787C8.45154 16.4787 6.03356 14.4233 6.03356 11.493L1.00781 11.4845C1.00781 17.1926 5.68338 21.4884 11.0039 21.4884C16.5332 21.4884 21.0156 17.006 21.0156 11.4767" />
    <path d="M11.0118 13.679C12.228 13.679 13.2141 12.693 13.2141 11.4768C13.2141 10.2605 12.228 9.27466 11.0118 9.27466C9.79555 9.27466 8.80957 10.2605 8.80957 11.4768C8.80957 12.693 9.79555 13.679 11.0118 13.679" />
  </svg>
)

const ProspectIconFilled = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 30 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.1367 15.3436C29.1367 7.28635 22.6091 0.754639 14.557 0.754639L14.5797 8.10259C18.5072 8.10259 21.8412 11.318 21.8412 15.3436C21.8412 19.3692 18.5801 22.6324 14.557 22.6324C10.8401 22.6324 7.31885 19.6373 7.31885 15.3673L0 15.3549C0 23.6728 6.80889 29.9326 14.557 29.9326C22.6091 29.9326 29.1367 23.4008 29.1367 15.3436Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.637 20.8521C17.6458 20.8521 20.0855 18.4112 20.0855 15.4001C20.0855 12.3891 17.6458 9.94849 14.637 9.94849C11.6279 9.94849 9.18848 12.3891 9.18848 15.4001C9.18848 18.4112 11.6279 20.8521 14.637 20.8521Z"
    />
  </svg>
)

ProspectIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default ProspectIcon
