import React from 'react'

export const ChecklistView = ({
  entries = [],
  setFlagEnabled = () => console.log('no handler defined for setFlagEnabled'),
}) => (
  <div>
    {entries.map(entry => (
      <FeatureFlag
        key={`dbg${entry.name}`}
        entry={entry}
        setFlagEnabled={setFlagEnabled}
      />
    ))}
  </div>
)

export const FeatureFlag = ({
  entry = { name: '', description: '', enabled: false },
  setFlagEnabled = () => console.log('no handler defined for setFlagEnabled'),
}) => (
  <div
    style={{
      display: 'flex',
      verticalAlign: 'middle',
      margin: 3,
      padding: 3,
      borderTop: '1px solid black',
      borderRight: '1px solid #a9a9a9',
    }}
  >
    <div
      style={{
        cursor: 'pointer',
        border: '2px solid black',
        width: 30,
        height: 30,
        marginRight: 10,
        verticalAlign: 'middle',
        textAlign: 'center',
        fontSize: '1.8em',
        fontWeight: 'bold',
        fontFamily: 'arial',
        lineHeight: '0.9em',
      }}
      onClick={() =>
        setFlagEnabled({
          name: entry.name,
          enabled: !entry.enabled,
        })
      }
    >
      {' '}
      {entry.enabled ? 'x' : ''}{' '}
    </div>
    <div
      style={{
        borderRight: '1px solid #a9a9a9',
        width: '200px',
      }}
    >
      <strong>{entry.name}</strong>
    </div>
    <div>
      <p style={{ fontSize: 14, margin: 3 }}>{entry.description}</p>
    </div>
  </div>
)

export default ChecklistView
