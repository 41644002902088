import React from 'react'
import PropTypes from 'prop-types'
import './NullLicensesView.css'

const NullLicensesView = ({ children }) => (
  <div className="null-licenses-view-container">{children}</div>
)

NullLicensesView.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NullLicensesView
