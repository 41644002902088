import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MenuOptions from './MenuOptions'
import IrisWrapper from '../../../../../Elements/IrisWrapper'
import './AntMenu.css'

class AntMenu extends Component {
  state = { visible: false }

  toggleMenu = () => this.setState({ visible: !this.state.visible })

  closeMenu = () => this.setState({ visible: false })

  render() {
    const { visible } = this.state
    const { options } = this.props

    return (
      <div className="ant-menu-container" onClick={this.toggleMenu}>
        <i className="material-icons ant-icon">more_vert</i>
        {visible && (
          <IrisWrapper onClickOutside={this.closeMenu}>
            <MenuOptions options={options} />
          </IrisWrapper>
        )}
      </div>
    )
  }
}

AntMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
}

export default AntMenu
