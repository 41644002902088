import React from 'react'
import Routes from '../Routes'
import './App.css'

const App = () => (
  <div className="app">
    <Routes />
  </div>
)

export default App
