import React from 'react'

import { LoadingIndicator } from '../loadingIndicator'

export const ProjectsZeroState = () => (
  <div
    style={{
      border: '1px solid #C7C7C7',
    }}
  >
    <LoadingIndicator
      status="error"
      message="The linked account does not appear to have access to any projects."
    />
    <BlankRows num="10" />
  </div>
)

const Row = ({ background = '#FBFAFD' }) => (
  <div
    style={{
      background: background,
      minHeight: 44,
    }}
  ></div>
)

const BlankRows = ({ num = 10 }) => {
  return (
    <div
      style={{
        minHeight: 300,
        overflow: 'auto',
        borderTop: '1px solid #C7C7C7',
      }}
    >
      <Row background="white" />
      <Row background="#FBFAFD" />
      <Row background="white" />
      <Row background="#FBFAFD" />
      <Row background="white" />
      <Row background="#FBFAFD" />
      <Row background="white" />
      <Row background="#FBFAFD" />
      <Row background="white" />
    </div>
  )
}

export default ProjectsZeroState
