import React from 'react'
import PropTypes from 'prop-types'

const SettingsIcon = ({ fill }) => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Orgs_Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Admin_BILLING_Collapsed"
        transform="translate(-35.000000, -373.000000)"
        fillRule="nonzero"
        stroke={fill}
        strokeWidth="1.5"
      >
        <path
          d="M53.1594942,384.975 C53.1969942,384.6625 53.2219942,384.3375 53.2219942,384 C53.2219942,383.6625 53.1969942,383.3375 53.1469942,383.025 L55.2594942,381.375 C55.4469942,381.225 55.4969942,380.95 55.3844942,380.7375 L53.3844942,377.275 C53.2594942,377.05 52.9969942,376.975 52.7719942,377.05 L50.2844942,378.05 C49.7594942,377.65 49.2094942,377.325 48.5969942,377.075 L48.2219942,374.425 C48.1844942,374.175 47.9719942,374 47.7219942,374 L43.7219942,374 C43.4719942,374 43.2719942,374.175 43.2344942,374.425 L42.8594942,377.075 C42.2469942,377.325 41.6844942,377.6625 41.1719942,378.05 L38.6844942,377.05 C38.4594942,376.9625 38.1969942,377.05 38.0719942,377.275 L36.0719942,380.7375 C35.9469942,380.9625 35.9969942,381.225 36.1969942,381.375 L38.3094942,383.025 C38.2594942,383.3375 38.2219942,383.675 38.2219942,384 C38.2219942,384.325 38.2469942,384.6625 38.2969942,384.975 L36.1844942,386.625 C35.9969942,386.775 35.9469942,387.05 36.0594942,387.2625 L38.0594942,390.725 C38.1844942,390.95 38.4469942,391.025 38.6719942,390.95 L41.1594942,389.95 C41.6844942,390.35 42.2344942,390.675 42.8469942,390.925 L43.2219942,393.575 C43.2719942,393.825 43.4719942,394 43.7219942,394 L47.7219942,394 C47.9719942,394 48.1844942,393.825 48.2094942,393.575 L48.5844942,390.925 C49.1969942,390.675 49.7594942,390.3375 50.2719942,389.95 L52.7594942,390.95 C52.9844942,391.0375 53.2469942,390.95 53.3719942,390.725 L55.3719942,387.2625 C55.4969942,387.0375 55.4469942,386.775 55.2469942,386.625 L53.1594942,384.975 Z M45.7219942,387.75 C43.6594942,387.75 41.9719942,386.0625 41.9719942,384 C41.9719942,381.9375 43.6594942,380.25 45.7219942,380.25 C47.7844942,380.25 49.4719942,381.9375 49.4719942,384 C49.4719942,386.0625 47.7844942,387.75 45.7219942,387.75 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

SettingsIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default SettingsIcon
