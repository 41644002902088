import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

const LogoViewer = ({ logoUrl, isUploading }) => {
  const uploadingClass = isUploading ? 'uploading' : ''
  return (
    <div className="logo-viewer">
      <div className={`logo-overlay ${uploadingClass}`}>
        <CircularProgress className="loading-gif" size={15} color="secondary" />
        <div className="sheet" />
      </div>
      {logoUrl ? (
        <div className="logo-image-container">
          <img src={logoUrl} alt="Company Logo" />
        </div>
      ) : (
        <div className="logo-placeholder">
          <p className="suggested-dimensions">200px by 50px</p>
        </div>
      )}
    </div>
  )
}

LogoViewer.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  isUploading: PropTypes.bool.isRequired,
}

export default LogoViewer
