import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { IrisTextField, IrisDropdown } from '../../../Elements/IrisFormFields'
import IrisButton from '../../../Elements/IrisButton'
import { validateOnClient, validateOnServer } from './validate'
import './AddUserToOrgForm.css'

class AddUserToOrgForm extends Component {
  static propTypes = {
    addUserToOrg: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    blur: PropTypes.func.isRequired,
  }

  state = {
    ctaText: 'Send Invite',
    roles: [
      {
        label: 'Member',
        value: 5,
      },
      {
        label: 'Team Admin',
        value: 2,
      },
      {
        label: 'Team Owner',
        value: 1,
      },
    ],
  }

  addUserToOrg = data => {
    const { reset, blur, addUserToOrg, updateUserRole } = this.props
    return addUserToOrg(data.emailAddress)
      .then(validateOnServer)
      .then(() => {
        const { users } = this.props
        const userEntity = users.find(
          u => u.user.username === data.emailAddress
        )
        const userId = userEntity && userEntity.user._id
        return updateUserRole(data.role, userId)
      })
      .then(() => {
        reset()
        blur()
        this.setState({ ctaText: 'Success!' })
        setTimeout(() => this.setState({ ctaText: 'Send Invite' }), 3000)
      })
  }

  render() {
    const { handleSubmit } = this.props
    const { ctaText, roles } = this.state
    return (
      <form
        className="add-user-to-org-form"
        onSubmit={handleSubmit(this.addUserToOrg)}
      >
        <div className="form-fields">
          <IrisTextField name="emailAddress" label="Email Address" fullWidth />
          <IrisDropdown data={roles} fieldName="role" label="Select Role" />
          <IrisButton
            buttonText={ctaText}
            onClick={handleSubmit(this.addUserToOrg)}
          />
        </div>
      </form>
    )
  }
}

const AddUserToOrgReduxForm = reduxForm({
  form: 'addUserForm',
  validate: validateOnClient,
})(AddUserToOrgForm)

export default AddUserToOrgReduxForm
