import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logoutUser } from '../../../actions/auth'
import { getFrontendRedirectBaseUrl } from '../../../util'
import AuthenticatedNavbarComponent from './AuthenticatedNavbarComponent'
import irisLogo from './iris-icon-black.png'
import './Navbar.css'

class Navbar extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    orgName: PropTypes.string,
    userData: PropTypes.shape({}).isRequired,
    actions: PropTypes.shape({}).isRequired,
  }

  logoutUser = () => {
    const { actions } = this.props
    this.context.router.history.push('/login')
    return actions.logoutUser()
  }

  render() {
    const { isAuthenticated, orgName, userData } = this.props

    return (
      <div className="navbar">
        <div className="iris-logo-container">
          <a href={getFrontendRedirectBaseUrl()}>
            <img alt="iris logo" className="iris-logo" src={irisLogo} />
          </a>
        </div>
        {orgName && (
          <div className="org-name-container">
            <p className="org-name">{orgName}</p>
          </div>
        )}
        {isAuthenticated && (
          <AuthenticatedNavbarComponent
            logoutUser={this.logoutUser}
            displayName={userData.display_name}
          />
        )}
      </div>
    )
  }
}

Navbar.contextTypes = {
  router: PropTypes.shape({}).isRequired,
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  orgName: state.orgs.selectedOrg.company_name,
  userData: state.user.data,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logoutUser,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
