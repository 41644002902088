import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Wrapper component for easily detecting clicks within or outside a component
class IrisWrapper extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef = node => (this.wrapperRef = node)

  handleClickOutside = event => {
    const { onClickInside, onClickOutside } = this.props
    const clickedOutsideComponent =
      this.wrapperRef && !this.wrapperRef.contains(event.target)
    return clickedOutsideComponent
      ? onClickOutside && onClickOutside(event)
      : onClickInside && onClickInside(event)
  }

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>
  }
}

IrisWrapper.propTypes = {
  onClickInside: PropTypes.func,
  onClickOutside: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default IrisWrapper
