import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '../../../Shared/Grid'
import GridHeader from '../../../Shared/Grid/GridHeader'
import NullLicensesView from '../../../Shared/NullLicensesView'
import SubscriptionCard from './SubscriptionCard'
import { _extractRelevantMetadata } from './util'
import './SubscriptionsGrid.css'

class SubscriptionsGrid extends Component {
  static propTypes = {
    licenses: PropTypes.array.isRequired,
    subscriptions: PropTypes.array.isRequired,
  }

  state = {
    subscriptions: [],
  }

  componentWillMount() {
    const { subscriptions, licenses } = this.props
    this.extractRelevantMetadata(subscriptions, licenses)
  }

  componentWillReceiveProps(nextProps) {
    const { subscriptions, licenses } = nextProps
    this.extractRelevantMetadata(subscriptions, licenses)
  }

  extractRelevantMetadata = (subscriptions, licenses) => {
    const extractedSubs = _extractRelevantMetadata(subscriptions, licenses)
    this.setState({ subscriptions: extractedSubs })
  }

  render() {
    const { subscriptions } = this.state
    return (
      <div className="subscriptions-grid-container">
        <Grid>
          <GridHeader
            columns={[
              {
                label: 'Product',
                className: 'billing-product',
              },
              {
                label: 'Subscription Cost',
                className: 'billing-total-cost',
              },
              {
                label: 'Licenses',
                className: 'billing-licenses',
              },
              {
                label: 'Next Renewal',
                className: 'billing-term-end',
              },
              {
                label: 'Status',
                className: 'billing-status',
              },
            ]}
            className={subscriptions.length ? 'no-border' : ''}
          />
          {subscriptions.map(s => (
            <SubscriptionCard key={s.id} subscription={s} />
          ))}
          {!subscriptions.length ? (
            <NullLicensesView>
              <p>
                You don't have any active subscriptions. If you'd like to
                purchase a subscription, please contact{' '}
                <a href="mailto:sales@irisvr.com">sales@irisvr.com</a>
              </p>
            </NullLicensesView>
          ) : (
            ''
          )}
        </Grid>
      </div>
    )
  }
}

export default SubscriptionsGrid
