export const _capitalize = str => {
  return str.toUpperCase().slice(0, 1) + str.slice(1)
}

export const _getHumanReadableDate = timestamp => {
  const date = new Date(timestamp * 1000)
  const month = _prependZeroIfLessThanXCharsLong(date.getMonth() + 1, 2)
  const day = _prependZeroIfLessThanXCharsLong(date.getDate(), 2)
  const year = _prependZeroIfLessThanXCharsLong(
    date
      .getFullYear()
      .toString()
      .slice(2),
    2
  )
  const readable = `${month}/${day}/${year}`
  return readable
}

export const _getHumanReadablePlanName = planId => {
  const planMap = {
    'p-prospect-t-workstation-c-annual': 'Workstation',
    'p-prospect-t-edu-workstation-c-annual': 'Educational Workstation',
    'p-prospect-t-basic-c-annual': 'Prospect Basic',
    'p-prospect-t-basic-c-monthly': 'Prospect Basic',
    'p-prospect-c-annual': 'Prospect Pro',
    'p-prospect-c-monthly': 'Prospect Pro',
    'p-prospect-t-plus-c-annual': 'Prospect Pro Plus',
    'p-prospect-t-plus-c-monthly': 'Prospect Pro Plus',
    'p-edu-scope-prospect-c-annual': 'Educational Plan',
    'p-scope-c-annual': 'Scope Pro',
    'p-scope-c-monthly': 'Scope Pro',
    'p-prospect-c-free-45': 'Prospect Trial',
    'p-scope-c-free-45': 'Scope Trial',
    '': 'None',
  }
  return planMap[planId]
}

export const _getHumanReadableRole = role => {
  const roleMap = {
    1: 'Owner',
    2: 'Team Admin',
    3: 'Member',
    4: 'Member',
    5: 'Member',
  }
  return roleMap[role]
}

export const _isTrialPlan = plan =>
  ['p-prospect-c-free-45', 'p-scope-c-free-45'].includes(plan)

export const _formatCurrency = dollars => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(dollars)
}

export const _formatCardNumber = cardNumber =>
  cardNumber.replace(/(.{4})/g, '$1 ').trim()

export const _prependZeroIfLessThanXCharsLong = (str, numChars) => {
  if (typeof str !== 'string') str = String(str)
  return str.length < numChars ? `0${str}` : str
}

export const _formatBillingStatus = str => _capitalize(str).replace('_', ' ')

export const _splitWorkstationKey = key => {
  const subStrs = []
  while (key.length) {
    const substr = key.slice(0, 5)
    subStrs.push(substr)
    key = key.replace(substr, '')
  }
  return subStrs
}

export const _removeDeepEmptyFields = obj => {
  for (let k in obj) {
    const v = obj[k]
    if (v === '') delete obj[k]
    if (typeof v === 'object' && !Array.isArray(v)) {
      _removeDeepEmptyFields(v)
    }
  }
}
