import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}
  if (!values.username) errors.username = 'Please enter your email address'
  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (response.payload && response.payload.error) {
      let serverError = response.payload.error.error_message
      if (serverError === 'The user with the specified query was not found') {
        serverError = 'The user with the specified email address was not found'
      }
      throw new SubmissionError({
        username: serverError,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
