import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import {
  IrisTextField,
  IrisDropdown,
} from '../../../../Elements/IrisFormFields'
import { BillingAddressFormFields } from '../../../../Elements/IrisForms'
import IrisButton from '../../../../Elements/IrisButton'
import PageFooter from '../../../../Shared/PageFooter'
import { verticalList, handleVerticalOption } from './data'
import {
  validateOnClient,
  validateOnServer,
  detectFieldsChanged,
} from './validate'
import './CompanySettingsFormContainer.css'

class CompanySettingsFormContainer extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    orgData: PropTypes.shape({}).isRequired,
    updateGeneralOrgData: PropTypes.func.isRequired,
    updateOrgBillingData: PropTypes.func.isRequired,
  }

  // TODO : Figure out how to do RETURN validated server
  // response in this 2-part request
  updateOrg = data => {
    const {
      dirty,
      initialValues,
      updateGeneralOrgData,
      updateOrgBillingData,
    } = this.props
    if (dirty) {
      const promisifiedResponses = []

      // Detect which fields changed, and send
      // separate API requests accordingly
      const generalOrgDataChanged = detectFieldsChanged(initialValues, data, [
        'companyName',
        'vertical',
      ])
      if (generalOrgDataChanged) {
        const requestBody = {
          company_name: data.companyName,
          vertical: data.vertical,
        }
        promisifiedResponses.push(
          updateGeneralOrgData(requestBody).then(validateOnServer)
        )
      }

      const orgBillingDataChanged = detectFieldsChanged(initialValues, data, [
        'addressLine1',
        'addressLine2',
        'country',
        'state',
        'city',
        'zipcode',
      ])
      if (orgBillingDataChanged) {
        const requestBody = {
          address_line1: data.addressLine1,
          address_line2: data.addressLine2,
          country: data.country,
          state: data.state,
          city: data.city,
          zipcode: data.zipcode,
        }
        promisifiedResponses.push(
          updateOrgBillingData(requestBody).then(validateOnServer)
        )
      }

      return Promise.all(promisifiedResponses)
    }
  }

  render() {
    const { handleSubmit, dirty } = this.props

    const buttonClass = dirty ? 'activated' : ''

    return (
      <div className="company-settings-form-container">
        <form
          className="company-settings-form"
          onSubmit={handleSubmit(this.updateOrg)}
        >
          <div className="form-fields">
            <IrisTextField name="companyName" label="Company Name" />
            <IrisDropdown
              data={verticalList}
              fieldName="vertical"
              label="Industry"
            />
          </div>

          <BillingAddressFormFields isMailing />

          <PageFooter className="company-settings-footer">
            <IrisButton
              className={buttonClass}
              buttonText="Save Edits"
              onClick={handleSubmit(this.updateOrg)}
            />
          </PageFooter>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { orgData } = ownProps
  return {
    initialValues: {
      companyName: orgData.company_name,
      vertical: handleVerticalOption(orgData.vertical),
      addressLine1: orgData.billing_address.address_line1,
      addressLine2: orgData.billing_address.address_line2,
      country: orgData.billing_address.country,
      state: orgData.billing_address.state,
      city: orgData.billing_address.city,
      zipcode: orgData.billing_address.zipcode,
    },
  }
}

const CompanySettingsReduxForm = reduxForm({
  form: 'companySettingsForm',
  validateOnClient,
  enableReinitialize: true,
})(CompanySettingsFormContainer)

export default connect(mapStateToProps)(CompanySettingsReduxForm)
