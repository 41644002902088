import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}
  if (!values.emailAddress)
    errors.emailAddress = 'Please enter a valid e-mail address'
  if (!values.role) errors.role = 'Required'
  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (response.payload && response.payload.error) {
      throw new SubmissionError({
        emailAddress: response.payload.error.error_message,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
