import React from 'react'
import PropTypes from 'prop-types'

const TeamIcon = ({ fill }) => (
  <svg width="25px" height="17px" viewBox="0 0 25 17" version="1.1">
    <title>Fill 8</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Orgs_Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Admin_BILLING_Collapsed"
        transform="translate(-35.000000, -281.000000)"
        stroke={fill}
        strokeWidth="1.3"
      >
        <path
          d="M43.0005,291 C40.6676666,291 36,292.167195 36,294.500583 L36,297 L50,297 L50,294.500583 C50,292.167195 45.3343333,291 43.0005,291 M51.9703666,291 C51.6780512,291 51.3505776,291.018003 51,291.054009 C52.1632346,291.892149 52.9738825,293.017336 52.9738825,294.500583 L52.9738825,297 L59,297 L59,294.500583 C59,292.167195 54.3139126,291 51.9703666,291 M43.0045068,282 C41.346019,282 40,283.344776 40,285.0005 C40,286.656224 41.346019,288 43.0045068,288 C44.663996,288 46,286.656224 46,285.0005 C46,283.344776 44.663996,282 43.0045068,282 M52.00601,282 C50.3452421,282 49,283.344776 49,285.0005 C49,286.656224 50.3452421,288 52.00601,288 C53.6647746,288 55,286.656224 55,285.0005 C55,283.344776 53.6647746,282 52.00601,282"
          id="Fill-8"
        />
      </g>
    </g>
  </svg>
)

TeamIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default TeamIcon
