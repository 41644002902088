export const _resolvePromisesParallel = funcs => () =>
  Promise.all(funcs.map(f => f()))

export const _resolvePromisesSequentially = funcs => async () => {
  for (let i in funcs) {
    const f = funcs[i]
    if (typeof f === 'function') {
      try {
        await f()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
  return Promise.resolve()
}
