import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import './IrisTooltip.css'

const IrisTooltip = ({ id, content, direction = 'bottom' }) => (
  <span className="iris-tooltip-container">
    <a data-tip data-for={id}>
      <i className="material-icons tooltip-icon">help_outline</i>
    </a>
    <ReactTooltip
      id={id}
      place={direction}
      effect="solid"
      className="iris-tooltip"
    >
      {content}
    </ReactTooltip>
  </span>
)

IrisTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  direction: PropTypes.string,
}

export default IrisTooltip
