import React from 'react'
import PropTypes from 'prop-types'
import './ResetPassword.css'

const ResetPassword = ({ showModal }) => (
  <div className="reset-password-container">
    <p className="header">Password</p>
    <p className="cta-text" onClick={showModal}>
      Change Password
    </p>
  </div>
)

ResetPassword.propTypes = {
  showModal: PropTypes.func.isRequired,
}

export default ResetPassword
