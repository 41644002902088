/**
 * Hashes the redux state with two caveats:
 * 1) Ignores certain reducers that are uncontrollable black boxes
 * 2) Restrict how many levels we check, since the existence of deeper
 *    fields depends on whether certain server data has been fetched
 */
export const _hashLocalReduxStoreSchema = storeObj => {
  const storeClone = JSON.parse(JSON.stringify(storeObj))
  const ignoreKeys = ['form']
  ignoreKeys.forEach(key => delete storeClone[key])
  const keys = __recursivelyGetObjectKeys(storeClone, 2, null)
  const keyStr = keys.sort().join(',')
  return __hash(keyStr)
}

function __recursivelyGetObjectKeys(obj, levelsDeep, parentKey) {
  let keys = []
  for (let key in obj) {
    const val = obj[key]
    const keyName = (parentKey && `${parentKey}.${key}`) || key
    if (typeof val === 'object' && !Array.isArray(val)) {
      const currLevel = keyName.split('.').length
      if (currLevel < levelsDeep) {
        keys = keys.concat(__recursivelyGetObjectKeys(val, levelsDeep, keyName))
      }
    }
    keys.push(keyName)
  }
  return keys
}

// https://stackoverflow.com/a/7616484/5377437
function __hash(str) {
  let hash = 0
  let chr = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}
