/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

const IntegrationsIcon = ({ color = '#bababa', hoverColor = '#9CABF0' }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 1C9.43782 1 7.75 2.6878 7.75 4.75C7.75 4.78108 7.75803 4.80992 7.75879 4.84082C4.66171 6.26517 2.5 9.37644 2.5 13C2.5 13.7181 2.61008 14.4085 2.76953 15.0801C1.71228 15.7452 1 16.9158 1 18.25C1 20.3122 2.68782 22 4.75 22C5.74981 22 6.65693 21.5979 7.33105 20.9541C8.58072 21.6101 9.99392 22 11.5 22C13.0061 22 14.422 21.613 15.6719 20.957C16.3457 21.5991 17.2517 22 18.25 22C20.3122 22 22 20.3122 22 18.25C22 16.9181 21.2905 15.7487 20.2363 15.083C20.396 14.4117 20.5 13.7182 20.5 13C20.5 9.3739 18.3413 6.25786 15.2412 4.83496C15.2419 4.80602 15.25 4.77909 15.25 4.75C15.25 2.6878 13.5622 1 11.5 1ZM11.5 2.5C12.7515 2.5 13.75 3.49855 13.75 4.75C13.75 6.0016 12.7515 7 11.5 7C10.2485 7 9.25 6.0016 9.25 4.75C9.25 3.49855 10.2485 2.5 11.5 2.5ZM8.11328 6.32617C8.71335 7.60237 10.0032 8.5 11.5 8.5C12.9959 8.5 14.2862 7.60405 14.8867 6.3291C17.3205 7.56681 19 10.0743 19 13C19 13.5371 18.9278 14.0541 18.8213 14.5586C18.6335 14.5293 18.4456 14.5 18.25 14.5C16.1878 14.5 14.5 16.1878 14.5 18.25C14.5 18.7716 14.61 19.2653 14.8047 19.7178C13.8063 20.2103 12.6919 20.5 11.5 20.5C10.3082 20.5 9.19306 20.2131 8.19531 19.7207C8.39067 19.2676 8.5 18.7725 8.5 18.25C8.5 16.1878 6.81218 14.5 4.75 14.5C4.55538 14.5 4.36849 14.5296 4.18164 14.5586C4.07447 14.054 4 13.5375 4 13C4 10.0745 5.67954 7.5636 8.11328 6.32617ZM4.75 16C6.00152 16 7 16.9986 7 18.25C7 19.5016 6.00152 20.5 4.75 20.5C3.49848 20.5 2.5 19.5016 2.5 18.25C2.5 16.9986 3.49848 16 4.75 16ZM18.25 16C19.5015 16 20.5 16.9986 20.5 18.25C20.5 19.5016 19.5015 20.5 18.25 20.5C16.9985 20.5 16 19.5016 16 18.25C16 16.9986 16.9985 16 18.25 16Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
)

IntegrationsIcon.propTypes = {}

export default IntegrationsIcon
