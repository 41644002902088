import React from 'react'
import PropTypes from 'prop-types'

const BillingIcon = ({ fill }) => (
  <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
    <title>Group 5</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Orgs_Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Admin_BILLING_Collapsed"
        transform="translate(-34.000000, -327.000000)"
      >
        <g id="Group-5" transform="translate(35.000000, 328.000000)">
          <circle
            id="Oval-6"
            stroke={fill}
            strokeWidth="1.5"
            cx="10.5"
            cy="10.5"
            r="10.5"
          />
          <text
            id="$"
            fontFamily="PxGrotesk-Regular, Px Grotesk"
            fontSize="15"
            fontWeight="normal"
            letterSpacing="1.875"
            fill={fill}
          >
            <tspan x="6" y="16">
              $
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
)

BillingIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default BillingIcon
