import React from 'react'
import PropTypes from 'prop-types'
import BillingContact from './BillingContact'
import BillingAddress from './BillingAddress'
import BillingCard from './BillingCard'
import './Body.css'

const Body = ({ id, billingContact, billingAddress }) => (
  <div className="grid-row subscription-expanded-body-container">
    <BillingContact subscriptionId={id} primaryContact={billingContact} />
    <BillingAddress subscriptionId={id} address={billingAddress} />
    <BillingCard subscriptionId={id} />
  </div>
)

Body.propTypes = {
  billingContact: PropTypes.shape({}).isRequired,
  billingAddress: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
}

export default Body
