import {
  GET_CARD_BY_SUBSCRIPTION_ID_REQUEST,
  GET_CARD_BY_SUBSCRIPTION_ID_SUCCESS,
  GET_CARD_BY_SUBSCRIPTION_ID_FAILURE,
  UPDATE_CARD_BY_SUBSCRIPTION_ID_REQUEST,
  UPDATE_CARD_BY_SUBSCRIPTION_ID_SUCCESS,
  UPDATE_CARD_BY_SUBSCRIPTION_ID_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/billing`

export const getCardBySubscriptionIdRequest = () => ({
  type: GET_CARD_BY_SUBSCRIPTION_ID_REQUEST,
})

export const getCardBySubscriptionIdSuccess = (
  subscriptionId,
  paymentMethod
) => ({
  type: GET_CARD_BY_SUBSCRIPTION_ID_SUCCESS,
  payload: {
    subscriptionId,
    paymentMethod,
  },
})

export const getCardBySubscriptionIdFailure = () => ({
  type: GET_CARD_BY_SUBSCRIPTION_ID_FAILURE,
})

/**
 * Function to retrieve users' subscription status via billing service
 * @returns {Object} payload - API response to subscription creation
 */
export const getCardBySubscriptionId = (
  token,
  orgId,
  subscriptionId
) => dispatch => {
  dispatch(getCardBySubscriptionIdRequest())
  const url = `${baseurl}/orgs/${orgId}/subscriptions/${subscriptionId}/card`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(getCardBySubscriptionIdSuccess(subscriptionId, payload.list[0]))
    )
    .catch(err => dispatch(getCardBySubscriptionIdFailure(err)))
}

export const updateCardBySubscriptionIdRequest = () => ({
  type: UPDATE_CARD_BY_SUBSCRIPTION_ID_REQUEST,
})

export const updateCardBySubscriptionIdSuccess = (subscriptionId, payload) => ({
  type: UPDATE_CARD_BY_SUBSCRIPTION_ID_SUCCESS,
  payload: {
    subscriptionId,
    card: payload.card,
  },
})

export const updateCardBySubscriptionIdFailure = error => ({
  type: UPDATE_CARD_BY_SUBSCRIPTION_ID_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve users' subscription status via billing service
 * @returns {Object} payload - API response to subscription creation
 */
export const updateCardBySubscriptionId = (
  token,
  orgId,
  subscriptionId,
  tmpToken
) => dispatch => {
  dispatch(updateCardBySubscriptionIdRequest())
  const url = `${baseurl}/orgs/${orgId}/subscriptions/${subscriptionId}/card`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .send({ tmp_token: tmpToken })
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(updateCardBySubscriptionIdSuccess(subscriptionId, payload))
    )
    .catch(err => dispatch(updateCardBySubscriptionIdFailure(err)))
}
