import React from 'react'
import PropTypes from 'prop-types'
import './IrisTitleContainer.css'

const IrisTitleContainer = ({ title }) => (
  <div className="iris-title-container">
    <p>{title}</p>
  </div>
)

IrisTitleContainer.propTypes = {
  title: PropTypes.string.isRequired,
}

export default IrisTitleContainer
