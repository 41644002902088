import React from 'react'

import { Button, Tooltip } from '@material-ui/core'

export const OpenInBIM360Icon = () => (
  <Tooltip title="Open in BIM&nbsp;360®">
    <span>
      <OpenIcon />
    </span>
  </Tooltip>
)

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.2714 14.5353H2.01942V2.28329H6.75548L5.41765 0.90918H0.645508V15.9094H15.6455V11.3003L14.2714 9.9115V14.5353Z"
      fill="#666666"
    />
    <path
      d="M14.9585 0.90918H10.5639H8.90561L10.0781 2.08186L11.803 3.80615L3.74219 11.8679L4.71363 12.8393L12.7746 4.7776L14.4727 6.47642L15.6453 7.6491V5.9906V1.59623V0.90918H14.9585Z"
      fill="#666666"
    />
  </svg>
)

export default OpenInBIM360Icon
