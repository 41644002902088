import React from 'react'

export const LoadingIndicator = ({
  status = '',
  message = '',
  textColor = '#000000',
  backgroundColor = 'white',
}) => {
  return (
    <div
      style={{
        border: '0px solid black',
        background: backgroundColor,
        color: textColor,
        padding: 12,
        paddingLeft: 45,
      }}
    >
      {status == 'error' ? <ErrorIcon /> : <LoadingIcon />}
      <div
        style={{
          margin: 5,
          display: 'inline-block',
        }}
      >
        {message}
      </div>
    </div>
  )
}

// NOTE: The animation relies on css in styles.scss
// @keyframes spin {
//   from {transform:rotate(0deg);}
//   to {transform:rotate(360deg);}
// }
export const LoadingIcon = ({ fill = '#4D9DE3' }) => (
  <div
    style={{
      position: 'relative',
      top: 6,
      animation: 'spin 2.5s infinite linear',
      width: 25,
      height: 24,
      display: 'inline-block',
    }}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5209 6.73375C11.6669 6.73375 10.813 6.95046 10.0872 7.34056L8.84895 6.08359C9.91637 5.39009 11.1546 5 12.5209 5C16.3209 5 19.395 8.12074 19.395 12.0217H21.9995L18.5411 15.5325L15.0827 12.0217H17.6872C17.6872 9.11765 15.3815 6.73375 12.5209 6.73375ZM12.5209 19C8.72086 19 5.60401 15.8793 5.60401 11.9783H2.99951L6.45794 8.46749L9.91637 11.9783H7.31187C7.31187 14.8824 9.61749 17.2229 12.4782 17.2229C13.3321 17.2229 14.186 17.0062 14.9119 16.6161L16.1501 17.8731C15.1254 18.6099 13.8872 19 12.5209 19Z"
        fill={fill}
      />
    </svg>
  </div>
)

export const ErrorIcon = () => (
  <svg
    style={{
      top: 3,
      marginRight: 15,
      position: 'relative',
    }}
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z"
      fill="#D0021B"
    />
  </svg>
)
