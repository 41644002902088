import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, getFormSyncErrors, getFormSubmitErrors } from 'redux-form'
import {
  IrisTextField,
  IrisCheckbox,
} from '../../../../Elements/IrisFormFields'
import IrisButton from '../../../../Elements/IrisButton'
import { validateOnClient } from './validate'
import TOCLabel from '../../TOCLabel'
import './OnboardForm.css'

class OnboardForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    submitErrors: PropTypes.shape({}).isRequired,
    serverErrors: PropTypes.shape({}).isRequired,
    confirmAccount: PropTypes.func.isRequired,
    isOrgInvitation: PropTypes.bool.isRequired,
  }

  onFullNameBlur = (_, fullName) =>
    fullName && this.props.change('preferredName', fullName.split(' ')[0])

  render() {
    const {
      confirmAccount,
      isOrgInvitation,

      // Redux-form props
      handleSubmit,
      submitting,
      submitFailed,
      submitErrors,
      serverErrors,
    } = this.props

    const submittingClass = submitting ? 'form-submitting' : ''

    const termsError =
      submitFailed && (submitErrors.terms || serverErrors.terms)

    return (
      <div className="org-onboard-form-container">
        <form
          className={`org-onboard-form iris-form iris-outline ${submittingClass}`}
          onSubmit={handleSubmit(confirmAccount)}
        >
          <div className="form-title-container">
            <p className="form-title">
              {isOrgInvitation ? 'Join your organization' : 'Join IrisVR'}
            </p>
          </div>
          <div className="form-fields-container">
            <div className="form-fields">
              <IrisTextField name="username" label="Work Email" disabled />
              <IrisTextField
                name="fullName"
                label="Full Name"
                autoFocus
                onBlur={this.onFullNameBlur}
              />
              <IrisTextField name="preferredName" label="Preferred Name" />
              <IrisTextField name="password" label="Password" type="password" />
              <IrisTextField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
              />
            </div>
            <div className="checkboxes-container">
              <IrisCheckbox
                fieldName="emailSubscription"
                labelContent="Keep me posted on IrisVR news and product updates"
              />
              <IrisCheckbox fieldName="terms" labelContent={<TOCLabel />} />
              {termsError && <span className="terms-error">{termsError}</span>}
            </div>
          </div>
          <IrisButton
            className="signup-button"
            buttonText="Complete Signup"
            onClick={handleSubmit(confirmAccount)}
          />
        </form>
      </div>
    )
  }
}

const OnboardReduxForm = reduxForm({
  form: 'onboardForm',
  validate: validateOnClient,
  enableReinitialize: true,
})(OnboardForm)

const OnboardReduxFormWithError = connect((state, ownProps) => ({
  initialValues: {
    username: ownProps.username,
  },
  submitErrors: getFormSyncErrors('onboardForm')(state),
  serverErrors: getFormSubmitErrors('onboardForm')(state),
}))(OnboardReduxForm)

export default OnboardReduxFormWithError
