import { exchangeAccessCode, disconnectForge } from './auth'
import { LinkModelToProspect, UnLinkModel } from './linking'
import { LATEST } from '../../constants'
import { StatusOptions } from '../bim360/components/project/models'

export const actionTypes = {
  SET_AUTH_CODE: 'SET_AUTH_CODE',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  DISCONNECT_INTEGRATION: 'DISCONNECT_INTEGRATION',
  SET_USERNAME: 'SET_USERNAME',
  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
  SET_PROJECT_DATA: 'SET_PROJECT_DATA',
  SET_SYNC_STATE: 'SET_SYNC_STATE',
  SET_EXPANDED_STATE: 'SET_EXPANDED_STATE',
  HYDRATE_SYNC_STATE: 'HYDRATE_SYNC_STATE',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  SET_POLLING_STATE: 'SET_POLLING_STATE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_SYNC_ITEMS_SUMMARY_VISIBLE: 'SET_SYNC_ITEMS_SUMMARY_VISIBLE',
  SET_POLLING_INTERVAL: 'SET_POLLING_INTERVAL',
  REMOVE_SYNC_ENABLED_ITEM_ID_FROM_LOADING_LIST:
    'REMOVE_SYNC_ENABLED_ITEM_ID_FROM_LOADING_LIST',
  ADD_SYNC_ENABLED_ITEM_ID_TO_LOADING_LIST:
    'ADD_SYNC_ENABLED_ITEM_ID_TO_LOADING_LIST',
  SET_SYNC_ENABLED_ITEMS_CURRENTLY_LOADING:
    'SET_SYNC_ENABLED_ITEMS_CURRENTLY_LOADING',
}

export const actions = {
  setAuthCode: props => {
    const { code, dispatch, history } = props
    if (code && code.length)
      // navigate to loading
      exchangeAccessCode({
        code: code,
        onSuccess: response => {
          // At this point we have successfully exchanged code
          history && history.push && history.push('/integrations')

          // Show the welcome modal
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              modalType: 'genericModal',
            },
          })
        },
      })
    return {
      type: actionTypes.SET_AUTH_CODE,
      payload: code,
    }
  },
  disconnectIntegration: () => {
    disconnectForge({
      onSuccess: () => {
        console.log('successfully disconnected forge')
        window.location = '/integrations'
      },
    })
    return {
      type: actionTypes.DISCONNECT_INTEGRATION,
    }
  },
  setAuthToken: token => {
    return {
      type: actionTypes.SET_AUTH_TOKEN,
      payload: token,
    }
  },
  setUsername: value => {
    return {
      type: actionTypes.SET_USERNAME,
      payload: value,
    }
  },

  setProjectData: projects => {
    return {
      type: actionTypes.SET_PROJECT_DATA,
      payload: projects,
    }
  },
  setSearchQuery: queryString => {
    return {
      type: actionTypes.SET_SEARCH_QUERY,
      payload: queryString,
    }
  },
  setExpandedState: props => {
    return {
      type: actionTypes.SET_EXPANDED_STATE,
      payload: props,
    }
  },
  hydrateSyncState: props => {
    return {
      type: actionTypes.HYDRATE_SYNC_STATE,
      payload: props,
    }
  },
  setPollingEnabled: props => {
    return {
      type: actionTypes.SET_POLLING_STATE,
      payload: props,
    }
  },
  setPollingInterval: props => {
    return {
      type: actionTypes.SET_POLLING_INTERVAL,
      payload: props,
    }
  },
  setSyncItemSummaryVisible: props => {
    return {
      type: actionTypes.SET_SYNC_ITEMS_SUMMARY_VISIBLE,
      payload: props,
    }
  },
  addSyncEnabledItemIdToLoadingList: syncEnabledItemId => {
    return {
      type: actionTypes.ADD_SYNC_ENABLED_ITEM_ID_TO_LOADING_LIST,
      payload: syncEnabledItemId,
    }
  },
  removeSyncEnabledItemIdFromLoadingList: syncEnabledItemId => {
    return {
      type: actionTypes.REMOVE_SYNC_ENABLED_ITEM_ID_FROM_LOADING_LIST,
      payload: syncEnabledItemId,
    }
  },
  updateSyncEnabledItemsLoadingList: props => {
    const { syncEnabledItems, syncEnabledItemIdsCurrentlyLoading } = props
    const syncEnabledItemsMap = {}
    syncEnabledItems.map(item => {
      syncEnabledItemsMap[item.id] = item
    })

    // iterate the response we have and take out any loading states for models which are in a pending status
    // this value will replace syncEnabledItemIdsCurrentlyLoading
    const _pendingItems = []
    syncEnabledItemIdsCurrentlyLoading.map(modelId => {
      // Check syncEnabledItems for any items which are in a pending status, to see if the server status is available.
      // If the modelID is not in the syncEnabledItems that means the server has not yet added any status for the model, likely still processing
      const modelIsCurrentlyPending = syncEnabledItemsMap[modelId] === undefined
      if (modelIsCurrentlyPending) {
        _pendingItems.push(modelId)
      }
    })
    if (_pendingItems.length < syncEnabledItemIdsCurrentlyLoading.length) {
      return {
        type: actionTypes.SET_SYNC_ENABLED_ITEMS_CURRENTLY_LOADING,
        payload: {
          items: _pendingItems,
        },
      }
    }
    // nothing chnaged
    return {
      type: 'noop',
    }
  },
  setSyncState: props => {
    const dispatch = props.dispatch
    const payload = props.payload
    switch (payload.status) {
      case StatusOptions.IMPORT:
        dispatch(actions.addSyncEnabledItemIdToLoadingList(payload.id))
        LinkModelToProspect({
          projectId: payload.projectID,
          modelId: payload.urn,
          version: LATEST, // "LATEST" will pull the max model version number. See: https://github.com/IrisVR/cloud-util/pull/68
          viewId: payload.modelViewId,
          dispatch: dispatch,
        })
        break
      case StatusOptions.UNLINK:
        UnLinkModel({
          projectId: payload.projectID,
          modelId: payload.urn,
          viewId: payload.modelViewId,
          dispatch: dispatch,
        })

        break
    }

    return {
      type: actionTypes.SET_SYNC_STATE,
      payload: props.payload,
    }
  },
  setErrorMessage: (errorMessage, syncEnabledItemId) => {
    return {
      type: actionTypes.SET_ERROR_MESSAGE,
      payload: { errorMessage, syncEnabledItemId },
    }
  },
}
