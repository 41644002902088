export const validateOnClient = values => {
  const errors = {}

  const requiredFields = ['fullName', 'email']

  requiredFields.forEach(
    field => (errors[field] = !values[field] ? 'Required' : null)
  )

  return errors
}

export const validateOnServer = response =>
  new Promise((resolve, reject) => {
    if (response.payload && response.payload.error) {
      reject(response.payload.error.error_message)
    } else {
      resolve(response.payload)
    }
  })
