import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import Cookies from 'js-cookie'

import { getConfig } from './config'

export const getStoredToken = () => Cookies.get('iris_jwt') || ''

const config = getConfig()
const graphQLURL = config.graphQLURI

const apolloLink = createHttpLink({
    uri: graphQLURL,
    // fetch: httpFetcher
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getStoredToken()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
})

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(apolloLink),
})

export const GetClient = ({ token = "" }) => {
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : "",
                }
            }
        }).concat(apolloLink),
    })
}
