import {
  UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_REQUEST,
  UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_SUCCESS,
  UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/billing`

export const updateBillingAddressBySubscriptionIdRequest = () => ({
  type: UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_REQUEST,
})

export const updateBillingAddressBySubscriptionIdSuccess = (
  subscriptionId,
  address
) => ({
  type: UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_SUCCESS,
  payload: {
    subscriptionId,
    address,
  },
})

export const updateBillingAddressBySubscriptionIdFailure = error => ({
  type: UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve users' subscription status via billing service
 * @returns {Object} payload - API response to subscription creation
 */
export const updateBillingAddressBySubscriptionId = (
  token,
  orgId,
  subscriptionId,
  address
) => dispatch => {
  dispatch(updateBillingAddressBySubscriptionIdRequest())
  const url = `${baseurl}/orgs/${orgId}/subscriptions/${subscriptionId}/address`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .send(address)
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(
        updateBillingAddressBySubscriptionIdSuccess(subscriptionId, payload)
      )
    )
    .catch(err => dispatch(updateBillingAddressBySubscriptionIdFailure(err)))
}
