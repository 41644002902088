import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  logoutUser,
  confirmAccount,
  login,
  fetchUserAndOrgData,
} from '../../../../actions'
import OnboardForm from './OnboardForm'
import { validateOnServer } from './OnboardForm/validate'
import { _searchToJson } from '../../../../util'
import './Onboard.css'

class Onboard extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    location: PropTypes.shape({}).isRequired,

    // Action creators
    logoutUser: PropTypes.func.isRequired,
    confirmAccount: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    fetchUserAndOrgData: PropTypes.func.isRequired,
  }

  static contextTypes = {
    router: PropTypes.shape({}).isRequired,
  }

  state = {
    username: '',
    orgId: '',
    inviteToken: '',
  }

  componentWillMount() {
    const { location, logoutUser } = this.props

    // Clear auth store
    logoutUser()

    // Examine URL query params
    const searchQueries = _searchToJson(location.search)
    const { username, org_id: orgId, invite_token: inviteToken } = searchQueries

    // The reason we call decodeURIComponent(x || "")
    // is because x may be undefined, and calling
    // decodeURIComponent(undefined) results in the
    // string literal "undefined" which consumers of
    // state would not be expecting. They do, however,
    // expect the string to be falsy (e.g., the empty string).
    this.setState({
      username: decodeURIComponent(username || ''),
      orgId: decodeURIComponent(orgId || ''),
      inviteToken: decodeURIComponent(inviteToken || ''),
    })
  }

  confirmAccount = data => {
    const { confirmAccount, login } = this.props
    const { orgId, inviteToken } = this.state
    const userData = this.formatUserData(data)
    return confirmAccount({ userData, orgId, inviteToken })
      .then(validateOnServer)
      .then(() =>
        login({
          username: data.username,
          password: data.password,
        })
      )
      .then(validateOnServer)
      .then(this.getUserAndOrgData)
      .then(this.handleRedirect)
  }

  formatUserData = data => {
    const userData = {
      email_subscription: {
        general: {
          subscribed: data.emailSubscription || false,
        },
      },
    }
    userData.username = data.username
    userData.full_name = data.fullName
    userData.display_name = data.preferredName
    userData.password = data.password
    return userData
  }

  getUserAndOrgData = () => {
    const { token, userId, fetchUserAndOrgData } = this.props
    const { orgId } = this.state
    return fetchUserAndOrgData(token, orgId, userId)
  }

  handleRedirect = () =>
    new Promise(resolve => {
      this.context.router.history.push('/prospect')
      resolve()
    })

  render() {
    const { username, orgId } = this.state

    return (
      <div className="org-onboard-page">
        <OnboardForm
          confirmAccount={this.confirmAccount}
          username={username}
          isOrgInvitation={!!orgId}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  userId: state.auth.user && state.auth.user._id,
})

export default connect(mapStateToProps, {
  logoutUser,
  confirmAccount,
  login,
  fetchUserAndOrgData,
})(Onboard)
