import ProspectIcon from './assets/ProspectIcon'
import ScopeIcon from './assets/ScopeIcon'
import LicenseIcon from './assets/LicenseIcon'
import TeamIcon from './assets/TeamIcon'
import BillingIcon from './assets/BillingIcon'
import SettingsIcon from './assets/SettingsIcon'

import IntegrationsIcon from './assets/IntegrationsIcon'

export const accessControlLogic = {
  personalUser: [
    'prospect',
    'scope',
    'integrations',
    'settings',
  ],
  teamMember: [
    'prospect',
    'scope',
    'integrations',
    'settings',
  ],
  teamAdmin: [
    'prospect',
    'scope',
    'integrations',
    'licenses',
    'team',
    'adminSettings',
  ],
  teamOwner: [
    'prospect',
    'scope',
    'integrations',
    'licenses',
    'team',
    'billing',
    'adminSettings',
  ]
}

export const tabs = {
  prospect: {
    name: 'Prospect',
    icon: ProspectIcon,
    link: '/prospect',
  },
  scope: {
    name: 'Scope',
    icon: ScopeIcon,
    link: '/scope',
  },
  licenses: {
    name: 'Licenses',
    icon: LicenseIcon,
    link: '/licenses',
  },
  team: {
    name: 'Team',
    icon: TeamIcon,
    link: '/team',
  },
  billing: {
    name: 'Billing',
    icon: BillingIcon,
    link: '/billing',
  },
  settings: {
    name: 'Settings',
    icon: SettingsIcon,
    link: '/settings',
  },
  integrations: {
    name: 'Integrations',
    icon: IntegrationsIcon,
    link: '/integrations'
  },
  adminSettings: {
    name: 'Settings',
    icon: SettingsIcon,
    link: [
      {
        name: 'Company Settings',
        link: '/settings/company',
      },
      {
        name: 'Personal Settings',
        link: '/settings',
      },
    ],
  },
}
