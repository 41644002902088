import React from 'react'
import PropTypes from 'prop-types'
import GridRow from '../GridRow'
import LicenseIcon from './LicenseIcon'
import './LicenseGridRowList.css'

const LicenseGridRowList = ({ rowData, rowColumns, menuOptions }) => (
  <div className="row-data-list-container license-grid-row-list">
    {rowData.map(r => (
      <GridRow
        key={r.id}
        rowMeta={r}
        rowColumns={rowColumns}
        icon={() => <LicenseIcon iconType={r.iconType} />}
        menuOptions={menuOptions}
      />
    ))}
  </div>
)

LicenseGridRowList.propTypes = {
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      iconType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
    })
  ).isRequired,
  rowColumns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  menuOptions: PropTypes.array,
}

export default LicenseGridRowList
