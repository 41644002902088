import {
  DEREGISTER_WORKSTATION_KEY_REQUEST,
  DEREGISTER_WORKSTATION_KEY_SUCCESS,
  DEREGISTER_WORKSTATION_KEY_FAILURE,
  RENAME_WORKSTATION_REQUEST,
  RENAME_WORKSTATION_SUCCESS,
  RENAME_WORKSTATION_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/orgs`

export const deregisterWorkstationKeyRequest = () => ({
  type: DEREGISTER_WORKSTATION_KEY_REQUEST,
})

export const deregisterWorkstationKeySuccess = workstation => ({
  type: DEREGISTER_WORKSTATION_KEY_SUCCESS,
  payload: workstation,
})

export const deregisterWorkstationKeyFailure = error => ({
  type: DEREGISTER_WORKSTATION_KEY_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const deregisterWorkstationKey = (
  token,
  orgId,
  workstationId
) => dispatch => {
  dispatch(deregisterWorkstationKeyRequest())
  const url = `${baseurl}/${orgId}/workstations/${workstationId}/deregister`
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .end()
    .then(parseResponse)
    .then(payload => dispatch(deregisterWorkstationKeySuccess(payload)))
    .catch(error => dispatch(deregisterWorkstationKeyFailure(error)))
}

export const renameWorkstationRequest = () => ({
  type: RENAME_WORKSTATION_REQUEST,
})

export const renameWorkstationSuccess = workstation => ({
  type: RENAME_WORKSTATION_SUCCESS,
  payload: workstation,
})

export const renameWorkstationFailure = error => ({
  type: RENAME_WORKSTATION_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const renameWorkstation = (
  token,
  orgId,
  workstationId,
  data
) => dispatch => {
  dispatch(renameWorkstationRequest())
  const url = `${baseurl}/${orgId}/workstations/${workstationId}`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .send(data)
    .type('json')
    .end()
    .then(parseResponse)
    .then(payload => dispatch(renameWorkstationSuccess(payload)))
    .catch(error => dispatch(renameWorkstationFailure(error)))
}
