import React, { Component } from 'react'
import PropTypes from 'prop-types'
import personThing from './person.thing.svg'
import NavDropdown from './NavDropdown'
import './AuthenticatedNavbarComponent.css'
import { getFrontendRedirectBaseUrl } from '../../../../util'

class AuthenticatedNavbarComponent extends Component {
  // TODO : This state looks just like AntMenu/index.js,
  // perhaps we can refactor with React Hooks
  state = { open: false }

  openMenu = () => this.setState({ open: true })

  closeMenu = () => this.setState({ open: false })

  redirectToHomeSplashPage = () =>
    window.open(getFrontendRedirectBaseUrl(), '_blank')

  redirectToZendesk = () => window.open('https://help.irisvr.com', '_blank')

  render() {
    const { open } = this.state
    const { displayName, logoutUser } = this.props
    return (
      <div className="authenticated-navbar-component" onClick={this.openMenu}>
        <div className="component-container">
          <div className="icon-container">
            <img src={personThing} alt="icon" />
          </div>
          <div className="name-container">
            <p>{displayName}</p>
          </div>
          <div className="dropdown-container">
            <NavDropdown
              open={open}
              closeMenu={this.closeMenu}
              options={[
                {
                  label: 'IrisVR Homepage',
                  onClick: this.redirectToHomeSplashPage,
                },
                {
                  label: 'Support',
                  onClick: this.redirectToZendesk,
                },
                {
                  label: 'Logout',
                  onClick: logoutUser,
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

AuthenticatedNavbarComponent.propTypes = {
  displayName: PropTypes.string.isRequired,
  logoutUser: PropTypes.func.isRequired,
}

export default AuthenticatedNavbarComponent
