import React from 'react'

export const Chevron = ({ direction = 'right' }) => {
  var transform = direction === 'right' ? 'rotate(-90)' : ''

  return (
    // eslint-disable-next-line
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      transform={transform}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.15462 0.409302L5.32129 4.24264L1.48796 0.409302L0.321289 1.57597L5.32129 6.57597L10.3213 1.57597L9.15462 0.409302Z"
        fill="#757575"
      />
    </svg>
  )
}

export default Chevron
