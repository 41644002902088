import React from 'react'
import PropTypes from 'prop-types'

const LicenseIcon = ({ fill }) => (
  <svg width="22px" height="16px" viewBox="0 0 22 16" version="1.1">
    <title>Group 6</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Orgs_Web"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Admin_BILLING_Collapsed"
        transform="translate(-34.000000, -235.000000)"
      >
        <g id="Group-6" transform="translate(34.000000, 235.000000)">
          <rect
            id="Rectangle-14"
            stroke={fill}
            x="0.5"
            y="0.5"
            width="21"
            height="15"
            rx="2"
          />
          <path
            d="M10.5,5.5 L18.5,5.5"
            id="Line-12"
            stroke={fill}
            strokeLinecap="square"
          />
          <rect id="Rectangle-2" fill={fill} x="3" y="4" width="5" height="8" />
          <path
            d="M10.5,8.5 L18.5,8.5"
            id="Line-12"
            stroke={fill}
            strokeLinecap="square"
          />
          <path
            d="M10.5,11.5 L18.5,11.5"
            id="Line-12"
            stroke={fill}
            strokeLinecap="square"
          />
        </g>
      </g>
    </g>
  </svg>
)

LicenseIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default LicenseIcon
