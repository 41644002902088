import {
  _formatBillingStatus,
  _getHumanReadableDate,
  _getHumanReadablePlanName,
  _isTrialPlan,
  _formatCurrency,
  _prependZeroIfLessThanXCharsLong,
} from '../../../../util'

export const _extractRelevantMetadata = (subscriptions, licenses) => {
  return subscriptions
    .filter(s => !_isTrialPlan(s.billing_summary && s.billing_summary.plan_id))
    .filter(s => !isZeroedOut(s))
    .map(s => _formatSubscriptionMetadata(s, licenses))
}

function isZeroedOut(subscription) {
  return !subscription.billing_summary.mrr
}

function _formatSubscriptionMetadata(subscription, licenses) {
  const meta = {
    id: '',
    planName: '',
    planQuantity: 1,
    cost: '',
    licenses: 0,
    endDate: 0,
    status: '',
    billingContact: {},
    billingAddress: {},
    cardLast4: '',
  }

  const summary = subscription.billing_summary
  meta.id = subscription._id
  meta.planName = _getHumanReadablePlanName(summary.plan_id)
  meta.planQuantity = summary.plan_quantity
  meta.cost = `${_formatCurrency(Math.round((summary.mrr * 12) / 100))}/yr`
  meta.licenses = licenses.filter(
    l => l.subscription_id === subscription._id
  ).length
  meta.licenses = _prependZeroIfLessThanXCharsLong(meta.licenses, 2)
  meta.endDate = _getHumanReadableDate(summary.current_term_end)
  if (summary.status === 'non_renewing') meta.endDate = null
  meta.status = _formatBillingStatus(summary.status)
  meta.billingContact = _formatBillingContact(subscription.primary_contact)
  meta.billingAddress = _formatBillingAddress(subscription.billing_address)
  return meta
}

function _formatBillingContact(contact) {
  return {
    fullName: contact.full_name,
    email: contact.email,
    phone: contact.phone,
  }
}

function _formatBillingAddress(address) {
  return {
    firstName: (address.full_name && address.full_name.split(' ')[0]) || '',
    lastName: (address.full_name && address.full_name.split(' ')[1]) || '',
    addressLine1: address.address_line1,
    addressLine2: address.address_line2,
    city: address.city,
    state: address.state,
    zipcode: address.zipcode,
    country: address.country,
  }
}
