import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import throttle from 'lodash.throttle'
import { loadState, saveState } from './localStorage'
import reducer, { rootReducer } from '../reducers'
import { _hashLocalReduxStoreSchema } from './hash'
import { _handleModalState, _handleIrisCookie } from './cookie'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// Grab cached redux state from localStorage for instant app hydration
let persistedState = loadState()

// Root reducer with all initial states
const initialStore = rootReducer(undefined, {})

// Compare the locally cached state schema to the latest one defined in
// our `rootReducer`. If an update is detected, wipe out persistedState
// to avoid white-screen errors induced by irreconcilable state trees.
if (!persistedState) persistedState = {}
else {
  const localHash = _hashLocalReduxStoreSchema(persistedState)
  const latestHash = _hashLocalReduxStoreSchema(initialStore)
  if (localHash !== latestHash) persistedState = {}
}
persistedState = _handleModalState(persistedState, initialStore.modals)
persistedState = _handleIrisCookie(persistedState)

const store = createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
)

// Serialize to localStorage upon state change
store.subscribe(
  throttle(() => {
    saveState(store.getState())
  }, 1000)
)

export const getStore = () => store
