import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { sendPasswordResetEmail } from '../../../../actions'
import { validateOnClient, validateOnServer } from './validate'
import { IrisTextField } from '../../../Elements/IrisFormFields'
import IrisButton from '../../../Elements/IrisButton'
import './SendPasswordResetForm.css'

class SendPasswordResetEmailForm extends Component {
  static propTypes = {
    sendPasswordResetEmail: PropTypes.func.isRequired,
    onEmailConfirmation: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  requestPasswordReset = data => {
    const { sendPasswordResetEmail, onEmailConfirmation } = this.props
    return sendPasswordResetEmail(data.username)
      .then(validateOnServer)
      .then(onEmailConfirmation)
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const submittingClass = submitting ? 'form-submitting' : ''
    return (
      <form
        className={`send-password-reset-email-form ${submittingClass}`}
        onSubmit={handleSubmit(this.requestPasswordReset)}
      >
        <div className="form-fields-container">
          <div className="form-fields">
            <IrisTextField name="username" label="Your Email" fullWidth />
          </div>
        </div>
        <IrisButton
          buttonText="Send Reset Email"
          onClick={handleSubmit(this.requestPasswordReset)}
        />
      </form>
    )
  }
}

const SendPasswordResetEmailReduxForm = reduxForm({
  form: 'sendPasswordResetEmail',
  validate: validateOnClient,
})(SendPasswordResetEmailForm)

export default connect(null, { sendPasswordResetEmail })(
  SendPasswordResetEmailReduxForm
)
