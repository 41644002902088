import React from 'react'
import PropTypes from 'prop-types'

export const TitleContainer = ({ icon, text }) => (
  <div className="title-container">
    <img className="icon" src={icon} alt={text} />
    <p>{text}</p>
  </div>
)
TitleContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export const DescriptionContainer = ({ text }) => (
  <div className="description-container">
    <p>{text}</p>
  </div>
)
DescriptionContainer.propTypes = {
  text: PropTypes.string.isRequired,
}

export const CtaContainer = ({ ctaLink, ctaText, newTab }) => (
  <div className="cta-container">
    <a href={ctaLink} target={newTab ? '_blank' : '_self'}>
      {ctaText}
    </a>
  </div>
)
CtaContainer.propTypes = {
  ctaLink: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  newTab: PropTypes.bool.isRequired,
}

export const ScopeLibraryCtaContainer = ({ ctaLink, ctaText, newTab }) => (
  <div className="cta-container">
    <a
      className="no-underline"
      href={ctaLink}
      target={newTab ? '_blank' : '_self'}
    >
      <button className="iris-cta-button">{ctaText}</button>
    </a>
  </div>
)
ScopeLibraryCtaContainer.propTypes = {
  ctaLink: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  newTab: PropTypes.bool.isRequired,
}
