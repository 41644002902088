import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardDetailsForm from './CardDetailsForm'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './EditCreditCardModal.css'

class EditCreditCardModal extends Component {
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
    address: PropTypes.shape({}),
  }

  state = { saveSuccess: false }

  confirmSaveSuccess = () => this.setState({ saveSuccess: true })

  render() {
    const { saveSuccess } = this.state
    const { subscriptionId, address } = this.props

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Edit Card Details</p>
        </div>
        <div className="modal-body-container">
          <CardDetailsForm
            subscriptionId={subscriptionId}
            address={address}
            onSuccessfulUpdate={this.confirmSaveSuccess}
          />
        </div>
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage text="Your card information was successfully updated" />
    )

    return (
      <div className="edit-credit-card-modal">
        <IrisTransition
          didTransition={saveSuccess}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

export default EditCreditCardModal
