import {
  GET_PERSONAL_USER_SUBSCRIPTION_STATUS_REQUEST,
  GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS,
  GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_REQUEST,
  GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS,
  GET_USER_LICENSES_WITHIN_ORG_SUCCESS,
} from '../constants'
import { createReducer } from './util'
// import { dummyLicenses } from './dummyData'

const initialState = {
  data: {},
  licenses: [],
  isFetchingSubscriptionStatus: false,
}

export default createReducer(initialState, {
  [GET_PERSONAL_USER_SUBSCRIPTION_STATUS_REQUEST]: (state, payload) => {
    return {
      ...state,
      isFetchingSubscriptionStatus: true,
    }
  },
  [GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      data: payload,
      isFetchingSubscriptionStatus: false,
    }
  },
  [GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_REQUEST]: (state, payload) => {
    return {
      ...state,
      isFetchingSubscriptionStatus: true,
    }
  },
  [GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS]: (state, payload) => {
    return {
      ...state,
      data: payload,
      isFetchingSubscriptionStatus: false,
    }
  },
  [GET_USER_LICENSES_WITHIN_ORG_SUCCESS]: (state, payload) => {
    return {
      ...state,
      licenses: payload || [],
    }
  },
})
