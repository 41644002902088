import React from 'react'
import PropTypes from 'prop-types'
import './Header.css'

const Header = ({
  planName,
  planQuantity,
  cost,
  licenses,
  endDate,
  status,
}) => (
  <div className="grid-row subscription-card-header-container">
    <div className="cell-container plan-name-container">
      <p className="plan-name">{planName}</p>
      <p>x {planQuantity}</p>
    </div>
    <div className="cell-container cost-container">{cost}</div>
    <div className="cell-container licenses-container">{licenses}</div>
    <div className="cell-container end-date-container">{endDate}</div>
    <div className="cell-container status-container">{status}</div>
  </div>
)

Header.propTypes = {
  planName: PropTypes.string.isRequired,
  planQuantity: PropTypes.number.isRequired,
  cost: PropTypes.string.isRequired,
  licenses: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export default Header
