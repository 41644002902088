import { DISMISS_NOTIFICATION } from '../constants'
import { createReducer } from './util'

const initialState = {
  dismissed: false,
}

export default createReducer(initialState, {
  [DISMISS_NOTIFICATION]: (state, payload) => {
    return { ...state, dismissed: true }
  },
})
