import { actionTypes } from './actions'

export const INITIAL_STATE = {
  code: '',
  username: '',
  activeProjectID: '',
  forgeToken: '',
  searchQuery: '',
  projects: undefined,
  syncEnabledItems: {},
  expandedProjectIds: [],
  pollingEnabled: true,
  pollingInterval: 5 * 1000,
  showSyncItemSummary: false,
  syncEnabledItemIdsCurrentlyLoading: [],
}

export const reducerMap = {
  [actionTypes.SET_AUTH_CODE]: (state, payload) => {
    return {
      ...state,
      code: payload,
    }
  },
  [actionTypes.SET_AUTH_TOKEN]: (state, payload) => {
    return {
      ...state,
      code: '',
      forgeToken: payload,
    }
  },
  [actionTypes.DISCONNECT_INTEGRATION]: state => {
    return {
      ...initialState,
    }
  },
  [actionTypes.SET_USERNAME]: (state, payload) => {
    return {
      ...state,
      username: payload,
    }
  },
  [actionTypes.SET_PROJECT_DATA]: (state, payload) => {
    return {
      ...state,
      projects: payload,
    }
  },
  [actionTypes.SET_SEARCH_QUERY]: (state, payload) => {
    return {
      ...state,
      searchQuery: payload,
    }
  },
  [actionTypes.HYDRATE_SYNC_STATE]: (state, payload = {}) => {
    // We need to clone this object for Redux to pick up the actual payload. It appears redux may not pick up on deeply nested object changes if we don't do this cloning.
    const clonedObject = { ...payload }
    return {
      ...state,
      syncEnabledItems: { ...clonedObject },
    }
  },
  [actionTypes.SET_POLLING_STATE]: (state, payload) => {
    return {
      ...state,
      pollingEnabled: payload,
    }
  },
  [actionTypes.SET_POLLING_INTERVAL]: (state, payload) => {
    return {
      ...state,
      pollingInterval: payload,
    }
  },
  [actionTypes.SET_SYNC_ITEMS_SUMMARY_VISIBLE]: (state, payload) => {
    return {
      ...state,
      showSyncItemSummary: payload,
    }
  },
  [actionTypes.SET_EXPANDED_STATE]: (state, payload) => {
    console.log(payload)

    if (state.expandedProjectIds.includes(payload.id)) {
      // remove
      var filtered = state.expandedProjectIds.filter(function(
        value,
        index,
        arr
      ) {
        return value !== payload.id
      })
      return {
        ...state,
        expandedProjectIds: filtered,
      }
    } else {
      return {
        ...state,
        expandedProjectIds: [...state.expandedProjectIds, payload.id],
      }
    }
  },
  [actionTypes.SET_SYNC_STATE]: (state, payload) => {
    var items = {}
    for (var i in state.syncEnabledItems) items[i] = state.syncEnabledItems[i]

    var k = payload.id
    if (payload.enabled === false) {
      if (items[k] !== undefined) {
        delete items[k]
      }
    } else {
      items[k] = {
        ...payload,
      }
    }

    return {
      ...state,
      syncEnabledItems: { ...state.syncEnabledItems, ...items },
    }
  },
  [actionTypes.SET_ERROR_MESSAGE]: (state, payload) => {
    return {
      ...state,
      syncEnabledItems: {
        ...state.syncEnabledItems,
        [payload.syncEnabledItemId]: {
          ...state.syncEnabledItems[payload.syncEnabledItemId],
          errorMessage: payload.errorMessage,
        },
      },
    }
  },
  [actionTypes.REMOVE_SYNC_ENABLED_ITEM_ID_FROM_LOADING_LIST]: (
    state,
    payload // payload is syncEnabledItemId
  ) => {
    return {
      ...state,
      syncEnabledItemIdsCurrentlyLoading: state.syncEnabledItemIdsCurrentlyLoading.filter(
        i => i !== payload
      ),
    }
  },
  [actionTypes.ADD_SYNC_ENABLED_ITEM_ID_TO_LOADING_LIST]: (
    state,
    payload // payload is syncEnabledItemId
  ) => {
    return {
      ...state,
      syncEnabledItemIdsCurrentlyLoading: [
        ...state.syncEnabledItemIdsCurrentlyLoading,
        payload,
      ],
    }
  },
  [actionTypes.SET_SYNC_ENABLED_ITEMS_CURRENTLY_LOADING]: (
    state,
    payload // payload is array of modeId strings
  ) => {
    return {
      ...state,
      syncEnabledItemIdsCurrentlyLoading: [...payload.items],
    }
  },
}

export default {
  INITIAL_STATE: INITIAL_STATE,
  reducerMap,
}
