import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getOrgUsersAndWorkstations, showModal } from '../../../actions'
import AddUserButtonContainer from './AddUserButtonContainer'
import IrisTitleContainer from '../../Elements/IrisTitleContainer'
import MembersGrid from './MembersGrid'
import './Team.css'

class TeamPage extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    orgEntities: PropTypes.shape({
      users: PropTypes.array.isRequired,
      workstations: PropTypes.array.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      getOrgUsersAndWorkstations: PropTypes.func.isRequired,
    }).isRequired,
  }

  componentWillMount() {
    const { token, orgId, actions } = this.props
    actions.getOrgUsersAndWorkstations(token, orgId)
  }

  addUserToOrg = () => {
    const { token, orgId, actions } = this.props
    actions.showModal('addUserToOrg', {
      token,
      orgId,
    })
  }

  render() {
    const { token, orgId, orgEntities } = this.props

    return (
      <div className="team-page app-inner-content">
        <div className="team-view-container">
          <div className="team-view-header-container">
            <IrisTitleContainer title="Team" />
            <AddUserButtonContainer addUser={this.addUserToOrg} />
          </div>
          <MembersGrid
            token={token}
            orgId={orgId}
            members={orgEntities.users}
            workstations={orgEntities.workstations}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  orgId: state.orgs.selectedOrg._id,
  orgEntities: state.orgs.orgEntities,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getOrgUsersAndWorkstations,
      showModal,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamPage)
