import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './IrisTransition.css'

class IrisTransition extends Component {
  static propTypes = {
    didTransition: PropTypes.bool.isRequired,
    initialComponent: PropTypes.node.isRequired,
    finalComponent: PropTypes.node.isRequired,
  }

  render() {
    const { didTransition, initialComponent, finalComponent } = this.props

    return (
      <div className="iris-transition-container">
        <div
          className="initial-component"
          style={{
            opacity: Number(!didTransition),
            pointerEvents: didTransition ? 'none' : 'auto',
          }}
        >
          {initialComponent}
        </div>
        <div
          className="final-component"
          style={{
            opacity: Number(didTransition),
            pointerEvents: didTransition ? 'auto' : 'none',
          }}
        >
          {finalComponent}
        </div>
      </div>
    )
  }
}

export default IrisTransition
