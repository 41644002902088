import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import NestedListElement from './NestedListElement'

const ListElement = ({ data, selectTab, isSelected, location }) => {
  const selectedClass = isSelected ? 'selected-menu-item' : ''
  const fillColor = isSelected ? '#727FE0' : '#E0E0E0'
  const isNested = Array.isArray(data.link)
  const link = isNested ? data.link[0].link : data.link // default to first link if nested
  return (
    <div className="menu-link">
      <li className="menu-item-container">
        <div className={`menu-item ${selectedClass}`}>
          <Link to={link} className="main-tab">
            <div className="icon-container">
              <data.icon fill={fillColor} />
            </div>
            <div className="name-container">{data.name}</div>
          </Link>
        </div>
        {isNested && (
          <NestedListElement
            links={data.link}
            expanded={isSelected}
            location={location}
          />
        )}
      </li>
    </div>
  )
}

ListElement.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  }),
  selectTab: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
}

export default ListElement
