import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showModal } from '../../../../../actions/modals'
import './AssignLicenseButton.css'

class AssignLicenseButton extends Component {
  displayAssignModal = () => {
    const { showModal, orgId, licenseId, licenseType, orgUsers } = this.props
    showModal('assignLicense', {
      orgId,
      licenseId,
      licenseType,
      orgUsers,
    })
  }

  render() {
    return (
      <div className="assign-license-button-container">
        <button className="iris-button" onClick={this.displayAssignModal}>
          Assign
        </button>
      </div>
    )
  }
}

AssignLicenseButton.propTypes = {
  showModal: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  licenseId: PropTypes.string.isRequired,
  licenseType: PropTypes.string.isRequired,
  orgUsers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      accessId: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default connect(null, { showModal })(AssignLicenseButton)
