import { SubmissionError } from 'redux-form'

export const validateOnServer = response =>
  new Promise(resolve => {
    if (response.payload && response.payload.error) {
      throw new SubmissionError({
        accessId: response.payload.error.error_message,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
