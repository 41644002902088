import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { IrisTextField } from '../../../../Elements/IrisFormFields'
import IrisButton from '../../../../Elements/IrisButton'
import { validateOnClient } from './validate'
import './ResetPasswordForm.css'

class ResetPasswordForm extends Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  render() {
    const { resetPassword, handleSubmit, submitting } = this.props

    const submittingClass = submitting ? 'form-submitting' : ''

    return (
      <div className="reset-password-with-token-form-container">
        <form
          className={`reset-password-with-token-form iris-form iris-outline ${submittingClass}`}
          onSubmit={handleSubmit(resetPassword)}
        >
          <div className="form-title-container">
            <p className="form-title">Reset your password</p>
          </div>
          <div className="form-fields-container">
            <div className="form-fields">
              <IrisTextField
                name="password"
                label="New Password"
                type="password"
                autoFocus
              />
              <IrisTextField
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
              />
            </div>
          </div>
          <IrisButton
            className="reset-password-button"
            buttonText="Reset Password"
            onClick={handleSubmit(resetPassword)}
          />
        </form>
      </div>
    )
  }
}

const ResetPasswordReduxForm = reduxForm({
  form: 'resetPasswordWithTokenForm',
  validate: validateOnClient,
})(ResetPasswordForm)

export default ResetPasswordReduxForm
