import React from 'react'
import { BIM360Text, BIM360Icon } from './icons'
import { Chevron } from '../chevron'

import { Caret } from './icons'
import { isDevelopmentEnvironment } from '../../../integrations'
import { LoadingIcon } from '../loadingIndicator'

export class AccountDetailsButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
      isOpen: false,
      enabled: props.enabled,
      oAuthConfig: props.oAuthConfig,
      identity: props.identity,
      onClick: props.onClick,
    }
    this.mouseOut = this.mouseOut.bind(this)
    this.mouseOver = this.mouseOver.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleOut = this.handleOut.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.enabled !== nextProps.enabled) {
      this.setState({ enabled: nextProps.enabled })
    }
    if (this.props.identity !== nextProps.identity) {
      this.setState({ identity: nextProps.identity })
    }
  }
  mouseOver() {
    this.setState({ hover: true })
  }
  handleClick() {
    this.setState({ isOpen: !this.state.isOpen })
    console.log('isOpen', this.state.isOpen)
  }
  handleOut() {
    this.setState({ isOpen: false })
  }
  mouseOut() {
    this.setState({ hover: false })
  }
  render() {
    return (
      <div
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
        onClick={this.handleClick}
        style={{
          width: '100%',
          height: 'auto',
        }}
      >
        <div
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <Caret />
        </div>
        {this.state.enabled &&
        this.state.hover === true &&
        isDevelopmentEnvironment ? (
          <IdentityPopper identity={this.state.identity} />
        ) : (
          ''
        )}
        {this.state.enabled && this.state.isOpen === true ? (
          <UnlinkButton
            onMouseOut={this.handleOut}
            onClick={this.state.onClick}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

const UnlinkButton = ({ onClick, onMouseOut }) => (
  <div
    onMouseOut={onMouseOut}
    onClick={onClick}
    style={{
      width: 228,
      height: 54,
      cursor: 'pointer',
      background: 'white',
      position: 'absolute',
      fontSize: 12,
      textAlign: 'left',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    }}
  >
    <div
      style={{
        pointerEvents: 'none',
        textAlign: 'center',
        marginTop: 20,
      }}
    >
      <span style={{ fontSize: 20 }}>Unlink Account</span>
    </div>
  </div>
)

const IdentityPopper = ({ identity }) => (
  <div
    style={{
      width: 200,
      background: 'white',
      position: 'absolute',
      fontSize: 12,
      textAlign: 'left',
    }}
  >
    <fieldset>
      <legend>
        <strong>Identity</strong>
      </legend>
      {identity && <Identity identity={identity} />}
    </fieldset>
  </div>
)

const Identity = ({ identity, onClick }) => {
  const { userId, email, userName, firstName, lastName } = identity
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <strong>{userName}</strong>
      <br />
      <strong>
        {firstName} {lastName}{' '}
      </strong>
      <br />
      <strong>{userId}</strong>
      <br />
      <strong>{email}</strong>
      <br />
    </div>
  )
}

export const IntegrationHeader = ({
  isLoading = true,
  oAuthConfig = {
    clientId: '',
    redirectUrl: '',
    accessToken: '',
    authScope: '',
    apiURL: 'auth-me',
  },
  identity = {
    userId: '',
  },
  enabled = false,
  expanded,
  onClick = () => {
    alert('IntegrationHeader.onClick is undefined')
  },
  disconnectIntegration = () => {
    console.error('!!!no value defined for disconnectIntegration')
  },
}) => {
  const style = {
    // '-moz-user-select': '-moz-none',
    // '-moz-user-select': 'none',
    // '-o-user-select': 'none',
    // '-khtml-user-select': 'none', /* you could also put this in a class */
    // '-webkit-user-select': 'none',/* and add the CSS class here instead */
    // '-ms-user-select': 'none',
    // 'user-select': 'none',
    position: 'relative',
    width: 925,
  }

  return (
    <div style={style}>
      <div style={{ display: 'flex', paddingBottom: 24 }}>
        {enabled ? (
          <div
            onClick={onClick}
            style={{
              width: 20,
              paddingTop: 46,
              paddingLeft: 31,
              cursor: 'pointer',
            }}
          >
            <Chevron direction={expanded ? 'down' : 'right'} />
          </div>
        ) : (
          ''
        )}
        {/** BIM360 "B" Icon */}
        <div
          style={{
            paddingRight: 23,
            paddingTop: 31,
            paddingLeft: enabled ? 14 : 31,
          }}
        >
          <BIM360Icon />
        </div>
        <div style={{ paddingTop: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ paddingBottom: 10 }}>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: 20 }}>
                  <BIM360Text />
                </div>
                {identity.userId.length > 0 ? (
                  <div
                    style={{
                      fontSize: 14,
                      top: 25,
                    }}
                  >
                    <AccountDetailsButton
                      enabled={true}
                      oAuthConfig={oAuthConfig}
                      identity={identity}
                      onClick={disconnectIntegration}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: 14,
                      top: 41,
                      right: 39,
                      position: 'absolute',
                      textAlign: 'right',
                    }}
                  >
                    <LinkAccountButton
                      enabled={enabled}
                      oAuthConfig={oAuthConfig}
                      identity={identity}
                      onClick={disconnectIntegration}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                fontSize: 16,
              }}
            >
              Keep your BIM&nbsp;360® files up-to-date in Prospect at all times.
            </div>
          </div>
        </div>
      </div>
      {enabled && expanded && (
        <div style={{ paddingLeft: 31, paddingBottom: 30 }}>
          Click 'Enable' next to any file you wish to link with Prospect. Once a
          file is enabled, each time an update is published to BIM&nbsp;360®,
          we'll update it in Prospect as well.
        </div>
      )}
    </div>
  )
}

const LinkAccountButton = ({ oAuthConfig }) => {
  const { clientId, redirectUrl, authScope, apiUrl } = oAuthConfig
  const responseType = 'code'
  const scope = encodeURI(authScope)
  const authUrl = encodeURI(
    `${apiUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}`
  )

  return clientId.length == 0 ? (
    <div
      style={{
        color: '#534DC9',
        background: 'white',
        border: 0,
        cursor: 'default',
        width: 87,
        height: 31,
        textAlign: 'center',
      }}
    >
      <LoadingIcon />
    </div>
  ) : (
    <a href={authUrl}>
      <button
        style={{
          background: '#534DC9',
          color: 'white',
          borderRadius: 4,
          padding: 5,
          border: '1px solid #534DC9',
          cursor: 'pointer',
          width: 87,
          height: 31,
          fontWeight: 'bold',
          letterSpacing: 0.5,
          fontVariant: 'uppercase',
        }}
      >
        LINK
      </button>
    </a>
  )
}

export default IntegrationHeader
