import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './DownloadProspectView.css'

/**
 * Displayed for users who signed up via Prospect Trial funnel,
 * such as https://irisvr.com/trial?product=prospect
 */
class DownloadProspectView extends Component {
  static propTypes = {
    displayLoginForm: PropTypes.func.isRequired,
  }

  handleProspectDownload = () =>
    (window.location = 'https://prospect-releases.irisvr.com/ProspectSetup.exe')

  render() {
    const { displayLoginForm } = this.props

    return (
      <div className="download-prospect-view">
        <div className="download-container">
          <div className="title-container">
            <p className="title">Download Prospect to start your free trial</p>
          </div>
          <div className="button-container">
            <button
              className="iris-cta-button download-prospect"
              onClick={this.handleProspectDownload}
            >
              Download Prospect
            </button>
          </div>
        </div>
        <div className="or-container">
          <p className="divisive-or">or</p>
        </div>
        <div className="manage-account-settings">
          <p className="secondary-cta">
            Log in to manage your account settings
          </p>
          <div className="button-container">
            <button
              className="iris-cta-button-inverse login"
              onClick={displayLoginForm}
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default DownloadProspectView
