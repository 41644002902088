import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { ModelListing } from './project'
import { LoadingIndicator } from './loadingIndicator'

// enumerate the status
const AccountLinkingStatus = {
  StatusUnknown: undefined,
  StatusLinked: true,
  StatusNotLinked: false,
}

const projectModelViewQuery = gql`
  query {
    bim360 {
      me {
        userId
      }
      project(id: $projectId) {
        id
        models {
          path
          filename
          file {
            id
          }
          folder {
            id
          }
          views {
            guid
            name
          }
        }
      }
    }
  }
`
const syncEnabledItemsQuery = gql`
  query {
    bim360 {
      me {
        userId
      }
      project(id: $projectId) {
        id
        providerLink {
          syncEnabledItems {
            id
            filename
            modelId
            modelViewId
            projectId
            enabled
            status
            ownerId
          }
        }
      }
    }
  }
`

export const ProjectModelViewsList = ({
  projectId,
  setSyncState,
  onModelViewsToggle,
  addSyncEnabledItemIdToLoadingList,
  updateSyncEnabledItemsLoadingList,
  syncEnabledItemIdsCurrentlyLoading = [],
  syncEnabledItemIdErrorMessages,
  expandedIds,
}) => {
  const { loading, error, data } = useQuery(projectModelViewQuery, {
    variables: {
      projectId,
    },
  })
  const syncEnabledItemsResponse = useQuery(syncEnabledItemsQuery, {
    variables: {
      projectId,
    },
    pollInterval: 10000,
  })

  const project = data && data.bim360 && data.bim360.project
  const hasModels = project && project.models && project.models.length > 0
  const syncEnabledItems =
    (syncEnabledItemsResponse.data &&
      syncEnabledItemsResponse.data.bim360 &&
      syncEnabledItemsResponse.data.bim360.project &&
      syncEnabledItemsResponse.data.bim360.project.providerLink &&
      syncEnabledItemsResponse.data.bim360.project.providerLink
        .syncEnabledItems) ||
    []

  const identity = (syncEnabledItemsResponse.data &&
    syncEnabledItemsResponse.data.bim360 &&
    syncEnabledItemsResponse.data.bim360.me) || { userId: '' }

  const [isAccountLinked, setAccountIsLinked] = useState(
    AccountLinkingStatus.StatusUnknown
  )

  useEffect(() => {
    if (isAccountLinked && identity.userId.length == 0) {
      setAccountIsLinked(false)
    }
    if (!isAccountLinked && identity.userId.length > 0) {
      setAccountIsLinked(true)
    }
    if (syncEnabledItemIdsCurrentlyLoading.length > 0) {
      updateSyncEnabledItemsLoadingList({
        syncEnabledItems: syncEnabledItems,
        syncEnabledItemIdsCurrentlyLoading: syncEnabledItemIdsCurrentlyLoading,
      })
    }
  }, [
    identity,
    isAccountLinked,
    syncEnabledItems,
    syncEnabledItemIdsCurrentlyLoading,
  ])

  if (isAccountLinked === AccountLinkingStatus.StatusUnknown) {
    return <LoadingIndicator status="loading" message="Requesting models..." />
  }
  if (isAccountLinked === AccountLinkingStatus.StatusNotLinked) {
    return <LoadingIndicator status="error" message="Account not linked" />
  }
  if (loading) {
    return <LoadingIndicator status="loading" message="Loading Models..." />
  }
  if (error) {
    return (
      <LoadingIndicator
        status="error"
        message="We've encountered an error. Please refresh your page."
      />
    )
  }
  if (!hasModels) {
    return (
      <LoadingIndicator
        status="error"
        message="No models currently available"
      />
    )
  }

  return (
    <div>
      <div
        style={{
          height: '100%',
          background: 'white',
          color: 'black',
        }}
      >
        <ModelListing
          items={project.models}
          projectID={project.id}
          syncEnabledItems={syncEnabledItems}
          setSyncState={setSyncState}
          expandedIds={expandedIds}
          onModelViewsToggle={onModelViewsToggle}
          addSyncEnabledItemIdToLoadingList={addSyncEnabledItemIdToLoadingList}
          syncEnabledItemIdsCurrentlyLoading={
            syncEnabledItemIdsCurrentlyLoading
          }
          syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
        />
      </div>
    </div>
  )
}
