import React from 'react'
import PropTypes from 'prop-types'
import {
  TitleContainer,
  DescriptionContainer,
  CtaContainer,
  ScopeLibraryCtaContainer,
} from './index'
import './Section.css'

const Section = ({ productType, sectionType, title, description, cta }) => (
  <div
    className={`
    ${sectionType}-div
    ${productType}-section-div
    section-container
  `}
  >
    <TitleContainer {...title} />
    <DescriptionContainer {...description} />
    {sectionType === 'library' ? (
      <ScopeLibraryCtaContainer {...cta} />
    ) : (
      <CtaContainer {...cta} />
    )}
  </div>
)
Section.propTypes = {
  productType: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
  title: PropTypes.shape({}).isRequired,
  description: PropTypes.shape({}).isRequired,
  cta: PropTypes.shape({}).isRequired,
}

export default Section
