import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IrisWrapper from '../../../../Elements/IrisWrapper'
import MenuOptions from '../../../../Shared/Grid/GridRowList/GridRow/AntMenu/MenuOptions'
import './NavDropdown.css'

class NavDropdown extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      })
    ),
  }

  render() {
    const { open, closeMenu, options } = this.props
    const arrowDirection = open ? 'up' : 'down'

    return (
      <div className="nav-dropdown">
        <i className="material-icons arrow">
          {`keyboard_arrow_${arrowDirection}`}
        </i>
        {open && (
          <IrisWrapper onClickOutside={closeMenu}>
            <MenuOptions options={options} />
          </IrisWrapper>
        )}
      </div>
    )
  }
}

export default NavDropdown
