import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { IrisDropdown } from '../../../Elements/IrisFormFields'
import IrisButton from '../../../Elements/IrisButton'
import './AssignLicenseForm.css'

class AssignLicenseForm extends Component {
  static propTypes = {
    orgUsers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        accessId: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    assignLicense: PropTypes.func.isRequired,
  }

  state = {
    userDropdownData: [],
  }

  componentWillMount() {
    const { orgUsers } = this.props
    const userDropdownData = orgUsers
      .map(u => ({
        label: u.name ? `${u.name} (${u.username})` : u.username,
        value: u.accessId,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    this.setState({ userDropdownData })
  }

  render() {
    const { userDropdownData } = this.state
    const { handleSubmit, assignLicense } = this.props

    return (
      <form
        className="assign-license-form"
        onSubmit={handleSubmit(assignLicense)}
      >
        <div className="form-fields">
          <IrisDropdown
            data={userDropdownData}
            fieldName="accessId"
            label="Select User"
          />
        </div>
        <IrisButton
          buttonText="Assign License"
          onClick={handleSubmit(assignLicense)}
        />
      </form>
    )
  }
}

const AssignLicenseReduxForm = reduxForm({
  form: 'assignLicenseForm',
})(AssignLicenseForm)

export default AssignLicenseReduxForm
