import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Checkbox } from 'redux-form-material-ui'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import './IrisCheckbox.css'

const StyledCheckbox = withStyles({
  checked: {
    color: '#727FE0 !important',
  },
  root: {
    height: '36px',
  },
})(Checkbox)

const IrisCheckbox = ({ header, fieldName, labelContent }) => (
  <div className="iris-checkbox form-input-field">
    {header ? <p className="header">{header}</p> : ''}
    <FormControlLabel
      control={
        <Field name={fieldName} component={StyledCheckbox} disableRipple />
      }
      label={labelContent}
    />
  </div>
)

IrisCheckbox.propTypes = {
  header: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  labelContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
}

export default IrisCheckbox
