import prospectImg from '../assets/prospect_trial.png'
import scopeImg from '../assets/scope_trial.png'

export default {
  prospect: {
    trialImage: prospectImg,
    altText: 'Prospect',
    description: 'Start your 14 day free trial of Prospect Pro Plus',
    ctaText: 'Download and Start Trial',
  },
  scope: {
    trialImage: scopeImg,
    altText: 'Scope',
    description: 'Start your 14 day free trial of Scope Pro',
    ctaText: 'Start Trial',
  },
}
