// ------------------------------------------------
// Auth
export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE'
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE'
export const JOIN_REQUEST = 'JOIN_REQUEST'
export const JOIN_SUCCESS = 'JOIN_SUCCESS'
export const JOIN_FAILURE = 'JOIN_FAILURE'
export const JOIN_ORG_REQUEST = 'JOIN_ORG_REQUEST'
export const JOIN_ORG_SUCCESS = 'JOIN_ORG_SUCCESS'
export const JOIN_ORG_FAILURE = 'JOIN_ORG_FAILURE'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const VERIFY_PASSWORD_REQUEST = 'VERIFY_PASSWORD_REQUEST'
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS'
export const VERIFY_PASSWORD_FAILURE = 'VERIFY_PASSWORD_FAILURE'
export const SEND_PASSWORD_RESET_EMAIL_REQUEST =
  'SEND_PASSWORD_RESET_EMAIL_REQUEST'
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
  'SEND_PASSWORD_RESET_EMAIL_SUCCESS'
export const SEND_PASSWORD_RESET_EMAIL_FAILURE =
  'SEND_PASSWORD_RESET_EMAIL_FAILURE'
export const RESET_PASSWORD_WITH_TOKEN_REQUEST =
  'RESET_PASSWORD_WITH_TOKEN_REQUEST'
export const RESET_PASSWORD_WITH_TOKEN_SUCCESS =
  'RESET_PASSWORD_WITH_TOKEN_SUCCESS'
export const RESET_PASSWORD_WITH_TOKEN_FAILURE =
  'RESET_PASSWORD_WITH_TOKEN_FAILURE'
export const GET_ZENDESK_SSO_URL_REQUEST = 'GET_ZENDESK_SSO_URL_REQUEST'
export const GET_ZENDESK_SSO_URL_SUCCESS = 'GET_ZENDESK_SSO_URL_SUCCESS'
export const GET_ZENDESK_SSO_URL_FAILURE = 'GET_ZENDESK_SSO_URL_FAILURE'

// ------------------------------------------------
// User
export const GET_PERSONAL_USER_DATA_REQUEST = 'GET_PERSONAL_USER_DATA_REQUEST'
export const GET_PERSONAL_USER_DATA_SUCCESS = 'GET_PERSONAL_USER_DATA_SUCCESS'
export const GET_PERSONAL_USER_DATA_FAILURE = 'GET_PERSONAL_USER_DATA_FAILURE'
export const UPDATE_PERSONAL_USER_DATA_REQUEST =
  'UPDATE_PERSONAL_USER_DATA_REQUEST'
export const UPDATE_PERSONAL_USER_DATA_SUCCESS =
  'UPDATE_PERSONAL_USER_DATA_SUCCESS'
export const UPDATE_PERSONAL_USER_DATA_FAILURE =
  'UPDATE_PERSONAL_USER_DATA_FAILURE'
export const CONFIRM_USER_EMAIL_REQUEST = 'CONFIRM_USER_EMAIL_REQUEST'
export const CONFIRM_USER_EMAIL_SUCCESS = 'CONFIRM_USER_EMAIL_SUCCESS'
export const CONFIRM_USER_EMAIL_FAILURE = 'CONFIRM_USER_EMAIL_FAILURE'

// ------------------------------------------------
// Billing
export const GET_PERSONAL_USER_SUBSCRIPTION_STATUS_REQUEST =
  'GET_PERSONAL_USER_SUBSCRIPTION_STATUS_REQUEST'
export const GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS =
  'GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS'
export const GET_PERSONAL_USER_SUBSCRIPTION_STATUS_FAILURE =
  'GET_PERSONAL_USER_SUBSCRIPTION_STATUS_FAILURE'
export const GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_REQUEST =
  'GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_REQUEST'
export const GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS =
  'GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS'
export const GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_FAILURE =
  'GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_FAILURE'
export const GET_SUBSCRIPTIONS_FOR_ORG_REQUEST =
  'GET_SUBSCRIPTIONS_FOR_ORG_REQUEST'
export const GET_SUBSCRIPTIONS_FOR_ORG_SUCCESS =
  'GET_SUBSCRIPTIONS_FOR_ORG_SUCCESS'
export const GET_SUBSCRIPTIONS_FOR_ORG_FAILURE =
  'GET_SUBSCRIPTIONS_FOR_ORG_FAILURE'
export const GET_USER_LICENSES_WITHIN_ORG_REQUEST =
  'GET_USER_LICENSES_WITHIN_ORG_REQUEST'
export const GET_USER_LICENSES_WITHIN_ORG_SUCCESS =
  'GET_USER_LICENSES_WITHIN_ORG_SUCCESS'
export const GET_USER_LICENSES_WITHIN_ORG_FAILURE =
  'GET_USER_LICENSES_WITHIN_ORG_FAILURE'
export const START_PERSONAL_USER_TRIAL_REQUEST =
  'START_PERSONAL_USER_TRIAL_REQUEST'
export const START_PERSONAL_USER_TRIAL_SUCCESS =
  'START_PERSONAL_USER_TRIAL_SUCCESS'
export const START_PERSONAL_USER_TRIAL_FAILURE =
  'START_PERSONAL_USER_TRIAL_FAILURE'
export const START_ORG_USER_TRIAL_REQUEST = 'START_ORG_USER_TRIAL_REQUEST'
export const START_ORG_USER_TRIAL_SUCCESS = 'START_ORG_USER_TRIAL_SUCCESS'
export const START_ORG_USER_TRIAL_FAILURE = 'START_ORG_USER_TRIAL_FAILURE'
export const REGISTER_LICENSE_REQUEST = 'REGISTER_LICENSE_REQUEST'
export const REGISTER_LICENSE_SUCCESS = 'REGISTER_LICENSE_SUCCESS'
export const REGISTER_LICENSE_FAILURE = 'REGISTER_LICENSE_FAILURE'
export const DEREGISTER_LICENSE_REQUEST = 'DEREGISTER_LICENSE_REQUEST'
export const DEREGISTER_LICENSE_SUCCESS = 'DEREGISTER_LICENSE_SUCCESS'
export const DEREGISTER_LICENSE_FAILURE = 'DEREGISTER_LICENSE_FAILURE'
export const GET_CARD_BY_SUBSCRIPTION_ID_REQUEST =
  'GET_CARD_BY_SUBSCRIPTION_ID_REQUEST'
export const GET_CARD_BY_SUBSCRIPTION_ID_SUCCESS =
  'GET_CARD_BY_SUBSCRIPTION_ID_SUCCESS'
export const GET_CARD_BY_SUBSCRIPTION_ID_FAILURE =
  'GET_CARD_BY_SUBSCRIPTION_ID_FAILURE'
export const UPDATE_CARD_BY_SUBSCRIPTION_ID_REQUEST =
  'UPDATE_CARD_BY_SUBSCRIPTION_ID_REQUEST'
export const UPDATE_CARD_BY_SUBSCRIPTION_ID_SUCCESS =
  'UPDATE_CARD_BY_SUBSCRIPTION_ID_SUCCESS'
export const UPDATE_CARD_BY_SUBSCRIPTION_ID_FAILURE =
  'UPDATE_CARD_BY_SUBSCRIPTION_ID_FAILURE'
export const UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_REQUEST =
  'UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_REQUEST'
export const UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_SUCCESS =
  'UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_SUCCESS'
export const UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_FAILURE =
  'UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_FAILURE'
export const UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_REQUEST =
  'UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_REQUEST'
export const UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_SUCCESS =
  'UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_SUCCESS'
export const UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_FAILURE =
  'UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_FAILURE'

// ------------------------------------------------
// Orgs
export const SELECT_ORG = 'SELECT_ORG'
export const GET_ORG_BY_ID_REQUEST = 'GET_ORG_BY_ID_REQUEST'
export const GET_ORG_BY_ID_SUCCESS = 'GET_ORG_BY_ID_SUCCESS'
export const GET_ORG_BY_ID_FAILURE = 'GET_ORG_BY_ID_FAILURE'
export const GET_ORGS_FOR_USER_REQUEST = 'GET_ORGS_FOR_USER_REQUEST'
export const GET_ORGS_FOR_USER_SUCCESS = 'GET_ORGS_FOR_USER_SUCCESS'
export const GET_ORGS_FOR_USER_FAILURE = 'GET_ORGS_FOR_USER_FAILURE'
export const GET_ORG_USERS_AND_WORKSTATIONS_REQUEST =
  'GET_ORG_USERS_AND_WORKSTATIONS_REQUEST'
export const GET_ORG_USERS_AND_WORKSTATIONS_SUCCESS =
  'GET_ORG_USERS_AND_WORKSTATIONS_SUCCESS'
export const GET_ORG_USERS_AND_WORKSTATIONS_FAILURE =
  'GET_ORG_USERS_AND_WORKSTATIONS_FAILURE'
export const GET_ORG_LICENSES_REQUEST = 'GET_ORG_LICENSES_REQUEST'
export const GET_ORG_LICENSES_SUCCESS = 'GET_ORG_LICENSES_SUCCESS'
export const GET_ORG_LICENSES_FAILURE = 'GET_ORG_LICENSES_FAILURE'
export const ADD_USER_TO_ORG_REQUEST = 'ADD_USER_TO_ORG_REQUEST'
export const ADD_USER_TO_ORG_SUCCESS = 'ADD_USER_TO_ORG_SUCCESS'
export const ADD_USER_TO_ORG_FAILURE = 'ADD_USER_TO_ORG_FAILURE'
export const UPDATE_USER_ROLE_REQUEST = 'UPDATE_USER_ROLE_REQUEST'
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS'
export const UPDATE_USER_ROLE_FAILURE = 'UPDATE_USER_ROLE_FAILURE'
export const RESEND_EMAIL_INVITE_REQUEST = 'RESEND_EMAIL_INVITE_REQUEST'
export const RESEND_EMAIL_INVITE_SUCCESS = 'RESEND_EMAIL_INVITE_SUCCESS'
export const RESEND_EMAIL_INVITE_FAILURE = 'RESEND_EMAIL_INVITE_FAILURE'
export const REMOVE_USER_FROM_ORG_REQUEST = 'REMOVE_USER_FROM_ORG_REQUEST'
export const REMOVE_USER_FROM_ORG_SUCCESS = 'REMOVE_USER_FROM_ORG_SUCCESS'
export const REMOVE_USER_FROM_ORG_FAILURE = 'REMOVE_USER_FROM_ORG_FAILURE'
export const UPDATE_ORG_REQUEST = 'UPDATE_ORG_REQUEST'
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS'
export const UPDATE_ORG_FAILURE = 'UPDATE_ORG_FAILURE'
export const UPDATE_ORG_BILLING_DATA_REQUEST = 'UPDATE_ORG_BILLING_DATA_REQUEST'
export const UPDATE_ORG_BILLING_DATA_SUCCESS = 'UPDATE_ORG_BILLING_DATA_SUCCESS'
export const UPDATE_ORG_BILLING_DATA_FAILURE = 'UPDATE_ORG_BILLING_DATA_FAILURE'
export const REQUEST_SIGNED_URL_FOR_UPLOAD_REQUEST =
  'REQUEST_SIGNED_URL_FOR_UPLOAD_REQUEST'
export const REQUEST_SIGNED_URL_FOR_UPLOAD_SUCCESS =
  'REQUEST_SIGNED_URL_FOR_UPLOAD_SUCCESS'
export const REQUEST_SIGNED_URL_FOR_UPLOAD_FAILURE =
  'REQUEST_SIGNED_URL_FOR_UPLOAD_FAILURE'
export const UPLOAD_FILE_TO_SIGNED_URL_REQUEST =
  'UPLOAD_FILE_TO_SIGNED_URL_REQUEST'
export const UPLOAD_FILE_TO_SIGNED_URL_SUCCESS =
  'UPLOAD_FILE_TO_SIGNED_URL_SUCCESS'
export const UPLOAD_FILE_TO_SIGNED_URL_FAILURE =
  'UPLOAD_FILE_TO_SIGNED_URL_FAILURE'

// ------------------------------------------------
// Workstations
export const DEREGISTER_WORKSTATION_KEY_REQUEST =
  'DEREGISTER_WORKSTATION_KEY_REQUEST'
export const DEREGISTER_WORKSTATION_KEY_SUCCESS =
  'DEREGISTER_WORKSTATION_KEY_SUCCESS'
export const DEREGISTER_WORKSTATION_KEY_FAILURE =
  'DEREGISTER_WORKSTATION_KEY_FAILURE'
export const RENAME_WORKSTATION_REQUEST = 'RENAME_WORKSTATION_REQUEST'
export const RENAME_WORKSTATION_SUCCESS = 'RENAME_WORKSTATION_SUCCESS'
export const RENAME_WORKSTATION_FAILURE = 'RENAME_WORKSTATION_FAILURE'

// ------------------------------------------------
// Modals
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

// ------------------------------------------------
// Notifications
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'

// ------------------------------------------------
// Queries
export const LATEST = 'LATEST'
