import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { removeUserFromOrg, deregisterLicense } from '../../../actions'
import IrisButton from '../../Elements/IrisButton'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './RemoveUserFromOrgModal.css'

class RemoveUserFromOrgModal extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    accessId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    removeUserFromOrg: PropTypes.func.isRequired,
    deregisterLicense: PropTypes.func.isRequired,
    licenses: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        iris_access_document_id: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }

  state = { removeSuccess: false }

  removeUserFromOrg = () => {
    const {
      token,
      orgId,
      userId,
      removeUserFromOrg,
      deregisterLicense,
      accessId,
      licenses,
    } = this.props

    // It's critical to first deregister any licenses associated with the user to be removed
    const associatedLicenses = licenses.filter(
      l => l.iris_access_document_id === accessId
    )
    const deregisteredPromises = []
    if (associatedLicenses.length) {
      associatedLicenses.forEach(l => {
        deregisteredPromises.push(
          deregisterLicense(token, orgId, l._id, accessId)
        )
      })
    }
    return Promise.all(deregisteredPromises)
      .then(() => removeUserFromOrg(token, orgId, userId))
      .then(() => this.setState({ removeSuccess: true }))
  }

  render() {
    const { username } = this.props

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Remove User</p>
        </div>
        <div className="modal-body-container">
          <p className="body-content">
            This action will remove <span className="emphasis">{username}</span>{' '}
            from your team and all assigned licenses
          </p>
        </div>
        <IrisButton buttonText="Confirm" onClick={this.removeUserFromOrg} />
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage text={`${username} was successfully removed`} />
    )

    return (
      <div className="remove-user-from-org-modal">
        <IrisTransition
          didTransition={this.state.removeSuccess}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  licenses: state.orgs.licenses,
})

export default connect(mapStateToProps, {
  removeUserFromOrg,
  deregisterLicense,
})(RemoveUserFromOrgModal)
