import React from 'react'

import ModelListing from './models'

const ProjectDetailView = ({ project, setSyncState, syncEnabledItems }) => {
  return (
    <div style={{}}>
      {/* <div style={{
                height: 60
            }}>
            <strong style={{
                fontSize: '2.0em',
            }}>{project.attributes.name}</strong>
        </div> */}

      <ModelListing
        items={project.models}
        projectID={project.id}
        syncEnabledItems={syncEnabledItems}
        setSyncState={setSyncState}
      />

      {/* <strong>issues</strong>
        <IssuesListing items={project.issues} /> */}
    </div>
  )
}

export default ProjectDetailView
