export const verticalList = [
  'Architecture & Design',
  'BIM/VDC Consultant',
  'Owner',
  'Construction',
  'Education',
  'Energy, Oil & Gas',
  'Engineering',
  'Manufacturing',
  'Other',
]

export const handleVerticalOption = vertical =>
  verticalList.find(v => v === vertical) || ''
