import { fetch } from './auth'
import { actions } from './actions'
import * as Sentry from '@sentry/react'
import { LATEST } from '../../constants'

export const LinkModelToProspect = ({
  projectId,
  modelId,
  version = LATEST,
  viewId = '',
  onSuccess = () => {},
  onFailure = () => {},
  dispatch,
}) => {
  fetch({
    query: `
        mutation LinkModel( $projectId:string!, $modelId:string!, $version:string!, $viewId:string ) {
            LinkModel(
                projectId: $projectId, 
                modelId: $modelId,
                version: $version,
                viewId: $viewId
            ) {
                message
                success
            }
          }
        `,
    variables: {
      projectId,
      modelId,
      version,
      viewId,
    },
  }).then(res => {
    const hasSucceeded = Boolean(
      res.data && res.data.LinkModel && res.data.LinkModel.success
    )
    // TODO: This '&' h4ck should be removed at some point
    // It's formatted like this because that's the unique identifier logic in the redux store
    // It is the `syncEnabledItems { id }` value
    const syncEnabledItemId = Boolean(viewId)
      ? `${modelId}&${viewId}`
      : `${modelId}&`
    if (hasSucceeded) {
      dispatch(actions.setErrorMessage(null, syncEnabledItemId))
    } else {
      const errorMessage =
        res.data && res.data.LinkModel && res.data.LinkModel.message

      const fullErrorMessage =
        'An error occured when linking this model: ' + errorMessage
      dispatch(actions.setErrorMessage(fullErrorMessage, syncEnabledItemId))

      const contextForError = JSON.stringify({ modelId, viewId, projectId })
      Sentry.captureException(
        new Error(fullErrorMessage.concat(' ').concat(contextForError))
      )
    }

    /**
     * Remove loading indicator
     */

    // TODO: remove? Doesn't appear to do anything?
    if (res.errors && res.errors.length > 0) {
      console.error(res.errors[0])
      onFailure(res)
    } else {
      onSuccess({ result: res.data.result })
    }
  })
}

export const UnLinkModel = ({
  projectId,
  modelId,
  version = '',
  viewId = '',
  onSuccess = () => {},
  onFailure = () => {},
  dispatch,
}) => {
  fetch({
    query: `
        mutation UnLinkModel( $projectId:string!, $modelId:string!, $version:string!, $viewId:string ) {
            UnLinkModel(
                projectId: $projectId, 
                modelId: $modelId,
                version: $version
                viewId: $viewId
            ) {
                message
                success
            }
          }
        `,
    variables: {
      projectId,
      modelId,
      version,
      viewId,
    },
  }).then(res => {
    const hasSucceeded = Boolean(
      res.data && res.data.UnLinkModel && res.data.UnLinkModel.success
    )
    // TODO: This '&' h4ck should be removed at some point
    // It's formatted like this because that's the unique identifier logic in the redux store
    // It is the `syncEnabledItems { id }` value
    const syncEnabledItemId = Boolean(viewId)
      ? `${modelId}&${viewId}`
      : `${modelId}&`
    if (hasSucceeded) {
      dispatch(actions.setErrorMessage(null, syncEnabledItemId))
    } else {
      const errorMessage =
        res.data && res.data.UnLinkModel && res.data.UnLinkModel.message

      const fullErrorMessage =
        'An error occured when unlinking this model: ' + errorMessage
      dispatch(actions.setErrorMessage(fullErrorMessage, syncEnabledItemId))

      const contextForError = JSON.stringify({ modelId, viewId, projectId })
      Sentry.captureException(
        new Error(fullErrorMessage.concat(' ').concat(contextForError))
      )
    }

    // invoke callback if we received one
    if (res.errors && res.errors.length > 0) {
      onFailure(res)
    } else {
      onSuccess({ result: res.data.result })
    }
  })
}
