import React from 'react'
import ReactDOM from 'react-dom'
import { getStore } from './store'
import { MuiThemeProvider } from '@material-ui/core'
import { irisTheme } from './styles/theme'
import { Provider } from 'react-redux'
import './styles/styles.css'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import { ApolloClient } from 'apollo-boost'
import { ApolloProvider, InMemoryCache } from '@apollo/client'
import { createHttpLink } from 'apollo-link-http'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://8753566144d743e5a10b1291a2d1ef29@sentry.io/1544424',
})

import { getConfig } from './modules/integrations'
const config = getConfig()
const graphqlURI = config['graphQLURI']

export const getIrisJWT = () => {
  var state = localStorage.getItem('state')
  var data = JSON.parse(state)
  return (data && data.auth && data.auth.token) || ''
}

const customFetch = (uri, options) => {
  const token = getIrisJWT()
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${token}` || '',
  }
  options.credentials = 'include'
  return fetch(graphqlURI, options)
}

export const httpLink = createHttpLink({
  fetch: customFetch,
})

// const middlewareLink = new ApolloLink((operation, forward) => {
//   const context = operation.getContext()
//   console.log(context)
//   // operation.setContext({
//   //   headers: {
//   //     ...headers,
//   //     cool: 'sss'
//   //   }
//   // })
//   console.log(operation)
//   return forward(operation)
// })

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
  request: operation => {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

const render = () => {
  ReactDOM.render(
    <MuiThemeProvider theme={irisTheme}>
      <Provider store={getStore()}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
  )
}

render()
registerServiceWorker()
