import React from 'react'
import PropTypes from 'prop-types'
import trialExpiredImg from '../assets/trial_expired.png'
import { getFrontendRedirectBaseUrl } from '../../../../../util'
import './TrialExpiredView.css'

const TrialExpiredView = ({ productType }) => (
  <div className="trial-expired-view-container">
    <div className="image-container">
      <img src={trialExpiredImg} alt="Trial Expired" />
    </div>
    <div className="title-container">
      <p>Your trial has expired</p>
    </div>
    <div className="description-container">
      <p>
        To continue using {productType}, please purchase a license or get in
        touch with our team at{' '}
        <a href="mailto:sales@irisvr.com">sales@irisvr.com</a>
      </p>
    </div>
    <div className="cta-container">
      <a href={`${getFrontendRedirectBaseUrl()}/pricing`} target="_blank">
        <button className="iris-cta-button">Buy Now</button>
      </a>
    </div>
  </div>
)

TrialExpiredView.propTypes = {
  productType: PropTypes.string.isRequired,
}

export default TrialExpiredView
