import React from 'react'
import PropTypes from 'prop-types'
import './MenuOptions.css'

const MenuOptions = ({ options }) => (
  <div className="menu-options-container">
    {options.map(o => (
      <div key={o.label} className="option-container" onClick={o.onClick}>
        <p className="label">{o.label}</p>
      </div>
    ))}
  </div>
)

MenuOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
}

export default MenuOptions
