import React from 'react'
import PageFooter from '..'
import './BillingContactFooter.css'

const BillingContactFooter = () => (
  <PageFooter className="billing-contact-footer">
    <a
      className="cta-text"
      href="https://help.irisvr.com/hc/en-us/requests/new"
    >
      To make account or billing changes, please contact us.
    </a>
  </PageFooter>
)

export default BillingContactFooter
