import {
  SELECT_ORG,
  GET_ORG_BY_ID_SUCCESS,
  GET_ORGS_FOR_USER_SUCCESS,
  UPDATE_ORG_SUCCESS,
  UPDATE_ORG_BILLING_DATA_SUCCESS,
  GET_ORG_USERS_AND_WORKSTATIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FOR_ORG_SUCCESS,
  GET_CARD_BY_SUBSCRIPTION_ID_SUCCESS,
  UPDATE_CARD_BY_SUBSCRIPTION_ID_SUCCESS,
  UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_SUCCESS,
  UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_SUCCESS,
  GET_ORG_LICENSES_SUCCESS,
  DEREGISTER_WORKSTATION_KEY_SUCCESS,
  RENAME_WORKSTATION_SUCCESS,
  REGISTER_LICENSE_SUCCESS,
  DEREGISTER_LICENSE_SUCCESS,
  ADD_USER_TO_ORG_SUCCESS,
  UPDATE_USER_ROLE_SUCCESS,
  REMOVE_USER_FROM_ORG_SUCCESS,
} from '../../constants'
import { _mapServerCardDetailsToClientObject } from './util'
import { createReducer } from '../util'

const initialState = {
  orgs: [],
  selectedOrg: {},
  orgEntities: {
    users: [],
    workstations: [],
  },
  licenses: [],
  subscriptions: [],
  cards: {},
}

export default createReducer(initialState, {
  [SELECT_ORG]: (state, payload) => {
    const selectedOrg = state.orgs.find(o => o._id === payload.orgId)
    return { ...state, selectedOrg: selectedOrg }
  },
  [GET_ORG_BY_ID_SUCCESS]: (state, payload) => {
    return { ...state, selectedOrg: payload }
  },
  [GET_ORGS_FOR_USER_SUCCESS]: (state, payload) => {
    return { ...state, orgs: payload.orgs }
  },
  [UPDATE_ORG_SUCCESS]: (state, payload) => {
    return { ...state, selectedOrg: payload }
  },
  [UPDATE_ORG_BILLING_DATA_SUCCESS]: (state, payload) => {
    return { ...state, selectedOrg: payload }
  },
  [GET_ORG_USERS_AND_WORKSTATIONS_SUCCESS]: (state, payload) => {
    return { ...state, orgEntities: payload || initialState.orgEntities }
  },
  [GET_SUBSCRIPTIONS_FOR_ORG_SUCCESS]: (state, payload) => {
    return { ...state, subscriptions: payload || [] }
  },
  [GET_ORG_LICENSES_SUCCESS]: (state, payload) => {
    return { ...state, licenses: payload || [] }
  },
  [GET_CARD_BY_SUBSCRIPTION_ID_SUCCESS]: (state, payload) => {
    const { subscriptionId, paymentMethod } = payload
    const cards = { ...state.cards }
    const paymentSource = paymentMethod.payment_source
    const clientCard = _mapServerCardDetailsToClientObject(paymentSource.card)
    // We must manually map the `id` and `status` from the parent
    // paymentSource response object as these fields aren't provided
    // within the inner `.card` key when fetching
    clientCard.id = paymentSource.id
    clientCard.status = paymentSource.status
    cards[subscriptionId] = clientCard
    return { ...state, cards }
  },
  [UPDATE_CARD_BY_SUBSCRIPTION_ID_SUCCESS]: (state, payload) => {
    const { subscriptionId, card } = payload
    const cards = { ...state.cards }
    const corrCustomerId = state.subscriptions.find(
      s => s._id === subscriptionId
    ).billing_summary.customer_id
    const corrSubIds = state.subscriptions
      .filter(s => s.billing_summary.customer_id === corrCustomerId)
      .map(s => s._id)
    const clientCard = _mapServerCardDetailsToClientObject(card)
    // Update view for *all* subscriptions that share customer IDs
    corrSubIds.forEach(id => {
      cards[id] = clientCard
    })
    return { ...state, cards }
  },
  [UPDATE_BILLING_ADDRESS_BY_SUBSCRIPTION_ID_SUCCESS]: (state, payload) => {
    const { subscriptionId, address } = payload
    const newSubs = state.subscriptions.slice()
    const newSub = newSubs.find(s => s._id === subscriptionId)
    const corrCustomerId = newSub.billing_summary.customer_id
    // Update view for *all* subscriptions that share customer IDs
    newSubs.forEach((s, i) => {
      if (s.billing_summary.customer_id === corrCustomerId) {
        const updatedSub = { ...s, billing_address: address }
        newSubs[i] = updatedSub
      }
    })
    return { ...state, subscriptions: newSubs }
  },
  [UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_SUCCESS]: (state, payload) => {
    const { subscriptionId, primaryContact } = payload
    const newSubs = state.subscriptions.slice()
    const newSub = newSubs.find(s => s._id === subscriptionId)
    const corrCustomerId = newSub.billing_summary.customer_id
    // Update view for *all* subscriptions that share customer IDs
    newSubs.forEach((s, i) => {
      if (s.billing_summary.customer_id === corrCustomerId) {
        const updatedSub = { ...s, primary_contact: primaryContact }
        newSubs[i] = updatedSub
      }
    })
    return { ...state, subscriptions: newSubs }
  },
  [DEREGISTER_WORKSTATION_KEY_SUCCESS]: (state, payload) => {
    const updatedWorkstation = payload
    const newWorkstations = state.orgEntities.workstations.slice()
    newWorkstations.forEach((w, i) => {
      if (w.workstation._id === updatedWorkstation._id) {
        newWorkstations[i].workstation = updatedWorkstation
      }
    })
    return {
      ...state,
      orgEntities: {
        ...state.orgEntities,
        workstations: newWorkstations,
      },
    }
  },
  [RENAME_WORKSTATION_SUCCESS]: (state, payload) => {
    const updatedWorkstation = payload
    const newWorkstations = state.orgEntities.workstations.slice()
    newWorkstations.forEach((w, i) => {
      if (w.workstation._id === updatedWorkstation._id) {
        newWorkstations[i].workstation = updatedWorkstation
      }
    })
    return {
      ...state,
      orgEntities: {
        ...state.orgEntities,
        workstations: newWorkstations,
      },
    }
  },
  [REGISTER_LICENSE_SUCCESS]: (state, payload) => {
    const license = payload
    const newLicenses = state.licenses.slice()
    newLicenses.forEach((l, i) => {
      if (l._id === license._id) {
        newLicenses[i] = {
          ...newLicenses[i],
          iris_access_document_id: license.iris_access_document_id,
        }
      }
    })
    return { ...state, licenses: newLicenses }
  },
  [DEREGISTER_LICENSE_SUCCESS]: (state, payload) => {
    const license = payload
    const newLicenses = state.licenses.slice()
    newLicenses.forEach((l, i) => {
      if (l._id === license._id) {
        newLicenses[i] = {
          ...newLicenses[i],
          iris_access_document_id: license.iris_access_document_id,
        }
      }
    })
    return { ...state, licenses: newLicenses }
  },
  [ADD_USER_TO_ORG_SUCCESS]: (state, payload) => {
    const userEntity = {
      user: payload.user,
      access_meta: payload.access,
    }
    const newUsers = state.orgEntities.users.slice()
    newUsers.push(userEntity)
    return {
      ...state,
      orgEntities: {
        ...state.orgEntities,
        users: newUsers,
      },
    }
  },
  [UPDATE_USER_ROLE_SUCCESS]: (state, payload) => {
    const updatedUserAccess = payload
    const newUsers = state.orgEntities.users.slice()
    newUsers.forEach((u, i) => {
      if (u.access_meta._id === updatedUserAccess._id) {
        newUsers[i].access_meta = updatedUserAccess
      }
    })
    return {
      ...state,
      orgEntities: {
        ...state.orgEntities,
        users: newUsers,
      },
    }
  },
  [REMOVE_USER_FROM_ORG_SUCCESS]: (state, payload) => {
    const removedUserId = payload.userId
    const newUsers = state.orgEntities.users.slice()
    newUsers.forEach((u, i) => {
      if (u.user._id === removedUserId) {
        newUsers.splice(i, 1)
      }
    })
    return {
      ...state,
      orgEntities: {
        ...state.orgEntities,
        users: newUsers,
      },
    }
  },
})
