import React from 'react'

import { Checkmark, CancelHover } from './icons'

import { LoadingIcon } from '../loadingIndicator'
import { Tooltip } from '@material-ui/core'

import { StatusOptions } from './models'

const Colors = {
  TRANSPARENT: 'rgba(0,0,0,0)',
  GREEN: 'rgba(0,180,0,.6)',
  RED: 'rgba(255,0,0,.6)',
  BLUE: '#0696e1',
  YELLOW: 'rgba(200,200,150,.6)',
  GRAY: 'rgba(200,200,200,.6)',
  WHITE: 'rgba(250,250,250,.3)',
  PURPLE: '#534DC9',
  DARK_PURPLE: '#1A1396',
}

export const ActionButton = props => {
  var { status, enabled, isPending } = props

  const height = 29

  var content = ''
  var textColor = Colors.DARK_PURPLE
  var color = 'white'

  if (!enabled) {
    status = ''
  }

  if (isPending) {
    const rawStatus = status.length == 0 ? '' : status
    status = StatusOptions.Processing
    // only show the status tooltip once we have an actual status response from the server
    content =
      rawStatus.length == 0 ? (
        <LoadingIcon fill={Colors.DARK_PURPLE} />
      ) : (
        <Tooltip title={'status: ' + rawStatus}>
          <span>
            <LoadingIcon fill={Colors.DARK_PURPLE} />
          </span>
        </Tooltip>
      )
    color = Colors.WHITE
    textColor = Colors.DARK_PURPLE
  } else {
    switch (status) {
      case StatusOptions.LINKED:
      case StatusOptions.IMPORT:
      case StatusOptions.PROCESSING:
        content = <div style={{ fontSize: 11 }}>Processing</div>
        break
      case StatusOptions.AVAILABLE:
        color = Colors.TRANSPARENT
        content = <Checkmark />
        break
      case StatusOptions.ERROR:
        color = Colors.RED
        textColor = 'white'
        content = <div>Retry</div>
        break
      default:
        content = StatusOptions.ENABLE
        color = Colors.PURPLE
        textColor = 'white'
    }
  }
  return (
    <div
      style={{
        border: '0px solid rgba(0,0,0,.3)',
        borderRadius: 2,
        height: height,
        background: color,
        textAlign: 'center',
        fontSize: '14px',
        color: textColor,
        lineHeight: '32px',
        textTransform: 'uppercase',
        fontWeight: 300,
        letterSpacing: 0.5,
      }}
    >
      {content}
    </div>
  )
}

export default ActionButton
