import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}

  const requiredFields = [
    // Company general fields
    'companyName',
    'vertical',

    // Address fields
    'addressLine1',
    'country',
    'state',
    'city',
  ]

  requiredFields.forEach(
    field => (errors[field] = !values[field] ? 'Required' : null)
  )

  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (
      response.payload &&
      response.payload.error &&
      response.payload.error.error_field
    ) {
      let errorField = response.payload.error.error_field
      if (errorField === 'company_name') errorField = 'companyName'
      if (errorField === 'address_line1') errorField = 'addressLine1'
      if (errorField === 'address_line2') errorField = 'addressLine2'
      throw new SubmissionError({
        [errorField]: response.payload.error.error_message,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })

export const detectFieldsChanged = (initialValues, newValues, fieldNames) =>
  fieldNames.reduce(
    (acc, curr) => acc || initialValues[curr] !== newValues[curr],
    false
  )
