import { createMuiTheme } from '@material-ui/core'

export const irisTheme = createMuiTheme({
  typography: {
    fontFamily: 'Px Grotesk',
    fontSize: '16px',
  },
  palette: {
    primary: {
      main: '#534DC9',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
        color: 'blue',
      },
    },
    MuiListItem: {
      root: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($error):not($focused):before': {
          borderBottomColor: '#727FE0',
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: 'white',
      },
      colorSecondary: {
        color: 'black',
      },
    },
  },
  props: {
    MuiInput: {
      spellCheck: false,
    },
  },
})
