import {
  GET_PERSONAL_USER_SUBSCRIPTION_STATUS_REQUEST,
  GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS,
  GET_PERSONAL_USER_SUBSCRIPTION_STATUS_FAILURE,
  GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_REQUEST,
  GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS,
  GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_FAILURE,
  GET_SUBSCRIPTIONS_FOR_ORG_REQUEST,
  GET_SUBSCRIPTIONS_FOR_ORG_SUCCESS,
  GET_SUBSCRIPTIONS_FOR_ORG_FAILURE,
  GET_USER_LICENSES_WITHIN_ORG_REQUEST,
  GET_USER_LICENSES_WITHIN_ORG_SUCCESS,
  GET_USER_LICENSES_WITHIN_ORG_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/billing`

export const getPersonalUserSubscriptionStatusRequest = () => ({
  type: GET_PERSONAL_USER_SUBSCRIPTION_STATUS_REQUEST,
})

export const getPersonalUserSubscriptionStatusSuccess = access => ({
  type: GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS,
  payload: access,
})

export const getPersonalUserSubscriptionStatusFailure = () => ({
  type: GET_PERSONAL_USER_SUBSCRIPTION_STATUS_FAILURE,
})

/**
 * Function to retrieve users' subscription status via billing service
 * @returns {Object} payload - API response to subscription creation
 */
export const getPersonalUserSubscriptionStatus = token => dispatch => {
  dispatch(getPersonalUserSubscriptionStatusRequest())

  const url = `${baseurl}/subscriptions`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(getPersonalUserSubscriptionStatusSuccess(payload))
    )
    .catch(err => dispatch(getPersonalUserSubscriptionStatusFailure(err)))
}

export const getUserSubscriptionStatusWithinOrgRequest = () => ({
  type: GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_REQUEST,
})

export const getUserSubscriptionStatusWithinOrgSuccess = access => ({
  type: GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS,
  payload: access,
})

export const getUserSubscriptionStatusWithinOrgFailure = error => ({
  type: GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getUserSubscriptionStatusWithinOrg = (
  token,
  orgId,
  userId
) => dispatch => {
  dispatch(getUserSubscriptionStatusWithinOrgRequest())

  const url = `${baseurl}/orgs/${orgId}/subscriptions/user/${userId}`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(getUserSubscriptionStatusWithinOrgSuccess(payload))
    )
    .catch(error => dispatch(getUserSubscriptionStatusWithinOrgFailure(error)))
}

export const getSubscriptionsForOrgRequest = () => ({
  type: GET_SUBSCRIPTIONS_FOR_ORG_REQUEST,
})

export const getSubscriptionsForOrgSuccess = subscriptions => ({
  type: GET_SUBSCRIPTIONS_FOR_ORG_SUCCESS,
  payload: subscriptions,
})

export const getSubscriptionsForOrgFailure = error => ({
  type: GET_SUBSCRIPTIONS_FOR_ORG_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getSubscriptionsForOrg = (token, orgId) => dispatch => {
  dispatch(getSubscriptionsForOrgRequest())

  const url = `${baseurl}/orgs/${orgId}/subscriptions`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getSubscriptionsForOrgSuccess(payload)))
    .catch(error => dispatch(getSubscriptionsForOrgFailure(error)))
}

export const getUserLicensesWithinOrgRequest = () => ({
  type: GET_USER_LICENSES_WITHIN_ORG_REQUEST,
})

export const getUserLicensesWithinOrgSuccess = licenses => ({
  type: GET_USER_LICENSES_WITHIN_ORG_SUCCESS,
  payload: licenses,
})

export const getUserLicensesWithinOrgFailure = error => ({
  type: GET_USER_LICENSES_WITHIN_ORG_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getUserLicensesWithinOrg = (token, orgId, userId) => dispatch => {
  dispatch(getUserLicensesWithinOrgRequest())

  const url = `${baseurl}/orgs/${orgId}/licenses/user/${userId}`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getUserLicensesWithinOrgSuccess(payload)))
    .catch(error => dispatch(getUserLicensesWithinOrgFailure(error)))
}
