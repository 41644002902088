import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LicensesGrid from './LicensesGrid'
import IrisTitleContainer from '../../../Elements/IrisTitleContainer'
import './OrgAccess.css'

class OrgAccessContainer extends Component {
  static propTypes = {
    productType: PropTypes.string.isRequired,
    licenses: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }

  render() {
    const { productType, licenses, isAdmin } = this.props
    return (
      <div className="org-access-view-container">
        <IrisTitleContainer title="My Access" />
        <LicensesGrid
          productType={productType}
          licenses={licenses}
          isAdmin={isAdmin}
        />
      </div>
    )
  }
}

export default OrgAccessContainer
