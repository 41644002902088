export const _mapServerCardDetailsToClientObject = card => {
  const subscriptionCard = {
    id: '',
    cardType: '',
    status: '',
    expiryMonth: 0,
    expiryYear: 0,
    last4: '',
    maskedNumber: '',
    address: {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      state: '',
      zipcode: '',
    },
  }
  subscriptionCard.id = card.payment_source_id
  subscriptionCard.status = card.status
  subscriptionCard.expiryMonth = card.expiry_month
  subscriptionCard.expiryYear = card.expiry_year
  subscriptionCard.cardType = card.card_type
  subscriptionCard.last4 = card.last4
  subscriptionCard.maskedNumber = card.masked_number
  subscriptionCard.address = {
    firstName: card.first_name,
    lastName: card.last_name,
    addressLine1: card.billing_addr1,
    addressLine2: card.billing_addr2,
    city: card.billing_city,
    country: card.billing_country,
    state: card.billing_state,
    zipcode: card.billing_zip,
  }
  return subscriptionCard
}
