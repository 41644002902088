import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}
  if (!values.name) errors.name = 'Required'
  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (
      response.payload &&
      response.payload.error &&
      response.payload.error.error_field
    ) {
      throw new SubmissionError({
        [response.payload.error.error_field]:
          response.payload.error.error_message,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
