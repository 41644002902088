// Removes modals from being cached, as it's a bit jarring
// to see a zombie modal still open on page refresh
export const _handleModalState = (persistedState, modalInitialState) => {
  // There are exceptions to this rule, of course; if it's
  // a big form we want to keep the fields in tact.
  const allowedCache = [
    'editBillingAddressModal',
    'editCreditCardModal',
    'editPrimaryContactModal',
  ]
  const cachedModal = persistedState.modals && persistedState.modals.modalType
  if (cachedModal && !allowedCache.includes(cachedModal)) {
    persistedState.modals = modalInitialState
  }
  return persistedState
}

export const _handleIrisCookie = persistedState => {
  const irisCookie = __getIrisJwt()
  if (irisCookie.jwt) {
    const auth = {
      token: irisCookie.jwt,
      isUsingViewAsFeature: irisCookie.type === 'admin',
    }
    const persistedStateWithSprinkles = persistedState.auth
      ? { ...persistedState, auth: { ...persistedState.auth, ...auth } }
      : { auth }
    return persistedStateWithSprinkles
  }
  return persistedState
}

export const __getIrisJwt = () => {
  const payload = { jwt: '', type: '' }
  if (__getCookie('iris_jwt')) {
    payload.jwt = __getCookie('iris_jwt')
    payload.type = 'standard'
  }
  if (__getCookie('iris_jwt__proxy')) {
    payload.jwt = __getCookie('iris_jwt__proxy')
    payload.type = 'admin'
  }
  return payload
}

const __getCookie = name => {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v ? v[2] : null
}

export const _purgeIrisCookies = () => {
  return __removeCookie('iris_jwt') || __removeCookie('iris_jwt__proxy')
}

const __removeCookie = name => {
  const domain = __getHostDomain()
  document.cookie = `${name}=; Max-Age=0; Path=/; Domain=${domain};`
}

const __getHostDomain = () => {
  const r = window.location.hostname.split('.')
  return r.length > 1 ? `${r[r.length - 2]}.${r[r.length - 1]}` : 'localhost'
}
