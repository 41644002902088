import { fetch } from '../../auth'

const SET_FEATURE_FLAG_MUTATION = `
mutation SetFeatureFlagForUser( $name:string!, $enabled:bool! ) {
    me {
      debug {
        SetFeatureFlagForUser( name: $name, enabled: $enabled ) {
            name
            description
            enabled
        }
      }
    }
  }
`
const CLEAR_FEATURE_FLAGS_MUTATION = `
mutation {
    me {
      debug {
        ClearFeatureFlagsForUser {
              name
              description
              enabled
          }
      }
    }
  }
`
export const setDebugFeatureFlag = ({
  name = '',
  enabled = false,
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  fetch({
    query: SET_FEATURE_FLAG_MUTATION,
    variables: {
      name: name,
      enabled: enabled,
    },
  }).then(res => {
    if (res.errors && res.errors.length > 0) {
      console.error('There is an error in your query: ' + res.errors[0])
      onFailure(res)
    } else {
      onSuccess({ result: res.data.result })
    }
  })
}

export const clearFeatureFlagsForUser = ({
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  fetch({
    query: CLEAR_FEATURE_FLAGS_MUTATION,
    variables: {},
  }).then(res => {
    if (res.errors && res.errors.length > 0) {
      console.error('There is an error in your query: ' + res.errors[0])
      onFailure(res)
    } else {
      onSuccess({ result: res.data.result })
    }
  })
}
