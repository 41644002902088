import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProductBanner from './ProductBanner'
import OrgAccess from './OrgAccess'
import Trial from './Trial'
import './ProductView.css'

class ProductView extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    userAccessType: PropTypes.string.isRequired,
    access: PropTypes.shape({}).isRequired,
    licenses: PropTypes.array.isRequired,
  }

  state = {
    productType: '',
    userType: '',
    viewType: '',
    isExpiredTrial: false,
  }

  componentWillMount() {
    const { location, userAccessType, access } = this.props
    this.extractRelevantMetadata(location, userAccessType, access)
  }

  componentWillReceiveProps(nextProps) {
    const { location, userAccessType, access } = nextProps
    this.extractRelevantMetadata(location, userAccessType, access)
  }

  extractRelevantMetadata = (location, userAccessType, access) => {
    const productType = this.getProductTypeFromLocation(location.pathname)
    const userType = this.determineUserType(userAccessType)
    this.determineIfExpiredTrial(productType, access)
    this.determineViewType(productType, userType, access)
  }

  getProductTypeFromLocation(pathname) {
    const productType = pathname.replace(/^\//, '')
    this.setState({ productType: productType })
    return productType
  }

  determineUserType(userAccessType) {
    const userType =
      userAccessType === 'personalUser' ? 'personal' : 'orgMember'
    this.setState({ userType })
    return userType
  }

  determineViewType(productType, userType, access) {
    const { licenses } = this.props
    const isAnOrgMember = userType === 'orgMember'
    const hasSomeHistoryWithCurrentProduct =
      access.access.plan_access[productType].subscription_ids
    const hasSomeHistoryThatIsMoreThanJustATrial = !access.access.plan_access[
      productType
    ].is_trial
    const hasSomeHistoryWithWorkstations = licenses.reduce(
      (v, l) => v || l.license_type === 'workstation',
      false
    )

    // If a user is part of an org and they have at least
    // one license assigned *that's not a relevant trial*,
    // they should see the standard org view. Otherwise,
    // org members with a single trial license should see
    // a the personal trial view.
    const viewType =
      (isAnOrgMember &&
        hasSomeHistoryWithCurrentProduct &&
        hasSomeHistoryThatIsMoreThanJustATrial) ||
      hasSomeHistoryWithWorkstations
        ? 'org'
        : 'trial'
    this.setState({ viewType })
    return viewType
  }

  determineIfExpiredTrial(productType, access) {
    const planAccess = access.access.plan_access[productType]
    const isExpiredTrial =
      planAccess.is_trial &&
      planAccess.status === 'non_renewing' &&
      !planAccess.has_access
    if (isExpiredTrial) this.setState({ isExpiredTrial: true })
  }

  render() {
    const { productType, viewType, isExpiredTrial } = this.state
    const { access, licenses, userAccessType } = this.props

    const isAdmin =
      userAccessType === 'teamOwner' || userAccessType === 'teamAdmin'

    return (
      <div>
        {!isExpiredTrial && <ProductBanner productType={productType} />}
        <div className="access-display-container">
          {viewType === 'org' ? (
            <OrgAccess
              productType={productType}
              licenses={licenses}
              isAdmin={isAdmin}
            />
          ) : (
            <Trial productType={productType} access={access} />
          )}
        </div>
      </div>
    )
  }
}

export default ProductView
