import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '../../../Shared/Grid'
import GridHeader from '../../../Shared/Grid/GridHeader'
import LicenseGridRowList from '../../../Shared/Grid/GridRowList/LicenseGridRowList'
import NullLicensesView from '../../../Shared/NullLicensesView'
import AssignLicenseButton from './AssignLicenseButton'
import AdminWorkstationLicenseGridRowList from './AdminWorkstationLicenseGridRowList'
import { _extractRelevantMetadata } from './util'
import './LicensesGrid.css'

class LicensesGridView extends Component {
  static propTypes = {
    orgId: PropTypes.string.isRequired,
    orgEntities: PropTypes.shape({
      users: PropTypes.array.isRequired,
      workstations: PropTypes.array.isRequired,
    }).isRequired,
    licenses: PropTypes.array.isRequired,
  }

  state = {
    users: [],
    workstations: [],
  }

  componentWillMount() {
    const { licenses, orgEntities, orgId } = this.props
    this.extractRelevantMetadata(licenses, orgEntities, orgId)
  }

  componentWillReceiveProps(nextProps) {
    const { licenses, orgEntities, orgId } = nextProps
    this.extractRelevantMetadata(licenses, orgEntities, orgId)
  }

  extractRelevantMetadata = (licenses, orgEntitiesObj, orgId) => {
    const licensesExist = licenses.length
    const orgUsersExist = orgEntitiesObj.users.length
    const orgWorkstationsExist = orgEntitiesObj.workstations.length
    const orgEntitiesExist = orgUsersExist || orgWorkstationsExist
    const worthExtracting = licensesExist && orgEntitiesExist
    if (worthExtracting) {
      const sortedLicensesObj = _extractRelevantMetadata(
        licenses,
        orgEntitiesObj
      )
      if (orgUsersExist) {
        const orgUsers = orgEntitiesObj.users.map(uo => ({
          name: uo.user.full_name,
          username: uo.user.username,
          accessId: uo.access_meta._id,
        }))
        const userLicenses = this.handleAssignLicenseButton(
          sortedLicensesObj.users,
          orgUsers,
          orgId
        )
        sortedLicensesObj.users = userLicenses
      }
      this.setState(sortedLicensesObj)
    }
  }

  handleAssignLicenseButton = (userLicenses = [], orgUsers, orgId) =>
    userLicenses.map(ul =>
      ul.userTitle === '__RENDER_ASSIGN_BUTTON__'
        ? {
            ...ul,
            userTitle: (
              <AssignLicenseButton
                orgId={orgId}
                licenseId={ul.id}
                licenseType={ul.name}
                orgUsers={orgUsers}
              />
            ),
          }
        : ul
    )

  render() {
    const { users, workstations } = this.state
    return (
      <div className="licenses-grid-container">
        <Grid>
          <GridHeader
            columns={[
              {
                label: 'License',
                className: 'license-type',
              },
              {
                label: 'User',
                className: 'license-user',
              },
              {
                label: 'Term End',
                className: 'term-end',
              },
              {
                label: 'Status',
                className: 'subscription-status',
              },
            ]}
          />
          {workstations.length ? (
            <div className="workstation-licenses-container licenses-section-container">
              <p className="header">Workstation Licenses</p>
              <AdminWorkstationLicenseGridRowList
                rowData={workstations}
                rowColumns={[
                  {
                    dataKey: 'name',
                    className: 'license-type',
                  },
                  {
                    dataKey: 'userTitle',
                    className: 'user-title',
                  },
                  {
                    dataKey: 'endDate',
                    className: 'term-end',
                  },
                  {
                    dataKey: 'status',
                    className: 'subscription-status',
                  },
                ]}
              />
            </div>
          ) : (
            ''
          )}
          {users.length ? (
            <div className="single-user-licenses-container licenses-section-container">
              <p className="header">Single User Licenses</p>
              <LicenseGridRowList
                rowData={users}
                rowColumns={[
                  {
                    dataKey: 'name',
                    className: 'license-type',
                  },
                  {
                    dataKey: 'userTitle',
                    className: 'user-title',
                  },
                  {
                    dataKey: 'endDate',
                    className: 'term-end',
                  },
                  {
                    dataKey: 'status',
                    className: 'subscription-status',
                  },
                ]}
              />
            </div>
          ) : (
            ''
          )}
          {!workstations.length && !users.length ? (
            <NullLicensesView>
              <p>
                You don't have any active licenses. If you'd like to purchase a
                license, please contact{' '}
                <a href="mailto:sales@irisvr.com">sales@irisvr.com</a>
              </p>
            </NullLicensesView>
          ) : (
            ''
          )}
        </Grid>
      </div>
    )
  }
}

export default LicensesGridView
