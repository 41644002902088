import React from 'react'
import PropTypes from 'prop-types'
import './Grid.css'

const Grid = ({ children }) => <div className="grid-container">{children}</div>

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Grid
