import React from 'react'

import LoadingAnimation from '../loading/loading.gif'

export class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dirty: false,
      onChange: props.onChange,
      query: props.query,
      processing: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.processingComplete = this.processingComplete.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.label !== nextProps.label) {
    //     this.setState({ label: nextProps.label })
    // }
  }

  processingComplete() {
    this.setState({ processing: false })
  }

  processingTimeout = false

  handleChange(evt) {
    this.setState({ query: evt.target.value, dirty: true, processing: true })
    this.state.onChange({ ele: evt.target, query: evt.target.value })

    if (this.processingTimeout) clearTimeout(this.processingTimeout)
    this.processingTimeout = setTimeout(this.processingComplete, 200)
  }

  render() {
    return (
      <div
        style={{
          borderBottom: '1px solid #C7C7C7',
        }}
      >
        <div
          style={{
            padding: 6,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                marginRight: 5,
                marginLeft: 37,
                marginTop: 3,
              }}
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.1214 10.3751H10.4259L10.1841 10.1353C11.0382 9.14044 11.5577 7.85069 11.5577 6.43756C11.5577 3.29631 9.00857 0.750063 5.8638 0.750063C2.71903 0.750063 0.169922 3.29631 0.169922 6.43756C0.169922 9.57881 2.71903 12.1251 5.8638 12.1251C7.27851 12.1251 8.56883 11.6071 9.56482 10.7548L9.80659 10.9946V11.6876L14.1847 16.0547L15.4908 14.7501L11.1214 10.3751ZM5.8638 10.3751C3.68611 10.3751 1.92188 8.61281 1.92188 6.43756C1.92188 4.26319 3.68611 2.50006 5.8638 2.50006C8.04061 2.50006 9.80571 4.26319 9.80571 6.43756C9.80571 8.61281 8.04061 10.3751 5.8638 10.3751Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder={'Search for projects'}
              value={this.state.query}
              onChange={this.handleChange}
              style={{
                outline: 'none',
                fontSize: 14,
                width: '80%',
                padding: 2,
                border: 0,
                display: 'inline-block',
                background: this.state.processing
                  ? 'url(' + LoadingAnimation + ') no-repeat right'
                  : '',
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
