import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  _copyTextValueToClipboard,
  _splitWorkstationKey,
} from '../../../../util'
import IrisButton from '../../../Elements/IrisButton'
import '../ManageWorkstationModal.css'

class CopyWorkstationKeyModal extends Component {
  static propTypes = {
    workstationKey: PropTypes.string.isRequired,
  }

  state = {
    ctaText: 'Copy Workstation Key',
  }

  copyKey = () => {
    const { workstationKey } = this.props
    _copyTextValueToClipboard(workstationKey)
    this.setState({ ctaText: 'Copied to Clipboard' })
    setTimeout(() => this.setState({ ctaText: 'Copy Workstation Key' }), 3000)
  }

  render() {
    const { ctaText } = this.state
    const { workstationKey } = this.props
    const splitKey = _splitWorkstationKey(workstationKey)

    return (
      <div
        className="
        manage-workstation-modal
        copy-workstation-key-modal
      "
      >
        <div className="modal-title-container">
          <p className="modal-title">Your Workstation Key</p>
        </div>
        <div className="modal-body-container">
          <p className="body-content">
            Use the following key to register your workstation machine
          </p>
          <div className="workstation-key-container">
            {splitKey.map(k => (
              <span className="workstation-key" key={k}>
                {k}
              </span>
            ))}
          </div>
        </div>
        <IrisButton buttonText={ctaText} onClick={this.copyKey} />
      </div>
    )
  }
}

export default CopyWorkstationKeyModal
