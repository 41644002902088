import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { IrisTextField } from '../../../Elements/IrisFormFields'
import IrisButton from '../../../Elements/IrisButton'
import { updatePrimaryContactBySubscriptionId } from '../../../../actions'
import { validateOnClient, validateOnServer } from './validate'
import './PrimaryContactForm.css'

class PrimaryContactForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    onSuccessfulUpdate: PropTypes.func.isRequired,
    updatePrimaryContactBySubscriptionId: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  state = { error: '' }

  updatePrimaryContact = data => {
    this.setState({ error: '' })

    const primaryContactData = {
      full_name: data.fullName,
      email: data.email,
      phone: data.phone,
    }

    return this.updatePrimaryContactOnServer(primaryContactData)
  }

  updatePrimaryContactOnServer = primaryContact => {
    const {
      dirty,
      token,
      orgId,
      subscriptionId,
      updatePrimaryContactBySubscriptionId,
      onSuccessfulUpdate,
    } = this.props

    if (dirty) {
      return updatePrimaryContactBySubscriptionId(
        token,
        orgId,
        subscriptionId,
        primaryContact
      )
        .then(validateOnServer)
        .then(res => onSuccessfulUpdate())
        .catch(this.handleErrorMessage)
    }
    return onSuccessfulUpdate()
  }

  handleErrorMessage = error =>
    this.setState({ error: `${error} Please try again.` })

  render() {
    const { handleSubmit, submitting } = this.props
    const { error } = this.state
    const submittingClass = submitting ? 'form-submitting' : ''
    return (
      <form
        className={`primary-contact-form ${submittingClass}`}
        onSubmit={handleSubmit(this.updatePrimaryContact)}
      >
        <div className="form-fields-container">
          <div className="form-fields">
            <IrisTextField name="fullName" label="Full Name" fullWidth />
            <IrisTextField name="email" label="Email Address" fullWidth />
            <IrisTextField name="phone" label="Phone Number" fullWidth />
          </div>
        </div>

        {error && (
          <div className="error-container">
            <p className="error">{error}</p>
          </div>
        )}

        <IrisButton
          buttonText="Save Edits"
          onClick={handleSubmit(this.updatePrimaryContact)}
        />
      </form>
    )
  }
}

const PrimaryContactReduxForm = reduxForm({
  form: 'primaryContactForm',
  validate: validateOnClient,
})(PrimaryContactForm)

const mapStateToProps = (state, ownProps) => {
  const { primaryContact } = ownProps
  return {
    token: state.auth.token,
    orgId: state.orgs.selectedOrg._id,
    initialValues: { ...primaryContact },
  }
}

export default connect(mapStateToProps, {
  updatePrimaryContactBySubscriptionId,
})(PrimaryContactReduxForm)
