import { SHOW_MODAL, HIDE_MODAL } from '../../constants'

export const showModal = (modalType, modalProps) => dispatch =>
  dispatch({
    type: SHOW_MODAL,
    payload: {
      modalType,
      modalProps,
    },
  })

export const hideModal = () => dispatch =>
  dispatch({
    type: HIDE_MODAL,
  })
