import React from 'react'

import IntegrationHeader from './header'
import { RootProjects } from '../index'

export const SummaryView = props => {
  const {
    data = {}, // this is the result of <Browser data={data.bim360} />
    expandedProjectIds,

    activeProjectId,
    onProjectSelected,
    onModelViewsToggle,
    setSyncState,
    hydrateSyncState,
    disconnectIntegration = () => alert('no handler for disconnectIntegration'),

    searchQuery,
    searchQueryUpdated,
    addSyncEnabledItemIdToLoadingList,
    updateSyncEnabledItemsLoadingList,
    syncEnabledItemIdsCurrentlyLoading,
    syncEnabledItemIdErrorMessages,
  } = props

  const handleModuleClick = props.handleModuleClick
  const identity = (data && data.identity) || { userId: '' }
  const isLoggedIn = identity && identity.userId.length > 3
  const oAuthConfig = (data && data.oAuthConfig) || { clientId: '' }
  const projects = data ? data.projects : []
  // var syncEnabledItems = []
  // if (isLoggedIn) {
  //     syncEnabledItems = data.bim360.state.syncEnabledItems
  //     console.log( "set syncEnabled Items",syncEnabledItems )
  // }
  var expanded = false
  if (expandedProjectIds.includes('bim360_integration')) {
    expanded = true
  }

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid #CBCBCB',
        borderRadius: 2,
        marginTop: 20,
        width: '100%',
        maxWidth: 925,
        background: 'white',
        marginLeft: 40,
        marginBottom: 40,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <IntegrationHeader
            oAuthConfig={oAuthConfig}
            identity={identity}
            enabled={isLoggedIn}
            expanded={expanded && isLoggedIn}
            onClick={handleModuleClick}
            disconnectIntegration={disconnectIntegration}
          />

          {expanded && isLoggedIn ? (
            <div
              style={{
                paddingTop: 0,
                paddingLeft: 18,
                paddingRight: 13,
                paddingBottom: 30,
              }}
            >
              <RootProjects
                items={projects}
                expandedProjectIds={expandedProjectIds}
                activeProjectId={activeProjectId}
                onProjectSelected={onProjectSelected}
                onModelViewsToggle={onModelViewsToggle}
                setSyncState={setSyncState}
                hydrateSyncState={hydrateSyncState}
                addSyncEnabledItemIdToLoadingList={
                  addSyncEnabledItemIdToLoadingList
                }
                searchQuery={searchQuery}
                searchQueryUpdated={searchQueryUpdated}
                syncEnabledItemIdsCurrentlyLoading={
                  syncEnabledItemIdsCurrentlyLoading
                }
                updateSyncEnabledItemsLoadingList={
                  updateSyncEnabledItemsLoadingList
                }
                syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default SummaryView
