import React from 'react'
import { getFrontendRedirectBaseUrl } from '../../../../util'

const TOCLabel = () => {
  // Use inline styles as MaterialUI may override sass
  const grey = '#757575'
  const linkStyle = {
    color: grey,
    borderColor: grey,
  }

  return (
    <span className="toc-label">
      I accept the{' '}
      <a
        href={`${getFrontendRedirectBaseUrl()}/terms`}
        target="_blank"
        style={linkStyle}
      >
        Terms and Conditions
      </a>{' '}
      and{' '}
      <a
        href={`${getFrontendRedirectBaseUrl()}/privacy`}
        target="_blank"
        style={linkStyle}
      >
        Privacy Policy
      </a>
    </span>
  )
}

export default TOCLabel
