import React, { useState } from 'react'
import { Checkmark, CancelHover } from './icons'
import Chevron from '../chevron'

import { LATEST } from '../../../../constants'
import { isDevelopmentEnvironment } from '../../../integrations'
import { LoadingIcon } from '../loadingIndicator'
import { Tooltip } from '@material-ui/core'

import OpenInBIM360Icon from '../elements/openInBim360Icon'
import ActionButton from './actionButton'
import { withStyles, makeStyles } from '@material-ui/core/styles'

const width = 88

const pendingCursor = 'default'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 420,
    fontSize: '12px',
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const ListEntryRow = ({
  itemDetails,
  syncEnabledItems,
  projectID,
  setSyncState,
  addSyncEnabledItemIdToLoadingList,
  syncEnabledItemIdsCurrentlyLoading,
  syncEnabledItemIdErrorMessages,
  onModelViewsToggle,
  expandedIds,
  idx,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { path, file, folder, filename, views = [] } = itemDetails
  const modelId = file.id
  const modelViewId = ''
  const isModelChevronOpen = expandedIds.includes(modelId)

  // generate the id value which we will see in syncEnabledItems from the props of the model
  const id = modelId + '&' + modelViewId

  // here we want to use the id value since we can not rely on modelId for uniquness
  let k = id

  const getItemFromSyncEnabledItems = itemId => {
    return syncEnabledItems.filter(i => i.id === itemId)[0]
  }
  const syncItemEnabled = itemId => {
    const item = getItemFromSyncEnabledItems(itemId)
    return Boolean(item && item.enabled)
  }
  const syncEnabled = syncItemEnabled(k)
  const syncItemStatus = itemId => {
    const item = getItemFromSyncEnabledItems(itemId)
    return (item && item.status) || ''
  }
  const item = getItemFromSyncEnabledItems(k)

  const modelSyncItemStatus = syncItemStatus(k)
  const cleanProjectId = projectID.substr(2)
  const folderId = folder.id
  const bim360File =
    'https://docs.b360.autodesk.com/projects/' +
    cleanProjectId +
    '/folders/' +
    folderId +
    '/detail/viewer/items/' +
    modelId

  const hasViewpoints = views.length > 0
  const syncEnabledModelErrorMessage = syncEnabledItemIdErrorMessages[k]
  const hasSyncEnabledModelErrored = Boolean(syncEnabledModelErrorMessage)
  let isCurrentlyPending =
    syncEnabledItemIdsCurrentlyLoading &&
    syncEnabledItemIdsCurrentlyLoading.includes(id)

  if (modelSyncItemStatus == StatusOptions.PROCESSING) {
    isCurrentlyPending = true
  }
  if (modelSyncItemStatus == StatusOptions.LINKED) {
    isCurrentlyPending = true
  }
  if (modelSyncItemStatus == StatusOptions.AVAILABLE) {
    isCurrentlyPending = false
  }
  if (modelSyncItemStatus == StatusOptions.ERROR) {
    isCurrentlyPending = false
  }

  if (modelSyncItemStatus == StatusOptions.UNLINK) {
    isCurrentlyPending = true
  }
  if (syncEnabled == false && modelSyncItemStatus == StatusOptions.UNLINK) {
    isCurrentlyPending = true
  }

  return (
    <div>
      <div
        key={modelId}
        id={modelId}
        style={{
          display: 'flex',
          alignItems: 'center',
          minHeight: 53,
          backgroundColor: idx % 2 == 0 ? 'white' : '#FBFAFD',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          style={{
            width: '72%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 0,
            marginRight: 13,
            marginLeft: 52,
          }}
        >
          <div
            style={{
              marginRight: hasViewpoints ? 12 : 23,
            }}
          >
            {hasViewpoints && (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onModelViewsToggle({ id: modelId })
                }}
              >
                <Chevron direction={isModelChevronOpen ? 'down' : 'right'} />
              </div>
            )}
          </div>
          <div
            style={{ cursor: hasViewpoints ? 'pointer' : '' }}
            onClick={() => {
              hasViewpoints && onModelViewsToggle({ id: modelId })
              //setIsModelChevronOpen(!isModelChevronOpen)
            }}
          >
            {isDevelopmentEnvironment ? (
              <HtmlTooltip
                enterDelay={500}
                leaveDelay={200}
                title={
                  <React.Fragment>
                    <ModelDetails id={id} path={path.replaceAll('\\', '/')} />
                  </React.Fragment>
                }
              >
                <span>{filename}</span>
              </HtmlTooltip>
            ) : (
              <span>{filename}</span>
            )}
            {hasSyncEnabledModelErrored && (
              <div style={{ marginTop: 5 }}>
                <div style={{ fontSize: 12, color: 'red' }}>
                  {isDevelopmentEnvironment
                    ? syncEnabledModelErrorMessage
                    : 'There was an error importing this model. Please try again.'}
                </div>
              </div>
            )}
          </div>
          {isHovered && (
            <div style={{ marginLeft: 20 }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(bim360File, cleanProjectId)
                }}
              >
                <OpenInBIM360Icon />
              </div>
            </div>
          )}
        </div>
        {!hasViewpoints && (
          <ModelRow
            id={id}
            projectID={projectID}
            modelId={modelId}
            syncEnabled={syncEnabled}
            isCurrentlyPending={isCurrentlyPending}
            syncEnabledItemIdsCurrentlyLoading={
              syncEnabledItemIdsCurrentlyLoading
            }
            syncEnabledItems={syncEnabledItems}
            modelSyncItemStatus={modelSyncItemStatus}
            setSyncState={setSyncState}
            addSyncEnabledItemIdToLoadingList={
              addSyncEnabledItemIdToLoadingList
            }
          />
        )}
        <div></div>
      </div>
      {hasViewpoints &&
        isModelChevronOpen &&
        views.map((viewpoint, key) => {
          idx++
          const rowIndex = idx
          const id = modelId + '&' + viewpoint.guid

          const syncEnabled = syncItemEnabled(id)
          let modelViewSyncItemStatus = ''
          let isCurrentlyPending =
            syncEnabledItemIdsCurrentlyLoading &&
            syncEnabledItemIdsCurrentlyLoading.includes(id)

          const entry = getItemFromSyncEnabledItems(id)
          if (entry != undefined) {
            modelViewSyncItemStatus = entry.status
          }

          if (modelViewSyncItemStatus == StatusOptions.PROCESSING) {
            isCurrentlyPending = true
          }
          if (modelViewSyncItemStatus == StatusOptions.UNLINK) {
            isCurrentlyPending = true
          }
          if (modelViewSyncItemStatus == StatusOptions.LINKED) {
            isCurrentlyPending = true
          }
          if (modelViewSyncItemStatus == StatusOptions.AVAILABLE) {
            isCurrentlyPending = false
          }
          if (modelViewSyncItemStatus == StatusOptions.ERROR) {
            isCurrentlyPending = false
          }

          return (
            <div
              key={`${id}`}
              style={{
                backgroundColor: rowIndex % 2 == 0 ? 'white' : '#FBFAFD',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 97,
                  marginRight: 100,
                }}
              >
                <div style={{}}>
                  {viewpoint.name}
                  {isDevelopmentEnvironment && (
                    <ModelViewDetails id={id} path={path} />
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  <ModelRow
                    id={id}
                    projectID={projectID}
                    modelId={modelId}
                    modelViewId={viewpoint.guid}
                    syncEnabled={syncEnabled}
                    isCurrentlyPending={isCurrentlyPending}
                    syncEnabledItemIdsCurrentlyLoading={
                      syncEnabledItemIdsCurrentlyLoading
                    }
                    syncEnabledItems={syncEnabledItems}
                    modelSyncItemStatus={modelViewSyncItemStatus}
                    setSyncState={setSyncState}
                    addSyncEnabledItemIdToLoadingList={
                      addSyncEnabledItemIdToLoadingList
                    }
                  />
                  {/* <ModelViewRow 
                id={id}
                projectID={projectID}
                modelId={modelId}
                viewpoint={viewpoint}
                syncEnabledItemIdsCurrentlyLoading={syncEnabledItemIdsCurrentlyLoading}
                syncEnabledItems={syncEnabledItems}

                setSyncState={setSyncState}
                addSyncEnabledItemIdToLoadingList={addSyncEnabledItemIdToLoadingList}
              /> */}
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

const ModelDetails = ({ id = '', path = '' }) => (
  <div>
    <div
      style={{
        fontSize: 10,
      }}
    >
      <strong>{path}</strong>
      <br />
    </div>
    <div
      style={{
        fontSize: 8,
      }}
    >
      <strong>{id}</strong>
      <br />
    </div>
  </div>
)

const ModelViewDetails = ({ id = '', path = '' }) => (
  <div>
    <div
      style={{
        fontSize: 8,
      }}
    >
      <strong>{id}</strong>
      <br />
    </div>
  </div>
)

const ModelRow = ({
  id = '',
  projectID = '',
  modelId = '',
  modelViewId = '',
  syncEnabled,

  isCurrentlyPending,
  modelSyncItemStatus,

  addSyncEnabledItemIdToLoadingList = () => {},
  setSyncState = () => {},
}) => {
  return (
    <div>
      <span
        style={{
          cursor: isCurrentlyPending ? pendingCursor : 'pointer',
        }}
        onClick={_ => {
          if (isCurrentlyPending) {
            console.log('Pending, ignore ')
          } else if (modelSyncItemStatus != 'processing') {
            setSyncState({
              id: id,
              urn: modelId,
              modelViewId: modelViewId,
              projectID: projectID,
              enabled: !syncEnabled,
              status:
                syncEnabled == true
                  ? StatusOptions.UNLINK
                  : StatusOptions.IMPORT,
            })
          } else {
            console.log('processing, please wait')
          }
        }}
      >
        <div
          style={{
            position: 'relative',
            width: width,
            display: 'inline-block',
          }}
        >
          <ActionButton
            status={modelSyncItemStatus}
            enabled={syncEnabled}
            isPending={isCurrentlyPending}
          />
        </div>
      </span>
    </div>
  )
}

const ListEntryRows = ({
  items,
  syncEnabledItems,
  projectID,
  setSyncState,
  addSyncEnabledItemIdToLoadingList,
  syncEnabledItemIdsCurrentlyLoading,
  syncEnabledItemIdErrorMessages,
  onModelViewsToggle,

  expandedIds,
}) => {
  return items
    .slice()
    .sort((a, b) => a.filename.localeCompare(b.filename))
    .map((entry, idx) => {
      const { path, file, folder, filename, versions = [], views = [] } = entry

      return (
        <ListEntryRow
          key={idx}
          idx={idx}
          model={entry}
          expandedIds={expandedIds}
          itemDetails={{ path, file, folder, filename, versions, views }}
          syncEnabledItems={syncEnabledItems}
          projectID={projectID}
          setSyncState={setSyncState}
          onModelViewsToggle={onModelViewsToggle}
          addSyncEnabledItemIdToLoadingList={addSyncEnabledItemIdToLoadingList}
          syncEnabledItemIdsCurrentlyLoading={
            syncEnabledItemIdsCurrentlyLoading
          }
          syncEnabledItemIdErrorMessages={syncEnabledItemIdErrorMessages}
        />
      )
    })
}

export const ModelListing = props => {
  const { items } = props

  if (items === undefined) {
    console.error('items undefined in ModelListing')
  }

  if (items === undefined || items.length === 0) {
    return (
      <div style={rowStyle(0)}>
        <strong>No Models in project.</strong>
      </div>
    )
  }
  return <ListEntryRows {...props} />
}
ModelListing.defaultProps = {
  setSyncState: value => {
    alert(`no handler defined for setSyncState ${value}`)
  },
  syncEnabled: false,
}
export default ModelListing

export const StatusOptions = {
  NONE: '',
  ERROR: 'error',
  ENABLE: 'enable',
  PENDING: 'pending',
  IMPORT: 'import',
  PROCESSING: 'processing',
  CITADEL: 'citadel',
  CONVERT: 'convert',
  EXTRACT: 'extract',
  AVAILABLE: 'available',
  LINKED: 'linked',
  UNLINK: 'unlink',
}
