import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import {
  IrisTextField,
  IrisDropdown,
  IrisCheckbox,
} from '../../../../Elements/IrisFormFields'
import IrisButton from '../../../../Elements/IrisButton'
import ResetPassword from './ResetPassword'
import PageFooter from '../../../../Shared/PageFooter'
import {
  industryList,
  countriesWithCorrespondingStates,
} from '../../../../../util/data'
import { _removeDeepEmptyFields } from '../../../../../util'
// TODO : VALIDATE ON SERVER ERROR
import { validateOnClient } from './validate'
import './SettingsFormContainer.css'

class SettingsFormContainer extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}).isRequired,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userData: PropTypes.shape({}).isRequired,
    updateUser: PropTypes.func.isRequired,
    displayResetPasswordModal: PropTypes.func.isRequired,
  }

  state = {
    countries: [],
    states: [],
  }

  componentWillMount() {
    this.setState({ countries: Object.keys(countriesWithCorrespondingStates) })
    // Set states list if initial country is detected
    const {
      initialValues: { country },
    } = this.props
    if (country) this.onCountrySelect(null, country)
  }

  onCountrySelect = (_, country) => {
    const states = countriesWithCorrespondingStates[country]
    if (states) this.setState({ states })
  }

  updateUser = data => {
    const { dirty, updateUser } = this.props
    if (dirty) {
      const requestBody = {
        full_name: data.fullName,
        display_name: data.displayName,
        personal_info: {
          company: data.company,
          job_title: data.title,
          industry: data.industry,
          country: data.country,
          state: data.state,
          city: data.city,
          phone: data.phoneNumber,
        },
        email_subscription: {
          general: {
            subscribed: data.emailSubscription,
          },
        },
      }
      _removeDeepEmptyFields(requestBody)
      return updateUser(requestBody)
      // TODO : DISPLAY CHECKMARK TO INDICATE SUCCESSFUL SAVE
    }
  }

  render() {
    const { handleSubmit, dirty, displayResetPasswordModal } = this.props
    const { countries, states } = this.state

    const buttonClass = dirty ? 'activated' : ''

    return (
      <div className="settings-form-container">
        <form
          className="settings-form iris-form"
          onSubmit={handleSubmit(this.updateUser)}
        >
          <div className="form-fields">
            <IrisTextField name="fullName" label="Full Name" />
            <IrisTextField name="displayName" label="Preferred Name" />
            <IrisTextField name="company" label="Company" />
            <IrisTextField name="title" label="Job Title" />
            <IrisDropdown
              data={industryList}
              fieldName="industry"
              label="Industry"
            />
            <IrisDropdown
              data={countries}
              fieldName="country"
              label="Country"
              onChange={this.onCountrySelect}
            />
            <IrisDropdown data={states} fieldName="state" label="State" />
            <IrisTextField name="city" label="City" />
            <IrisTextField name="phoneNumber" label="Phone Number" />
            <IrisCheckbox
              header="Emails"
              fieldName="emailSubscription"
              labelContent="Keep me posted on IrisVR news and product updates"
            />
            <ResetPassword showModal={displayResetPasswordModal} />
          </div>

          <PageFooter className="settings-footer">
            <IrisButton
              className={buttonClass}
              buttonText="Save Edits"
              onClick={handleSubmit(this.updateUser)}
            />
          </PageFooter>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userData } = ownProps
  return {
    initialValues: {
      fullName: userData.full_name,
      displayName: userData.display_name,
      company: userData.personal_info.company,
      title: userData.personal_info.job_title,
      industry: userData.personal_info.industry,
      country: userData.personal_info.country,
      state: userData.personal_info.state,
      city: userData.personal_info.city,
      phoneNumber: userData.personal_info.phone,
      emailSubscription: userData.email_subscription.general.subscribed,
    },
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'settingsForm',
    validateOnClient,
    enableReinitialize: true,
  })(SettingsFormContainer)
)
