import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectOrgAndFetchRelevantBillingData } from '../../../../actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import './SelectOrg.css'

class SelectOrg extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    orgs: PropTypes.array.isRequired,
    selectOrgAndFetchRelevantBillingData: PropTypes.func.isRequired,
  }

  static contextTypes = {
    router: PropTypes.shape({}).isRequired,
  }

  state = {
    didTransition: false,
    opacity: '0 !important',
    leftMargin: 0,
  }

  componentDidMount() {
    setTimeout(() => {
      let longestChildWidth = 0
      const children = Array.from(this.orgList.children)
      children.forEach(li => {
        const childWidth = li.children[0].offsetWidth
        if (childWidth > longestChildWidth) longestChildWidth = childWidth
      })
      this.setState({
        leftMargin: longestChildWidth / 2 + 10,
        opacity: 0,
      })
    }, 200)
  }

  selectOrg = orgId => {
    const { token, userId, selectOrgAndFetchRelevantBillingData } = this.props
    this.setState({ didTransition: true })
    setTimeout(() => {
      selectOrgAndFetchRelevantBillingData(token, orgId, userId).then(() =>
        this.context.router.history.push('/prospect')
      )
    }, 1000)
  }

  render() {
    const { orgs } = this.props
    const { didTransition, opacity, leftMargin } = this.state

    const mainContentStyle = {
      opacity: Number(!didTransition),
      pointerEvents: didTransition ? 'none' : 'auto',
      transition: 'opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1)',
    }
    const loaderStyle = {
      opacity: Number(didTransition),
      pointerEvents: didTransition ? 'auto' : 'none',
      transition: 'opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1)',
    }

    return (
      <div className="select-org-page">
        <div className="select-org-card-container iris-outline">
          <div className="select-org-inner-container">
            <div className="text-container" style={mainContentStyle}>
              <div className="title-container">
                <p className="title">Select your team:</p>
              </div>
              <div
                className="orgs-list-container"
                ref={l => (this.orgList = l)}
              >
                {orgs.map(o => (
                  <div
                    className="org-list-item"
                    key={o._id}
                    onClick={this.selectOrg.bind(null, o._id)}
                  >
                    <p className="org-title">{o.company_name}</p>
                    <i
                      className="material-icons"
                      style={{
                        opacity,
                        marginLeft: `${leftMargin}px`,
                      }}
                    >
                      arrow_forward
                    </i>
                  </div>
                ))}
              </div>
            </div>
            <div className="loader-container" style={loaderStyle}>
              <CircularProgress
                className="loading-gif"
                size={25}
                color="inherit"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  userId: state.auth.user && state.auth.user._id,
  orgs: state.orgs.orgs,
})

export default connect(mapStateToProps, {
  selectOrgAndFetchRelevantBillingData,
})(SelectOrg)
