import React from 'react'
import { IrisTextField } from '../../IrisFormFields'

const NameFormFields = () => (
  <div className="form-fields-container name-fields">
    <p className="form-fields-title">Name on card</p>
    <div className="form-fields">
      <IrisTextField name="firstName" label="First Name" />
      <IrisTextField name="lastName" label="Last Name" />
    </div>
  </div>
)

export default NameFormFields
