import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Banner.css'

class Banner extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dismiss: PropTypes.func.isRequired,
  }

  render() {
    const { children, dismiss } = this.props

    return (
      <div className="banner-container">
        <div className="content-container">{children}</div>
        <div className="dismiss-container">
          <i className="material-icons dismiss" onClick={dismiss}>
            close
          </i>
        </div>
      </div>
    )
  }
}

export default Banner
