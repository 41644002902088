import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
import { _purgeIrisCookies } from '../../store/cookie'
const baseurl = `${getBaseUrl()}/auth`

export const loginUserRequest = () => ({
  type: LOGIN_USER_REQUEST,
})

export const loginUserSuccess = payload => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  }
}

export const loginUserFailure = error => dispatch =>
  new Promise((resolve, reject) => {
    window.localStorage.clear()
    resolve(
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: { error },
      })
    )
  })

export const login = data => dispatch => {
  dispatch(loginUserRequest())
  const url = `${baseurl}/user/login`
  return request
    .post(url)
    .withCredentials()
    .type('json')
    .send(data)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(loginUserSuccess(payload)))
    .catch(e => {
      switch (e.code) {
        case 203:
        case 204:
          return dispatch(
            loginUserFailure({
              statusText: e.error_message,
              field: 'username',
            })
          )
        case 207:
          return dispatch(
            loginUserFailure({
              statusText: e.error_message,
              field: 'password',
            })
          )
        default:
          return dispatch(
            loginUserFailure({
              statusText: 'the username or password you entered is incorrect',
              field: 'password',
            })
          )
      }
    })
}

export const logoutUser = () => dispatch => {
  // Clear client-side auth factors
  window.localStorage.clear()
  _purgeIrisCookies()

  // Asynchronously call `logout` server
  // endpoint to clear browser cookies
  const url = `${baseurl}/user/logout`
  request
    .get(url)
    .withCredentials()
    .end()

  return Promise.resolve(dispatch({ type: LOGOUT_USER }))
}
