import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { _searchToJson, _isUUID, _isUrl } from '../../../../util'
import { _findSavedAuthSession } from '../../../../store/auth'
import { connect } from 'react-redux'
import {
  login,
  logoutUser,
  showModal,
  fetchRelevantUserAndOrgData,
  confirmUserEmail,
  getZendeskSSOUrl,
} from '../../../../actions'
import { validateOnServer } from './LoginForm/validate'
import DownloadProspectView from './DownloadProspectView'
import LoginForm from './LoginForm'
import './Login.css'

class Login extends Component {
  static propTypes = {
    location: PropTypes.shape({}).isRequired,
    orgs: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
    user: PropTypes.shape({}).isRequired,
    userAccessType: PropTypes.string.isRequired,
    confirmation: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      error: PropTypes.string.isRequired,
    }).isRequired,

    // action creators
    login: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    fetchRelevantUserAndOrgData: PropTypes.func.isRequired,
    confirmUserEmail: PropTypes.func.isRequired,
    getZendeskSSOUrl: PropTypes.func.isRequired,
  }

  state = {
    redirectToReferrer: '',
    redirectQuery: 'prospect',
    displayInternalAdminPrompt: false,
    confirmation: {
      isError: false,
      message: '',
    },
    loginView: true,
    fetchingZendeskRedirect: false,
  }

  componentWillMount() {
    const { location, logoutUser } = this.props
    const searchQueries = _searchToJson(location.search)
    const { confirmation_token: confirmationToken, flavor } = searchQueries

    // In the short term, we will clear the user's auth data by default.
    // There are two exceptions, which requires auto-login mechanisms
    // for valid SSO:
    // - Zendesk redirects
    // - Admin "View As" feature
    //
    // See comment block below for more information.
    if (this.determineAdminSSO()) {
      this.handleCachedJwt().then(this.handleAlreadyAuthenticated)
    } else if (!this.determineZendeskRedirectWorthy()) {
      logoutUser()
    }

    if (!this.determineZendeskRedirectWorthy() && !this.determineAdminSSO())
      logoutUser()

    if (confirmationToken)
      return this.handleEmailConfirmation(confirmationToken, flavor)

    /**
     * NOTE :
     * This section is commented out as the combination of using both
     * localStorage AND cookies for auth produces some messy side effects:
     * https://irisvr.atlassian.net/browse/SQUAD1-17
     *
     * In the short term, we will simply log out any user who arrives
     * on this page regardless of whether they're already authenticated.
     * This should be fine, and those being redirected can avoid this issue
     * by being sent directly to an authenticated route, such as /prospect.
     * In the long run, we should deprecate the use of localStorage for auth
     * and stick to using cookies exclusively.
     */
    // Handle standard login
    // return this.handleCachedJwt()
    //   .then(() => {
    //     this.handleAttemptedAuthentication()
    //     this.handleAlreadyAuthenticated()
    //   })
  }

  handleEmailConfirmation(token, flavor) {
    const { confirmUserEmail } = this.props

    // Validate UUID
    if (!_isUUID(token)) {
      return this.setState({
        confirmation: {
          isError: true,
          message: 'Error: Confirmation token is in an invalid format.',
        },
      })
    }

    return confirmUserEmail(token).then(() => {
      const { confirmation } = this.props
      if (confirmation.error) {
        return this.setState({
          confirmation: {
            isError: true,
            message: `Error: ${confirmation.error}. Please log in with your credentials, or contact support@irisvr.com if you're facing further issues.`,
          },
        })
      }

      return this.setState({
        confirmation: {
          isError: false,
          message: 'Thank you for confirming your email.',
        },
        loginView: !(flavor && flavor === 'prospect'),
      })
    })
  }

  handleCachedJwt() {
    const { token, fetchRelevantUserAndOrgData } = this.props
    return token ? fetchRelevantUserAndOrgData(token) : Promise.resolve()
  }

  // Attempt login if user was not redirected to this page
  // via authenticated route. If login is successful, push
  // user to intended route (if any).
  handleAttemptedAuthentication() {
    const { location } = this.props
    const attemptedLogin = location.state && location.state.attemptedLogin
    const searchQueries = _searchToJson(location.search)
    const redirect = searchQueries.redirect
    redirect && this.setState({ redirectQuery: redirect })
    if (!attemptedLogin && redirect) {
      _findSavedAuthSession().then(() =>
        this.setState({ redirectToReferrer: redirect })
      )
    }
  }

  handleAlreadyAuthenticated() {
    console.log('this.props =', this.props)
    const { userAccessType } = this.props
    const { redirectQuery } = this.state
    if (userAccessType) {
      this.setState({ redirectToReferrer: redirectQuery })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { orgs, location } = nextProps
    const { redirectQuery } = this.state
    const determinedAccess =
      !this.props.userAccessType && nextProps.userAccessType

    this.determineZendeskRedirectWorthy()
    const { redirect = redirectQuery } = _searchToJson(location.search)

    // Handle no-org personal account OR single org auto-selection
    if (orgs.length <= 1) {
      if (determinedAccess) this.setState({ redirectToReferrer: redirect })
      // Handle multi-org selection selection
    } else if (orgs.length > 1) {
      if (_isUrl(redirect))
        return this.setState({ redirectToReferrer: redirect })
      const optionalRedirect = redirect ? `?redirect=${redirect}` : ''
      this.setState({ redirectToReferrer: `orgs${optionalRedirect}` })
    }
  }

  determineAdminSSO = () => {
    const { location } = this.props
    const { admin_sso: adminSSO } = _searchToJson(location.search)
    return !!adminSSO
  }

  determineZendeskRedirectWorthy = () => {
    const { location } = this.props
    const { action, flavor, return_to: returnTo } = _searchToJson(
      location.search
    )
    const redirectParam = 'iris-zendesk-sso'
    if (
      (action && action === redirectParam) ||
      (flavor && flavor === redirectParam)
    ) {
      this.handleZendeskRedirect(returnTo)
      return true
    }
    return false
  }

  handleZendeskRedirect = returnTo => {
    const { fetchingZendeskRedirect } = this.state
    const { user, getZendeskSSOUrl } = this.props
    const { username, full_name: fullName } = user
    const userDataExists = username && fullName
    if (!fetchingZendeskRedirect && userDataExists) {
      this.setState({ fetchingZendeskRedirect: true })
      return getZendeskSSOUrl({
        email: username,
        name: fullName,
        return_to: returnTo,
      }).then(r => {
        const { redirect } = r.payload
        if (redirect) window.location = redirect
      })
    }
  }

  loginUser = data => {
    const { login, fetchRelevantUserAndOrgData } = this.props
    return login(data)
      .then(validateOnServer)
      .then(() => {
        const { token } = this.props
        return fetchRelevantUserAndOrgData(token)
      })
  }

  displayForgotPasswordModal = () =>
    this.props.showModal('sendPasswordResetEmail')

  displayLoginView = () =>
    this.setState({
      loginView: true,
      confirmation: {
        isError: false,
        message: '',
      },
    })

  render() {
    const { redirectToReferrer, confirmation, loginView } = this.state
    const { location } = this.props
    if (redirectToReferrer) {
      if (_isUrl(redirectToReferrer))
        return (window.location = redirectToReferrer)
      else return <Redirect to={redirectToReferrer} />
    }

    const messageClass = (confirmation.isError && 'error') || ''

    return (
      <div className="login-page">
        {confirmation.message && (
          <div className="confirmation-message-container">
            <p className={messageClass}>{confirmation.message}</p>
          </div>
        )}

        <div className="content-container iris-outline">
          {loginView ? (
            <LoginForm
              loginUser={this.loginUser}
              displayForgotPasswordModal={this.displayForgotPasswordModal}
              search={location.search}
            />
          ) : (
            <DownloadProspectView displayLoginForm={this.displayLoginView} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  userAccessType: state.auth.userAccessType,
  confirmation: state.auth.confirmation,
  user: state.user.data,
  orgs: state.orgs.orgs,
})

export default connect(mapStateToProps, {
  login,
  logoutUser,
  showModal,
  fetchRelevantUserAndOrgData,
  confirmUserEmail,
  getZendeskSSOUrl,
})(Login)
