import React from 'react'
import PropTypes from 'prop-types'
import './IrisSuccessMessage.css'

const IrisSuccessMessage = ({ text }) => (
  <div className="iris-success-message-container">
    <i className="material-icons success-icon">check_circle</i>
    {text ? <p className="success-message">{text}</p> : null}
  </div>
)

IrisSuccessMessage.propTypes = {
  text: PropTypes.string,
}

export default IrisSuccessMessage
