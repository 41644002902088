import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getPersonalUserData,
  updatePersonalUserData,
  showModal,
} from '../../../../actions'
import SettingsFormContainer from './SettingsFormContainer'
import IrisTitleContainer from '../../../Elements/IrisTitleContainer'
import './PersonalSettings.css'

class PersonalSettingsPage extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    userData: PropTypes.shape({}).isRequired,
    actions: PropTypes.shape({
      getPersonalUserData: PropTypes.func.isRequired,
      updatePersonalUserData: PropTypes.func.isRequired,
      showModal: PropTypes.func.isRequired,
    }).isRequired,
  }

  // Always check for updates
  componentWillMount() {
    const { token, actions } = this.props
    actions.getPersonalUserData(token)
  }

  updateUserData = data => {
    const { token, actions } = this.props
    return actions.updatePersonalUserData(token, data)
  }

  displayResetPasswordModal = () =>
    this.props.actions.showModal('resetPassword')

  render() {
    const { userData } = this.props
    return (
      <div className="settings-page app-inner-content">
        <div className="settings-view-container">
          <IrisTitleContainer title="Settings" />
          {userData._id && (
            <SettingsFormContainer
              userData={userData}
              updateUser={this.updateUserData}
              displayResetPasswordModal={this.displayResetPasswordModal}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  userData: state.user.data,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPersonalUserData,
      updatePersonalUserData,
      showModal,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalSettingsPage)
