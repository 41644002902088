import React from 'react'

export const ErrorList = ({ errors = [], title = '' }) =>
  errors.length > 0 && (
    <div>
      <h1>{title}</h1>
      <pre>
        <ol>
          {errors.map(entry => (
            <li>{entry}</li>
          ))}
        </ol>
      </pre>
    </div>
  )

export const ErrorDisplay = ({
  error = { graphQLErrors: [], networkErrors: [] },
}) => {
  const errorStyle = {
    border: '4px solid red',
    color: 'red',
    background: '#FFEEEE',
    padding: 10,
    fontSize: '8pt',
    textAlign: 'left',
  }
  const isProduction = false

  if (isProduction === true) {
    return <div style={errorStyle}>An Error has occured.</div>
  }

  return (
    <div style={errorStyle}>
      {error.message && <ErrorList errors={[error.message]} title={'Error'} />}
      {error.networkErrors && (
        <ErrorList errors={error.networkErrors} title={'Network Errors'} />
      )}
      {error.graphQLErrors && (
        <ErrorList errors={error.graphQLErrors} title={'GraphQL Errors'} />
      )}
    </div>
  )
}
