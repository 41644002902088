import prospectImg from '../assets/prospect_trial.png'
import scopeImg from '../assets/scope_trial.png'

export default {
  prospect: {
    trialImage: prospectImg,
    altText: 'Prospect',
    trialType: 'Prospect Pro Plus',
    ctaText: 'Upgrade',
  },
  scope: {
    trialImage: scopeImg,
    altText: 'Scope',
    trialType: 'Scope Pro',
    ctaText: 'Upgrade',
  },
}
