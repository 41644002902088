import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  NameFormFields,
  BillingAddressFormFields,
} from '../../../Elements/IrisForms'
import IrisButton from '../../../Elements/IrisButton'
import { updateBillingAddressBySubscriptionId } from '../../../../actions'
import { validateOnClient, validateOnServer } from './validate'
import './BillingAddressForm.css'

class BillingAddressForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    onSuccessfulUpdate: PropTypes.func.isRequired,
    updateBillingAddressBySubscriptionId: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  state = { error: '' }

  updateAddress = data => {
    this.setState({ error: '' })

    const addressData = {
      first_name: data.firstName,
      last_name: data.lastName,
      line1: data.addressLine1,
      line2: data.addressLine2,
      city: data.city,
      state: data.state,
      zip: data.zipcode,
      country: data.country,
    }

    return this.updateBillingAddressOnServer(addressData)
  }

  updateBillingAddressOnServer = address => {
    const {
      dirty,
      token,
      orgId,
      subscriptionId,
      updateBillingAddressBySubscriptionId,
      onSuccessfulUpdate,
    } = this.props

    if (dirty) {
      return updateBillingAddressBySubscriptionId(
        token,
        orgId,
        subscriptionId,
        address
      )
        .then(validateOnServer)
        .then(res => onSuccessfulUpdate())
        .catch(this.handleErrorMessage)
    }
    return onSuccessfulUpdate()
  }

  handleErrorMessage = error =>
    this.setState({ error: `${error} Please try again.` })

  render() {
    const { handleSubmit, submitting } = this.props
    const { error } = this.state
    const submittingClass = submitting ? 'form-submitting' : ''
    return (
      <form
        className={`billing-address-form ${submittingClass}`}
        onSubmit={handleSubmit(this.updateAddress)}
      >
        <NameFormFields />
        <BillingAddressFormFields />
        {error && (
          <div className="error-container">
            <p className="error">{error}</p>
          </div>
        )}
        <IrisButton
          buttonText="Save Edits"
          onClick={handleSubmit(this.updateAddress)}
        />
      </form>
    )
  }
}

const BillingAddressReduxForm = reduxForm({
  form: 'billingAddressForm',
  validate: validateOnClient,
})(BillingAddressForm)

const mapStateToProps = (state, ownProps) => {
  const { address } = ownProps
  return {
    token: state.auth.token,
    orgId: state.orgs.selectedOrg._id,
    initialValues: { ...address },
  }
}

export default connect(mapStateToProps, {
  updateBillingAddressBySubscriptionId,
})(BillingAddressReduxForm)
