import {
  verifyToken,
  getPersonalUserSubscriptionStatus,
  getUserSubscriptionStatusWithinOrg,
  getUserLicensesWithinOrg,
} from '../actions'
import { getStore } from './index'

// Grab persisted auth information in redux store
// and verifies token if necessary
export const _findSavedAuthSession = () => {
  const store = getStore()
  const persistedAuth = store.getState().auth
  const token = persistedAuth.token
  const userId = persistedAuth.user._id
  return store.dispatch(verifyToken(token, userId)).then(r => {
    if (r.type === 'VERIFY_TOKEN_FAILURE') throw new Error(r.error)
    return r
  })
}

// Fetch the latest access status for the user
export const _syncUserAccessStatus = () =>
  new Promise((resolve, reject) => {
    const store = getStore()
    const state = store.getState()
    const persistedAuth = state.auth
    const token = persistedAuth.token
    const userId = persistedAuth.user._id
    const selectedOrg = state.orgs.selectedOrg
    const orgId = selectedOrg._id
    if (!orgId) {
      return resolve(store.dispatch(getPersonalUserSubscriptionStatus(token)))
    } else {
      return store
        .dispatch(getUserSubscriptionStatusWithinOrg(token, orgId, userId))
        .then(() =>
          store.dispatch(getUserLicensesWithinOrg(token, orgId, userId))
        )
        .then(() => resolve())
    }
  })

// Grab user role from redux store
export const __extractUserRole = () => getStore().getState().auth.userAccessType

export const _verifyUserRole = requiredRole =>
  new Promise((resolve, reject) => {
    // Convert string role assigned in /reducers/auth.js
    // to corresponding integer value, and ensure it is
    // less than or equal to the requiredRole
    const roleMap = {
      teamOwner: 1,
      teamAdmin: 2,
      teamMember: 5,
    }
    roleMap[__extractUserRole()] <= requiredRole
      ? resolve()
      : reject(new Error('Insufficient Permissions'))
  })
