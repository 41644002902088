import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import AdminMode from '../Globals/AdminMode'
import Navbar from '../Globals/Navbar'
import Sidebar from '../Globals/Sidebar'
import Notification from '../Globals/Notification'
import Modals from '../Modals'
import PrivateRoute from './ComponentUtil/auth/PrivateRoute'
import Login from './Auth/Login'
import SelectOrg from './Auth/SelectOrg'
import ResetPassword from './Auth/ResetPassword'
import Signup from './Auth/Signup'
import Onboard from './Auth/Onboard'
import ProductView from './ProductView'
import Licenses from './Licenses'
import Team from './Team'
import Billing from './Billing'
import CompanySettings from './Settings/Company'
import PersonalSettings from './Settings/Personal'
import './Routes.css'

import { DebugFeaturesToggleList } from '../../modules/integrations'

// import { Integrations, Callback } from './Integrations'
// import IntegrationsPage from './BIM360'
// import { Cloud } from './Cloud'

import { Integrations, Callback } from '../../modules/integrations'

const authedRoutes = /^(?!.*(login|signup|join|orgs|reset-password)).*$/

const Routes = () => (
  <Router>
    <div className="routes">
      <AdminMode />
      <Navbar />
      <Route exact path="/" component={() => <Redirect to="/login" />} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/join" component={Onboard} />
      <Route path="/orgs" component={SelectOrg} />
      <Route path="/reset-password" component={ResetPassword} />
      <div className="main-content-container">
        <Route component={resetMainContentScroll} />
        <Route path={authedRoutes} component={Sidebar} />
        <Route path={authedRoutes} component={Notification} />
        <PrivateRoute path="/prospect" component={ProductView} />

        <PrivateRoute path="/integrations" component={Integrations} />
        <PrivateRoute
          path="/debug"
          component={() => <DebugFeaturesToggleList />}
        />
        <PrivateRoute path="/callback" component={Callback} />
        {/* <PrivateRoute path='/library' component={Cloud} /> */}

        {/* <PrivateRoute path='/config' component={Integrations} /> */}
        {/* <PrivateRoute path='/io' component={IntegrationsPage} /> */}

        <PrivateRoute path="/scope" component={ProductView} />
        <PrivateRoute path="/licenses" component={Licenses} />
        <PrivateRoute path="/team" component={Team} requiredRole={2} />
        <PrivateRoute path="/billing" component={Billing} requiredRole={1} />
        <PrivateRoute
          path="/settings/company"
          component={CompanySettings}
          requiredRole={2}
        />
        <PrivateRoute exact path="/settings" component={PersonalSettings} />
      </div>
      <Modals />
    </div>
  </Router>
)

// Reset scroll position when transitioning in case view is in overflow state
function resetMainContentScroll() {
  const mcc = document.getElementsByClassName('main-content-container')[0]
  mcc && mcc.scrollTo && mcc.scrollTo(0, 0)
  return null
}

export default Routes
