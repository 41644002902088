import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Select } from 'redux-form-material-ui'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'

const IrisDropdown = ({
  data = [],
  className,
  fieldName,
  label,
  onChange = () => {},
}) => (
  <FormControl className={`form-input-field ${className}`}>
    {label ? (
      <InputLabel htmlFor={`${fieldName}-dropdown`}>{label}</InputLabel>
    ) : (
      ''
    )}
    <Field
      id={`${fieldName}-dropdown`}
      name={fieldName}
      onChange={onChange}
      component={Select}
      inputProps={{
        id: `${fieldName}-dropdown`,
      }}
    >
      {data.map(d => (
        <MenuItem value={d.value || d} key={d.value || d}>
          {d.label || d}
        </MenuItem>
      ))}
    </Field>
  </FormControl>
)

IrisDropdown.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default IrisDropdown
