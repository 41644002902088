import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IrisTextField, IrisDropdown } from '../../IrisFormFields'
import { countriesWithISOCode, unitedStatesList } from '../../../../util/data'

class BillingAddressFormFields extends Component {
  static propTypes = {
    isMailing: PropTypes.boolean,
  }

  state = {
    isUSSelected: false,
  }

  onCountrySelect = (_, country) =>
    this.setState({ isUSSelected: country === 'US' })

  render() {
    const { isUSSelected } = this.state
    const { isMailing } = this.props
    const prependedAddressLabel = isMailing ? 'Mailing ' : ''

    return (
      <div className="form-fields-container billing-address-fields">
        <p className="form-fields-title">Billing address</p>
        <div className="form-fields">
          <IrisTextField
            name="addressLine1"
            label={`${prependedAddressLabel}Address Line 1`}
            fullWidth
          />
        </div>
        <div className="form-fields">
          <IrisTextField
            name="addressLine2"
            label={`${prependedAddressLabel}Address Line 2`}
            fullWidth
          />
        </div>
        <div className="form-fields">
          <IrisDropdown
            className="field-country"
            data={countriesWithISOCode}
            fieldName="country"
            label="Country"
            onChange={this.onCountrySelect}
          />
          {isUSSelected ? (
            <IrisDropdown
              className="field-state"
              data={unitedStatesList}
              fieldName="state"
              label="State / Province / Region"
            />
          ) : (
            <IrisTextField className="field-state" name="state" label="State" />
          )}
        </div>
        <div className="form-fields">
          <IrisTextField className="field-city" name="city" label="City" />
          <IrisTextField
            className="field-zipcode"
            name="zipcode"
            label="Zip Code"
          />
        </div>
      </div>
    )
  }
}

export default BillingAddressFormFields
