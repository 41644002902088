export const _isUrl = str => {
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
  const regex = new RegExp(expression)
  const decodedStr = decodeURIComponent(str)
  return decodedStr.match(regex) || decodedStr.includes('localhost')
}

export const _isUUID = id =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    id
  )
