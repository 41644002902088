import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BillingAddressForm from './BillingAddressForm'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './EditBillingAddressModal.css'

class EditBillingAddressModal extends Component {
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
    address: PropTypes.shape({}),
  }

  state = { saveSuccess: false }

  confirmSaveSuccess = () => this.setState({ saveSuccess: true })

  render() {
    const { saveSuccess } = this.state
    const { subscriptionId, address } = this.props

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Edit Primary Address</p>
        </div>
        <div className="modal-body-container">
          <BillingAddressForm
            subscriptionId={subscriptionId}
            address={address}
            onSuccessfulUpdate={this.confirmSaveSuccess}
          />
        </div>
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage text="Your billing address was successfully updated" />
    )

    return (
      <div ref={m => (this.modal = m)} className="edit-billing-address-modal">
        <IrisTransition
          didTransition={saveSuccess}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

export default EditBillingAddressModal
