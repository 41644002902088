import React, { Component } from 'react'
import ResetPasswordForm from './ResetPasswordForm'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './ResetPasswordModal.css'

class ResetPasswordModal extends Component {
  state = { resetPasswordSuccess: false }

  confirmPasswordReset = () => this.setState({ resetPasswordSuccess: true })

  render() {
    const { resetPasswordSuccess } = this.state

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Reset Your Password</p>
        </div>
        <div className="modal-body-container">
          <ResetPasswordForm
            onPasswordResetConfirm={this.confirmPasswordReset}
          />
        </div>
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage text="Your password has been successfully reset" />
    )

    return (
      <div className="reset-password-modal">
        <IrisTransition
          didTransition={resetPasswordSuccess}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

export default ResetPasswordModal
