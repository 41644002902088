import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Body from './Body'
import './SubscriptionCard.css'

const SubscriptionCard = ({ subscription }) => (
  <div className="subscription-card-container">
    <Header {...subscription} />
    <Body {...subscription} />
  </div>
)

SubscriptionCard.propTypes = {
  subscription: PropTypes.shape({}).isRequired,
}

export default SubscriptionCard
