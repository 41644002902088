import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}
  if (!values.username) errors.username = 'Required'
  if (!values.password) errors.password = 'Required'
  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (
      response.payload &&
      response.payload.error &&
      response.payload.error.field
    ) {
      throw new SubmissionError({
        [response.payload.error.field]: response.payload.error.statusText,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
