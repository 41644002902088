import React from 'react'
import PropTypes from 'prop-types'
import Banner from '../Banner'
import { getFrontendRedirectBaseUrl } from '../../../../util'
import './EULA.css'

const EULA = ({ dismiss }) => (
  <Banner dismiss={dismiss}>
    <p>
      We've updated our{' '}
      <a href={`${getFrontendRedirectBaseUrl()}/terms`} target="_blank">
        Terms and Conditions
      </a>
    </p>
  </Banner>
)

EULA.propTypes = {
  dismiss: PropTypes.func.isRequired,
}

export default EULA
