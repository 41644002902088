import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import { IrisTextField } from '../../../../Elements/IrisFormFields'
import IrisButton from '../../../../Elements/IrisButton'
import { validateOnClient } from './validate'
import './LoginForm.css'

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    loginUser: PropTypes.func.isRequired,
    displayForgotPasswordModal: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
  }

  componentWillMount() {
    // Clear form to avoid any confusion
    this.props.reset()
  }

  render() {
    const {
      handleSubmit,
      submitting,
      loginUser,
      displayForgotPasswordModal,
      search,
    } = this.props

    const submittingClass = submitting ? 'form-submitting' : ''

    return (
      <div className="login-form-container">
        <form
          className={`login-form iris-form ${submittingClass}`}
          onSubmit={handleSubmit(loginUser)}
        >
          <div className="form-title-container">
            <p className="form-title">Login to your IrisVR account</p>
          </div>
          <div className="form-fields-container">
            <div className="form-fields">
              <IrisTextField name="username" label="Your Email" autoFocus />
              <IrisTextField name="password" label="Password" type="password" />
            </div>
          </div>
          <IrisButton
            className="login-button"
            buttonText="Login"
            onClick={handleSubmit(loginUser)}
          />
          <div className="forgot-password-container">
            <a
              className="forgot-password-cta"
              onClick={displayForgotPasswordModal}
            >
              Forgot your password?
            </a>
          </div>
          <div className="signup-container">
            <p className="signup-text">
              Don't have an account?{' '}
              <Link className="signup-text-cta" to={`/signup${search}`}>
                Sign up
              </Link>
            </p>
          </div>
        </form>
      </div>
    )
  }
}

const LoginReduxForm = reduxForm({
  form: 'loginForm',
  validate: validateOnClient,
})(LoginForm)

export default LoginReduxForm
