import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import IrisButton from '../../../Elements/IrisButton'

class GenericModel extends Component {
  static propTypes = {
    actions: PropTypes.shape({}).isRequired,
  }

  resetPassword = data => {
    const { token, username, actions, onPasswordResetConfirm } = this.props
    return actions
      .resetPasswordWithVerification(
        token,
        username,
        data.currentPassword,
        data.newPassword
      )
      .then(validateOnServer)
      .then(onPasswordResetConfirm)
  }

  render() {
    const { closeModal } = this.props
    return (
      <div>
        <h2>BIM&nbsp;360® account successfully linked!</h2>

        <div
          style={{
            padding: 20,
          }}
        >
          You can now import your BIM&nbsp;360® projects into Prospect. Your
          projects will be available in BIM&nbsp;360® Projects tab in Prospect
          after they are succesfully imported.
        </div>

        <IrisButton buttonText="Get Started" onClick={closeModal} />
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeModal: () => {
        alert('asdasdasd')
      },
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(GenericModel)
