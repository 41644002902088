import {
  _formatBillingStatus,
  _getHumanReadableDate,
  _getHumanReadablePlanName,
  _isTrialPlan,
} from '../../../../../util'

export const _extractRelevantMetadata = (productType, licenses) => {
  const relevantLicenses = _extractRelevantLicenses(productType, licenses)
  const sortedLicenses = _sortByCurrentTermEndAndStatus(relevantLicenses)
  const formattedLicenses = _formatLicenseMetadata(productType, sortedLicenses)
  return formattedLicenses
}

function _extractRelevantLicenses(productType, licenses) {
  return licenses.filter(l => {
    // Hide workstations from being displayed in Scope tab
    if (productType === 'scope' && l.license_type === 'workstation')
      return false
    return l.product_type.includes(productType)
  })
}

function _sortByCurrentTermEndAndStatus(licenses) {
  // Licenses that are active and will expire sooner
  // should show up first
  const activeSorted = licenses
    .filter(l => l.status !== 'cancelled')
    .sort((a, b) => a.current_term_end - b.current_term_end)

  // Licenses that are expired and expired most recently
  // should be displayed underneath
  const expiredSorted = licenses
    .filter(l => l.status === 'cancelled')
    .sort((a, b) => b.current_term_end - a.current_term_end)

  return [...activeSorted, ...expiredSorted]
}

function _formatLicenseMetadata(productType, licenses) {
  return licenses.map(l => {
    const meta = {
      id: '',
      name: '',
      startDate: 0,
      endDate: 0,
      status: '',
      isActive: false,
      iconType: 'avatar',
    }
    meta.id = l._id || l.id
    meta.startDate = _getHumanReadableDate(l.current_term_start)
    meta.endDate = _getHumanReadableDate(l.current_term_end)
    meta.status = _formatBillingStatus(l.status)
    meta.isActive = l.status !== 'cancelled'

    // Handle name
    if (productType === 'scope') meta.name = 'Scope Pro'
    if (productType === 'prospect')
      meta.name = _getHumanReadablePlanName(l.plan_id)

    // Handle icon type
    if (l.license_type === 'workstation') {
      meta.iconType = 'workstation'
      meta.name = l.workstation_name || 'Workstation'
    }

    // Handle expired trials
    if (_isTrialPlan(l.plan_id)) {
      if (l.status === 'non_renewing') {
        meta.status = 'Expired'
        meta.endDate = ''
      }
    }
    return meta
  })
}
