import {
  START_PERSONAL_USER_TRIAL_REQUEST,
  START_PERSONAL_USER_TRIAL_SUCCESS,
  START_PERSONAL_USER_TRIAL_FAILURE,
  START_ORG_USER_TRIAL_REQUEST,
  START_ORG_USER_TRIAL_SUCCESS,
  START_ORG_USER_TRIAL_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = getBaseUrl()

export const startPersonalUserTrialRequest = () => ({
  type: START_PERSONAL_USER_TRIAL_REQUEST,
})

export const startPersonalUserTrialSuccess = subscriptionData => ({
  type: START_PERSONAL_USER_TRIAL_SUCCESS,
  payload: subscriptionData,
})

export const startPersonalUserTrialFailure = error => ({
  type: START_PERSONAL_USER_TRIAL_FAILURE,
  payload: { error },
})

/**
 * Function to create subscription via billing service
 *
 * @returns {Object} payload - API response to subscription creation
 */
export const startPersonalUserTrial = (token, productType) => dispatch => {
  dispatch(startPersonalUserTrialRequest())

  const url = `${baseurl}/billing/subscriptions/trial`
  const body = { plan_id: `p-${productType}-c-free-45` }
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send(body)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(startPersonalUserTrialSuccess(body)))
    .catch(error => dispatch(startPersonalUserTrialFailure(error)))
}

export const startOrgUserTrialRequest = () => ({
  type: START_ORG_USER_TRIAL_REQUEST,
})

export const startOrgUserTrialSuccess = subscriptionData => ({
  type: START_ORG_USER_TRIAL_SUCCESS,
  payload: subscriptionData,
})

export const startOrgUserTrialFailure = error => ({
  type: START_ORG_USER_TRIAL_FAILURE,
  payload: { error },
})

/**
 * Function to create subscription via billing service
 *
 * @returns {Object} payload - API response to subscription creation
 */
export const startOrgUserTrial = (token, productType, orgId) => dispatch => {
  dispatch(startOrgUserTrialRequest())

  const url = `${baseurl}/billing/orgs/${orgId}/subscriptions/trial`
  const body = { plan_id: `p-${productType}-c-free-45` }
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send(body)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(startOrgUserTrialSuccess(body)))
    .catch(error => dispatch(startOrgUserTrialFailure(error)))
}
