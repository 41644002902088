import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import access from './access'
import modals from './modals'
import notifications from './notifications'
import orgs from './orgs'
import user from './user'
import Integrations from '../modules/integrations/reducer'
import { createReducer } from './util'

export const rootReducer = combineReducers({
  auth,
  access,
  modals,
  notifications,
  orgs,
  user,
  form: formReducer,
  integrations: createReducer(Integrations.INITIAL_STATE, Integrations.reducerMap),
})

export default function(stateParam, action) {
  let state = stateParam
  // Clear state upon logout or token failure
  if (action.type === 'LOGOUT_USER') state = {}
  if (action.type === 'VERIFY_TOKEN_FAILURE') state = {}
  return rootReducer(state, action)
}
