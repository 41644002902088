import React from 'react'
import PropTypes from 'prop-types'
import './LicenseIcon.css'

const LicenseIcon = ({ iconType }) => (
  <div className="license-icon-container">
    <i className="material-icons">
      {iconType === 'avatar' ? 'person' : 'personal_video'}
    </i>
  </div>
)

LicenseIcon.propTypes = {
  iconType: PropTypes.string.isRequired,
}

export default LicenseIcon
