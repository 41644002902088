import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showModal } from '../../../../../../../actions'

class BillingAddress extends Component {
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
    address: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      addressLine1: PropTypes.string.isRequired,
      addressLine2: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
    }),
    showModal: PropTypes.func.isRequired,
  }

  editAddress = () => {
    const { subscriptionId, address, showModal } = this.props
    showModal('editBillingAddressModal', {
      subscriptionId,
      address,
    })
  }

  render() {
    const { address } = this.props
    return (
      <div
        className="
        cell-container
        expanded-component-container
        billing-address-container
      "
      >
        <div className="cell-header-container">
          <p className="cell-header">Primary Address</p>
        </div>
        <div className="cell-content-container">
          {address.addressLine1 ? (
            <div>
              <p>{`${address.firstName} ${address.lastName}`}</p>
              <p>{address.addressLine1}</p>
              <p>{address.addressLine2}</p>
              <p>
                {address.city}, {address.state}
              </p>
              <p>
                {address.country}, {address.zipcode}
              </p>
            </div>
          ) : (
            <p>No address on file</p>
          )}
        </div>
        <div className="cell-cta-container">
          <a className="cell-cta" onClick={this.editAddress}>
            {address.addressLine1 ? 'Edit' : 'Add Address'}
          </a>
        </div>
      </div>
    )
  }
}

export default connect(null, { showModal })(BillingAddress)
