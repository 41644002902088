import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProductView from './ProductView'

class ProductViewContainer extends Component {
  static propTypes = {
    selectedOrg: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    userAccessType: PropTypes.string.isRequired,
    access: PropTypes.shape({}).isRequired,
    licenses: PropTypes.array.isRequired,
  }

  render() {
    const { location, userAccessType, access, licenses } = this.props
    return (
      <div className="product-access-page app-inner-content">
        {access.access && (
          <ProductView
            location={location}
            userAccessType={userAccessType}
            access={access}
            licenses={licenses}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedOrg: state.orgs.selectedOrg,
  userAccessType: state.auth.userAccessType,
  access: state.access.data,
  licenses: state.access.licenses,
})

export default connect(mapStateToProps)(ProductViewContainer)
