import {
  SELECT_ORG,
  GET_ORG_BY_ID_REQUEST,
  GET_ORG_BY_ID_SUCCESS,
  GET_ORG_BY_ID_FAILURE,
  GET_ORGS_FOR_USER_REQUEST,
  GET_ORGS_FOR_USER_SUCCESS,
  GET_ORGS_FOR_USER_FAILURE,
  UPDATE_ORG_REQUEST,
  UPDATE_ORG_SUCCESS,
  UPDATE_ORG_FAILURE,
  UPDATE_ORG_BILLING_DATA_REQUEST,
  UPDATE_ORG_BILLING_DATA_SUCCESS,
  UPDATE_ORG_BILLING_DATA_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/orgs`

export const selectOrg = orgId => dispatch =>
  Promise.resolve(
    dispatch({
      type: SELECT_ORG,
      payload: { orgId },
    })
  )

export const getOrgByIdRequest = () => ({
  type: GET_ORG_BY_ID_REQUEST,
})

export const getOrgByIdSuccess = org => ({
  type: GET_ORG_BY_ID_SUCCESS,
  payload: org,
})

export const getOrgByIdFailure = error => ({
  type: GET_ORG_BY_ID_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getOrgById = (token, orgId) => dispatch => {
  dispatch(getOrgByIdRequest())
  const url = `${baseurl}/${orgId}`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getOrgByIdSuccess(payload)))
    .catch(error => dispatch(getOrgByIdFailure(error)))
}

export const getOrgsForUserRequest = () => ({
  type: GET_ORGS_FOR_USER_REQUEST,
})

export const getOrgsForUserSuccess = orgs => ({
  type: GET_ORGS_FOR_USER_SUCCESS,
  payload: { orgs },
})

export const getOrgsForUserFailure = error => ({
  type: GET_ORGS_FOR_USER_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getOrgsForUser = token => dispatch => {
  dispatch(getOrgsForUserRequest())
  const url = baseurl
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getOrgsForUserSuccess(payload)))
    .catch(error => dispatch(getOrgsForUserFailure(error)))
}

export const updateOrgRequest = () => ({
  type: UPDATE_ORG_REQUEST,
})

export const updateOrgSuccess = org => ({
  type: UPDATE_ORG_SUCCESS,
  payload: org,
})

export const updateOrgFailure = error => ({
  type: UPDATE_ORG_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const updateOrg = (token, orgId, data) => dispatch => {
  dispatch(updateOrgRequest())
  const url = `${baseurl}/${orgId}`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .send(data)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(updateOrgSuccess(payload)))
    .catch(error => dispatch(updateOrgFailure(error)))
}

export const updateOrgBillingDataRequest = () => ({
  type: UPDATE_ORG_BILLING_DATA_REQUEST,
})

export const updateOrgBillingDataSuccess = org => ({
  type: UPDATE_ORG_BILLING_DATA_SUCCESS,
  payload: org,
})

export const updateOrgBillingDataFailure = error => ({
  type: UPDATE_ORG_BILLING_DATA_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const updateOrgBillingData = (token, orgId, data) => dispatch => {
  dispatch(updateOrgBillingDataRequest())
  const url = `${baseurl}/${orgId}/billing-address`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .send(data)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(updateOrgBillingDataSuccess(payload)))
    .catch(error => dispatch(updateOrgBillingDataFailure(error)))
}
