import { SHOW_MODAL, HIDE_MODAL } from '../constants'
import { createReducer } from './util'

const initialState = {
  modalType: null,
  modalProps: {},
}

export default createReducer(initialState, {
  [SHOW_MODAL]: (state, payload) => {
    return {
      modalProps: payload.modalProps || {},
      modalType: payload.modalType,
    }
  },
  [HIDE_MODAL]: (state, payload) => {
    return initialState
  },
})
