import {
  GET_ORG_USERS_AND_WORKSTATIONS_REQUEST,
  GET_ORG_USERS_AND_WORKSTATIONS_SUCCESS,
  GET_ORG_USERS_AND_WORKSTATIONS_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/billing`

// ----- FETCH POPULATED USERS AND WORKSTATIONS WITHIN ORG
export const getOrgUsersAndWorkstationsRequest = () => ({
  type: GET_ORG_USERS_AND_WORKSTATIONS_REQUEST,
})

export const getOrgUsersAndWorkstationsSuccess = payload => ({
  type: GET_ORG_USERS_AND_WORKSTATIONS_SUCCESS,
  payload,
})

export const getOrgUsersAndWorkstationsFailure = error => ({
  type: GET_ORG_USERS_AND_WORKSTATIONS_FAILURE,
  payload: error,
})

export const getOrgUsersAndWorkstations = (token, orgId) => dispatch => {
  dispatch(getOrgUsersAndWorkstationsRequest())
  const url = `${baseurl}/orgs/${orgId}/access`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getOrgUsersAndWorkstationsSuccess(payload)))
    .catch(error => dispatch(getOrgUsersAndWorkstationsFailure(error)))
}
