import {
  GET_PERSONAL_USER_DATA_REQUEST,
  GET_PERSONAL_USER_DATA_SUCCESS,
  GET_PERSONAL_USER_DATA_FAILURE,
  UPDATE_PERSONAL_USER_DATA_REQUEST,
  UPDATE_PERSONAL_USER_DATA_SUCCESS,
  UPDATE_PERSONAL_USER_DATA_FAILURE,
  CONFIRM_USER_EMAIL_REQUEST,
  CONFIRM_USER_EMAIL_SUCCESS,
  CONFIRM_USER_EMAIL_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/users`

export const getPersonalUserDataRequest = () => ({
  type: GET_PERSONAL_USER_DATA_REQUEST,
})

export const getPersonalUserDataSuccess = data => ({
  type: GET_PERSONAL_USER_DATA_SUCCESS,
  payload: { data },
})

export const getPersonalUserDataFailure = error => ({
  type: GET_PERSONAL_USER_DATA_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getPersonalUserData = token => dispatch => {
  dispatch(getPersonalUserDataRequest())
  const url = `${baseurl}/me`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getPersonalUserDataSuccess(payload)))
    .catch(error => dispatch(getPersonalUserDataFailure(error)))
}

export const updatePersonalUserDataRequest = () => ({
  type: UPDATE_PERSONAL_USER_DATA_REQUEST,
})

export const updatePersonalUserDataSuccess = data => ({
  type: UPDATE_PERSONAL_USER_DATA_SUCCESS,
  payload: { data },
})

export const updatePersonalUserDataFailure = error => ({
  type: UPDATE_PERSONAL_USER_DATA_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const updatePersonalUserData = (token, userData) => dispatch => {
  dispatch(updatePersonalUserDataRequest())
  const url = `${baseurl}/me`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send(userData)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(updatePersonalUserDataSuccess(payload)))
    .catch(error => dispatch(updatePersonalUserDataFailure(error)))
}

export const confirmUserEmailRequest = () => ({
  type: CONFIRM_USER_EMAIL_REQUEST,
})

export const confirmUserEmailSuccess = data => ({
  type: CONFIRM_USER_EMAIL_SUCCESS,
  payload: { data },
})

export const confirmUserEmailFailure = error => ({
  type: CONFIRM_USER_EMAIL_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const confirmUserEmail = token => dispatch => {
  dispatch(confirmUserEmailRequest())
  const url = `${baseurl}/email-confirmation/${token}`
  return request
    .get(url)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(confirmUserEmailSuccess(payload)))
    .catch(error => dispatch(confirmUserEmailFailure(error)))
}
