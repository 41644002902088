import { SubmissionError } from 'redux-form'

export const validateOnClient = values => {
  const errors = {}

  const requiredFields = ['password', 'confirmPassword']

  requiredFields.forEach(
    field => (errors[field] = !values[field] ? 'Required' : null)
  )

  if (values.password && values.password.length < 8)
    errors.password = 'Your password must be at least 8 characters long'
  if (values.password !== values.confirmPassword)
    errors.confirmPassword = 'Your password must match'

  return errors
}

export const validateOnServer = response =>
  new Promise(resolve => {
    if (
      response.payload &&
      response.payload.error &&
      response.payload.error.field
    ) {
      throw new SubmissionError({
        [response.payload.error.field]: response.payload.error.statusText,
        _error: 'Error',
      })
    } else {
      resolve()
    }
  })
