import {
  _getHumanReadableRole,
  _getHumanReadablePlanName,
} from '../../../../util'

// Ensure at least one workstation is active
export const _atLeastOneWorkstationIsActive = workstations =>
  workstations.reduce(
    (v, w) => v && w.access_meta.access.plan_access.prospect.has_access,
    true
  )

export const _extractRelevantMetadata = members => {
  const membersSortedAlphabetically = members.sort((a, b) => {
    const [nameA, nameB] = [a, b].map(m => m.user.username)

    return nameA.localeCompare(nameB)
  })

  const extracted = membersSortedAlphabetically.map(_formatMemberData)
  return extracted
}

const createUsernameFromMemberPayload = member => {
  const memberEmail = member.user.username
  if (Boolean(member.user.full_name)) {
    return member.user.full_name.concat(' ').concat(`(${memberEmail})`)
  }

  if (Boolean(member.user.display_name)) {
    return member.user.display_name.concat(' ').concat(`(${memberEmail})`)
  }

  // Just show email if there's no full name or display name
  return memberEmail
}

function _formatMemberData(member) {
  const meta = {
    id: '', // accessId
    userId: '',
    username: '',
    role: 5,
    roleReadable: '',
    accessList: '',
    isPending: false,
    isActive: true,
  }
  meta.id = member.access_meta._id
  meta.userId = member.user._id
  meta.username = createUsernameFromMemberPayload(member)
  // Handle pending user
  if (member.access_meta.status === 'invited') {
    meta.username = `${meta.username} (Pending)`
    meta.isPending = true
  }
  meta.role = member.access_meta.role
  meta.roleReadable = _getHumanReadableRole(member.access_meta.role)
  meta.accessList = _getAccessList(member.access_meta.access.plan_access)
  return meta
}

function _getAccessList(planAccess) {
  if (!planAccess.prospect.plan_id && !planAccess.scope.plan_id) {
    return 'None'
  }
  if (planAccess.prospect.plan_id && !planAccess.scope.plan_id) {
    return _handleAccessList(planAccess, 'prospect')
  }
  if (!planAccess.prospect.plan_id && planAccess.scope.plan_id) {
    return _handleAccessList(planAccess, 'scope')
  }
  if (planAccess.prospect.plan_id && planAccess.scope.plan_id) {
    if (planAccess.prospect.plan_id === planAccess.scope.plan_id) {
      return _handleAccessList(planAccess, 'prospect')
    }
    return `${_getHumanReadablePlanName(
      planAccess.prospect.plan_id
    )} ${_getHumanReadablePlanName(planAccess.scope.plan_id)}`
  }
}

function _handleAccessList(planAccess, subscriptionType) {
  // If subscription is expired, access is null regardless of plan ID
  if (!planAccess[subscriptionType].has_access) return 'None'
  return _getHumanReadablePlanName(planAccess[subscriptionType].plan_id)
}

// Sort by date added to org
function _sortByDateAdded(members) {
  return members.sort(
    (a, b) => a.access_meta.created_at - b.access_meta.created_at
  )
}
