import ScopeLibraryIcon from './assets/ScopeLibrary.png'
import DownloadIcon from './assets/Download.svg'
import SupportIcon from './assets/Help.svg'
import { getFrontendRedirectBaseUrl } from '../../../../util'

export default {
  prospect: {
    download: {
      title: {
        icon: DownloadIcon,
        text: 'Download',
      },
      description: {
        text: 'Get the latest version of Prospect',
      },
      cta: {
        ctaText: 'Download Prospect',
        ctaLink: 'https://prospect-releases.irisvr.com/ProspectSetup.exe',
        newTab: false,
      },
    },
    support: {
      title: {
        icon: SupportIcon,
        text: 'Support',
      },
      description: {
        text: 'Tips, tutorials & detailing on using Prospect',
      },
      cta: {
        ctaText: 'Learn More',
        ctaLink:
          'https://help.irisvr.com/hc/en-us/categories/201836838-Prospect',
        newTab: true,
      },
    },
  },
  scope: {
    library: {
      title: {
        icon: ScopeLibraryIcon,
        text: 'Scope Library',
      },
      description: {
        text: 'Upload, organize and share your panos',
      },
      cta: {
        ctaText: 'Access Scope Library',
        ctaLink: `${getFrontendRedirectBaseUrl()}/library`,
        newTab: true,
      },
    },
    download: {
      title: {
        icon: DownloadIcon,
        text: 'Download',
      },
      description: {
        text:
          'Get the Scope Viewer app on your smartphone, then view your 360 images in VR',
      },
      cta: {
        ctaText: 'Download Scope Viewer',
        ctaLink: `${getFrontendRedirectBaseUrl()}/downloads`,
        newTab: true,
      },
    },
    support: {
      title: {
        icon: SupportIcon,
        text: 'Support',
      },
      description: {
        text: 'Tips, tutorials & detailing on using Scope',
      },
      cta: {
        ctaText: 'Learn More',
        ctaLink: 'https://help.irisvr.com/hc/en-us/categories/201836858-Scope',
        newTab: true,
      },
    },
  },
}
