import {
  VERIFY_PASSWORD_REQUEST,
  VERIFY_PASSWORD_SUCCESS,
  VERIFY_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SEND_PASSWORD_RESET_EMAIL_REQUEST,
  SEND_PASSWORD_RESET_EMAIL_SUCCESS,
  SEND_PASSWORD_RESET_EMAIL_FAILURE,
  RESET_PASSWORD_WITH_TOKEN_REQUEST,
  RESET_PASSWORD_WITH_TOKEN_SUCCESS,
  RESET_PASSWORD_WITH_TOKEN_FAILURE,
} from '../../constants'
import { login } from './login'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}`

export const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST,
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailure = error => ({
  type: RESET_PASSWORD_FAILURE,
  payload: { error },
})

export const resetPassword = (token, password) => dispatch => {
  dispatch(resetPasswordRequest())
  const url = `${baseurl}/users/me`
  const data = { password }
  return request
    .put(url)
    .type('json')
    .set('Authorization', `Bearer ${token}`)
    .send(data)
    .end()
    .then(parseResponse)
    .then(() => dispatch(resetPasswordSuccess()))
    .catch(error =>
      dispatch(
        resetPasswordFailure({
          statusText: error.error_message,
          field: 'confirmNewPassword',
        })
      )
    )
}

export const verifyPasswordRequest = () => ({
  type: VERIFY_PASSWORD_REQUEST,
})

export const verifyPasswordSuccess = () => ({
  type: VERIFY_PASSWORD_SUCCESS,
})

export const verifyPasswordFailure = error => ({
  type: VERIFY_PASSWORD_FAILURE,
  payload: { error },
})

/**
 * Attempt user login with previous password -- if successful, reset password.
 */
export const resetPasswordWithVerification = (
  token,
  username,
  currentPassword,
  newPassword
) => dispatch => {
  dispatch(verifyPasswordRequest())
  const url = `${baseurl}/auth/user/login`
  const data = { username, password: currentPassword }
  return request
    .post(url)
    .type('json')
    .send(data)
    .end()
    .then(parseResponse)
    .then(() => {
      dispatch(verifyPasswordSuccess())
      return dispatch(resetPassword(token, newPassword))
    })
    .catch(error =>
      dispatch(
        verifyPasswordFailure({
          statusText: error.error_message,
          field: 'currentPassword',
        })
      )
    )
}

export const sendPasswordResetEmailRequest = () => ({
  type: SEND_PASSWORD_RESET_EMAIL_REQUEST,
})

export const sendPasswordResetEmailSuccess = () => ({
  type: SEND_PASSWORD_RESET_EMAIL_SUCCESS,
})

export const sendPasswordResetEmailFailure = error => ({
  type: SEND_PASSWORD_RESET_EMAIL_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const sendPasswordResetEmail = username => dispatch => {
  // ${baseurl}/users/reset-password`;
  dispatch(sendPasswordResetEmailRequest())
  const url = `${baseurl}/users/reset-password`
  return request
    .post(url)
    .type('json')
    .send({ username })
    .end()
    .then(parseResponse)
    .then(payload => dispatch(sendPasswordResetEmailSuccess(payload)))
    .catch(error => dispatch(sendPasswordResetEmailFailure(error)))
}

export const resetPasswordWithTokenRequest = () => ({
  type: RESET_PASSWORD_WITH_TOKEN_REQUEST,
})

export const resetPasswordWithTokenSuccess = () => ({
  type: RESET_PASSWORD_WITH_TOKEN_SUCCESS,
})

export const resetPasswordWithTokenFailure = error => ({
  type: RESET_PASSWORD_WITH_TOKEN_FAILURE,
  payload: { error },
})

export const resetPasswordWithToken = (token, password) => dispatch => {
  dispatch(resetPasswordWithTokenRequest())
  const url = `${baseurl}/users/reset-password/${token}`
  const data = { password }
  return request
    .put(url)
    .type('json')
    .send(data)
    .end()
    .then(parseResponse)
    .then(payload => {
      dispatch(resetPasswordWithTokenSuccess())
      return dispatch(
        login({
          username: payload.username,
          password,
        })
      )
    })
    .catch(error =>
      dispatch(
        resetPasswordWithTokenFailure({
          statusText: error.error_message,
          field: 'confirmPassword',
        })
      )
    )
}
