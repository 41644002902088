import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showModal } from '../../../../../../../actions'

class BillingContact extends Component {
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
    primaryContact: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
    }).isRequired,
    showModal: PropTypes.func.isRequired,
  }

  editContact = () => {
    const { subscriptionId, primaryContact, showModal } = this.props
    showModal('editPrimaryContactModal', {
      subscriptionId,
      primaryContact,
    })
  }

  render() {
    const { primaryContact } = this.props
    return (
      <div
        className="
        cell-container
        expanded-component-container
        billing-contact-container
      "
      >
        <div className="cell-header-container">
          <p className="cell-header">Primary Contact</p>
        </div>
        <div className="cell-content-container">
          <p>{primaryContact.fullName}</p>
          <p>{primaryContact.email}</p>
          <p>{primaryContact.phone}</p>
        </div>
        <div className="cell-cta-container">
          <a className="cell-cta" onClick={this.editContact}>
            Edit
          </a>
        </div>
      </div>
    )
  }
}

export default connect(null, { showModal })(BillingContact)
