import React from 'react'
import { IrisTextField } from '../../IrisFormFields'
import { enforceLimitedDigits } from './validate'

const CreditCardFormFields = () => (
  <div className="form-fields-container payment-information-fields">
    <p className="form-fields-title">Payment Information</p>
    <div className="form-fields">
      <IrisTextField
        className="field-card-number"
        name="cardNumber"
        label="Card Number"
        normalize={enforceLimitedDigits(20)}
      />
      <IrisTextField
        className="field-month"
        name="month"
        label="MM"
        normalize={enforceLimitedDigits(2)}
      />
      <IrisTextField
        className="field-year"
        name="year"
        label="YY"
        normalize={enforceLimitedDigits(2)}
      />
      <IrisTextField
        className="field-cvc"
        name="cvc"
        label="CVC"
        normalize={enforceLimitedDigits(4)}
      />
    </div>
  </div>
)

export default CreditCardFormFields
