import React, { Component } from 'react'
import SendPasswordResetEmailForm from './SendPasswordResetEmailForm'
import IrisSuccessMessage from '../../Elements/IrisSuccessMessage'
import IrisTransition from '../../Elements/IrisTransition'
import './SendPasswordResetEmailModal.css'

class SendPasswordResetEmailModal extends Component {
  state = { emailConfirmationSent: false }

  confirmEmailSent = () => this.setState({ emailConfirmationSent: true })

  render() {
    const { emailConfirmationSent } = this.state

    const initialComponent = (
      <div>
        <div className="modal-title-container">
          <p className="modal-title">Reset Your Password</p>
        </div>
        <div className="modal-body-container">
          <SendPasswordResetEmailForm
            onEmailConfirmation={this.confirmEmailSent}
          />
        </div>
      </div>
    )

    const finalComponent = (
      <IrisSuccessMessage text="We're processing your request! Please check your inbox for an e-mail that contains directions for resetting your password." />
    )

    return (
      <div className="send-password-reset-email-modal">
        <IrisTransition
          didTransition={emailConfirmationSent}
          initialComponent={initialComponent}
          finalComponent={finalComponent}
        />
      </div>
    )
  }
}

export default SendPasswordResetEmailModal
