import React from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { actions } from './actions'

const CallbackHandler = (props) => {
    console.log("callback", props)
    const parsed = queryString.parse(props.location.search)
    const code = parsed['code']
    props.setCode({ code })

    return (
        <Redirect to='/integrations' />
    )
}

CallbackHandler.defaultProps = {
    setCode: (code) => {
        alert('default:' + code)
    }
}

export const Callback = connect(
    state => ({
        authCode: state.integrations.code
    }),
    dispatch => ({
        setCode: ({ code, history }) => {
            // history.push('/loading')
            dispatch(actions.setAuthCode({
                code: code,
                history: history,
                dispatch: dispatch,
            }))
            // dispatch(bim360Actions.setEnabled(true))
        },
        setToken: (token) => {
            // dispatch(actions.setAuthToken(token))
        }
    })
)(CallbackHandler)
