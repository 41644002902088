import {
  GET_ORG_LICENSES_REQUEST,
  GET_ORG_LICENSES_SUCCESS,
  GET_ORG_LICENSES_FAILURE,
  REGISTER_LICENSE_REQUEST,
  REGISTER_LICENSE_SUCCESS,
  REGISTER_LICENSE_FAILURE,
  DEREGISTER_LICENSE_REQUEST,
  DEREGISTER_LICENSE_SUCCESS,
  DEREGISTER_LICENSE_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/billing`

export const getOrgLicensesRequest = () => ({
  type: GET_ORG_LICENSES_REQUEST,
})

export const getOrgLicensesSuccess = licenses => ({
  type: GET_ORG_LICENSES_SUCCESS,
  payload: licenses || [],
})

export const getOrgLicensesFailure = error => ({
  type: GET_ORG_LICENSES_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const getOrgLicenses = (token, orgId) => dispatch => {
  dispatch(getOrgLicensesRequest())
  const url = `${baseurl}/orgs/${orgId}/licenses`
  return request
    .get(url)
    .set('Authorization', `Bearer ${token}`)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getOrgLicensesSuccess(payload)))
    .catch(error => dispatch(getOrgLicensesFailure(error)))
}

export const registerLicenseRequest = () => ({
  type: REGISTER_LICENSE_REQUEST,
})

export const registerLicenseSuccess = license => ({
  type: REGISTER_LICENSE_SUCCESS,
  payload: license,
})

export const registerLicenseFailure = error => ({
  type: REGISTER_LICENSE_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const registerLicense = (
  token,
  orgId,
  licenseId,
  accessId
) => dispatch => {
  dispatch(registerLicenseRequest())
  const url = `${baseurl}/orgs/${orgId}/licenses/${licenseId}/register`
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send({ org_user_access_id: accessId })
    .end()
    .then(parseResponse)
    .then(payload => dispatch(registerLicenseSuccess(payload)))
    .catch(error => dispatch(registerLicenseFailure(error)))
}

export const deregisterLicenseRequest = () => ({
  type: DEREGISTER_LICENSE_REQUEST,
})

export const deregisterLicenseSuccess = license => ({
  type: DEREGISTER_LICENSE_SUCCESS,
  payload: license,
})

export const deregisterLicenseFailure = error => ({
  type: DEREGISTER_LICENSE_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve tax estimate from billing service
 * @returns {Object} payload - API response to tax data retrieval
 */
export const deregisterLicense = (
  token,
  orgId,
  licenseId,
  accessId
) => dispatch => {
  dispatch(deregisterLicenseRequest())
  const url = `${baseurl}/orgs/${orgId}/licenses/${licenseId}/deregister`
  return request
    .post(url)
    .set('Authorization', `Bearer ${token}`)
    .type('json')
    .send({ org_user_access_id: accessId })
    .end()
    .then(parseResponse)
    .then(payload => dispatch(deregisterLicenseSuccess(payload)))
    .catch(error => dispatch(deregisterLicenseFailure(error)))
}
