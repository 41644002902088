import {
  _formatBillingStatus,
  _getHumanReadableDate,
  _getHumanReadablePlanName,
  _isTrialPlan,
} from '../../../../util'

export const _extractRelevantMetadata = (licensesObj, orgEntitiesObj) => {
  const sortedLicensesObj = _sortByLicenseType(licensesObj)
  const populatedLicensesObj = _populateLicensesFromCorrespondingEntityMetadata(
    sortedLicensesObj,
    orgEntitiesObj
  )
  return populatedLicensesObj
}

function _sortByLicenseType(licenses) {
  const sorted = {
    users: [],
    workstations: [],
  }
  licenses.forEach(l => {
    const key = `${l.license_type}s`
    sorted[key].push(l)
  })
  return sorted
}

function _populateLicensesFromCorrespondingEntityMetadata(
  sortedLicensesObj,
  orgEntitiesObj
) {
  const populatedLicensesObj = {}
  Object.keys(sortedLicensesObj).forEach(type => {
    if (sortedLicensesObj[type].length && orgEntitiesObj[type].length) {
      populatedLicensesObj[type] = sortedLicensesObj[type]
        .filter(license => {
          // Filter out unassigned trials
          const isAnUnassignedTrial =
            !license.iris_access_document_id && _isTrialPlan(license.plan_id)
          return !isAnUnassignedTrial
        })
        .map(license => {
          const corrEntity = orgEntitiesObj[type].find(
            e => e.access_meta._id === license.iris_access_document_id
          )
          const formattedLicense = _formatLicenseMetadata(
            type,
            corrEntity,
            license
          )
          return formattedLicense
        })
    }
  })
  return populatedLicensesObj
}

function _formatLicenseMetadata(entityType, entity, license) {
  const meta = {
    id: '',
    name: '',
    userTitle: '',
    startDate: 0,
    endDate: 0,
    status: '',
    isActive: false,
    iconType: 'avatar',
    entityId: '',
    workstationKey: '',
    workstationIsRegistered: false,
  }

  meta.id = license._id
  meta.startDate = _getHumanReadableDate(license.current_term_start)
  meta.endDate = _getHumanReadableDate(license.current_term_end)
  meta.status = _formatBillingStatus(license.status)
  meta.isActive = license.status !== 'cancelled'

  // BUGFIX HACK: If a license is assigned to a missing entity things break
  if (entity === undefined) {
    // no entity found
    // this means the license is assigned to a missing or deleted entity
    meta.entityId = ''
    if (entityType === 'workstations') {
      meta.name = 'Deleted Workstation'
      meta.userTitle = 'Team'
      meta.iconType = 'workstation'
      meta.workstationKey = ''
      meta.workstationIsRegistered = true
    } else {
      meta.name = _getHumanReadablePlanName(license.plan_id)
      meta.userTitle = '__RENDER_ASSIGN_BUTTON__'
    }
  } else if (entityType === 'workstations') {
    // Handle label, title for workstation
    meta.entityId = entity.workstation._id
    meta.name = entity.workstation.name || 'Untitled Workstation'
    meta.userTitle = 'Team'
    meta.iconType = 'workstation'
    meta.workstationKey = entity.workstation.access_key
    meta.workstationIsRegistered = !!entity.workstation.mac_address
  } else {
    // Handle label, title for user
    meta.entityId = entity ? entity.user._id : ''
    meta.name = _getHumanReadablePlanName(license.plan_id)
    meta.userTitle = entity
      ? entity.user.full_name || entity.user.username
      : '__RENDER_ASSIGN_BUTTON__'
  }
  // Handle expired trials
  if (_isTrialPlan(license.plan_id)) {
    if (license.status === 'non_renewing') {
      meta.status = 'Expired'
      meta.endDate = ''
    }
  }

  return meta
}
