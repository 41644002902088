import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showModal, hideModal } from '../../actions/modals'
import ReactModal from 'react-modal'
import AddUserToOrg from './AddUserToOrg'
import AssignLicenseModal from './AssignLicenseModal'
import EditBillingAddressModal from './EditBillingAddressModal'
import EditCreditCardModal from './EditCreditCardModal'
import EditPrimaryContactModal from './EditPrimaryContactModal'
import RemoveUserFromOrgModal from './RemoveUserFromOrgModal'
import ResendEmailInviteModal from './ResendEmailInviteModal'
import ResetPasswordModal from './ResetPasswordModal'
import SendPasswordResetEmailModal from './SendPasswordResetEmailModal'
import ReleaseWorkstationKeyModal from './Workstations/ReleaseWorkstationKeyModal'
import CopyWorkstationKeyModal from './Workstations/CopyWorkstationKeyModal'
import RenameWorkstationModal from './Workstations/RenameWorkstationModal'
import GenericModal from './GenericModal/GenericModal'
import ConfirmationModal from './ConfirmationModal/components'

import { DebugFeaturesToggleListRaw } from '../../modules/integrations'

const MODAL_TYPES = {
  addUserToOrg: AddUserToOrg,
  assignLicense: AssignLicenseModal,
  editBillingAddressModal: EditBillingAddressModal,
  editCreditCardModal: EditCreditCardModal,
  editPrimaryContactModal: EditPrimaryContactModal,
  removeUserFromOrg: RemoveUserFromOrgModal,
  resendEmailInvite: ResendEmailInviteModal,
  resetPassword: ResetPasswordModal,
  sendPasswordResetEmail: SendPasswordResetEmailModal,
  releaseWorkstationKey: ReleaseWorkstationKeyModal,
  copyWorkstationKey: CopyWorkstationKeyModal,
  renameWorkstation: RenameWorkstationModal,
  genericModal: GenericModal,
  confirmationModal: ConfirmationModal,
  debugModal: DebugFeaturesToggleListRaw,
}

class ModalContainer extends Component {
  static propTypes = {
    modalType: PropTypes.string,
    modalProps: PropTypes.shape({}),
    actions: PropTypes.shape({
      showModal: PropTypes.func.isRequired,
      hideModal: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = {
    isOpen: false,
    transitionDurationMs: 400,
  }

  componentWillMount() {
    this.handleModalOpen(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleModalOpen(nextProps)
  }

  // Detects whether modal should be open based on redux state
  // and local component state
  handleModalOpen = props =>
    props.modalType && !this.state.isOpen
      ? this.setState({ isOpen: true })
      : undefined

  closeModal = () => {
    this.setState({ isOpen: false })
    // Delay hiding by animation duration set in Modals.scss
    // on the class `.ReactModalPortal .ReactModal__Overlay`
    // as well as `closeTimeoutMS` prop on ReactModal
    setTimeout(this.props.actions.hideModal, this.state.transitionDurationMs)
  }

  render() {
    const { isOpen, transitionDurationMs } = this.state
    const { modalType, modalProps } = this.props
    const ModalComponent = MODAL_TYPES[modalType]
    return (
      <ReactModal
        className="iris-modal"
        overlayClassName="iris-modal-overlay"
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        closeTimeoutMS={transitionDurationMs}
        ariaHideApp={false}
      >
        <i className="material-icons close-icon" onClick={this.closeModal}>
          close
        </i>
        {ModalComponent && (
          <ModalComponent closeModal={this.closeModal} {...modalProps} />
        )}
      </ReactModal>
    )
  }
}

const mapStateToProps = state => ({
  ...state.modals,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showModal,
      hideModal,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
