import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IrisPDLButton } from '../../../Elements/IrisPDLButton'

class GenericModel extends Component {
  static propTypes = {
    actions: PropTypes.shape({}).isRequired,
  }

  resetPassword = data => {
    const { token, username, actions, onPasswordResetConfirm } = this.props
    return actions
      .resetPasswordWithVerification(
        token,
        username,
        data.currentPassword,
        data.newPassword
      )
      .then(validateOnServer)
      .then(onPasswordResetConfirm)
  }

  render() {
    const { closeModal } = this.props

    console.log(this.props)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ paddingBottom: 21 }}>
          <div
            style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}
          >
            Are you sure you want to unlink this account?
          </div>
        </div>
        <div style={{ fontSize: 16, width: 498 }}>
          <div style={{ paddingBottom: 30 }}>
            By unlinking this account, there will no longer be an active
            connection between Prospect and Autodesk® BIM&nbsp;360®.
          </div>
          <div style={{ paddingBottom: 34 }}>
            Previously processed files will remain accessible in your Prospect
            library, but no new versions will be imported.
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {this.props && this.props.buttons
            ? this.props.buttons.map((item, key) => {
                const isCancelButton = key === 0
                const warningStyle = {
                  border: '1px solid #F95E5E',
                  color: 'white',
                  backgroundColor: '#F95E5E',
                }
                const cancelStyle = {
                  border: '1px solid #534DC9',
                  color: '#534DC9',
                  backgroundColor: 'white',
                }

                return (
                  <div
                    key={key}
                    style={{ marginRight: isCancelButton ? 10 : 0 }}
                  >
                    <IrisPDLButton
                      style={isCancelButton ? cancelStyle : warningStyle}
                      onClick={() => {
                        closeModal()
                        item.onClick && item.onClick()
                      }}
                      label={item.label}
                    />
                  </div>
                )
              })
            : ''}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeModal: () => {
        alert('asdasdasd')
      },
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(GenericModel)
