/**
 * Configuration base for BIM360 integration
 *
 */
export const getConfig = () => {
    const config = {
        graphQLURI: IRISVR_ENV.retina_graphql_uri
    }
    return config
}
