import {
  UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_REQUEST,
  UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_SUCCESS,
  UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/billing`

export const updatePrimaryContactBySubscriptionIdRequest = () => ({
  type: UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_REQUEST,
})

export const updatePrimaryContactBySubscriptionIdSuccess = (
  subscriptionId,
  primaryContact
) => ({
  type: UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_SUCCESS,
  payload: {
    subscriptionId,
    primaryContact,
  },
})

export const updatePrimaryContactBySubscriptionIdFailure = error => ({
  type: UPDATE_PRIMARY_CONTACT_BY_SUBSCRIPTION_ID_FAILURE,
  payload: { error },
})

/**
 * Function to retrieve users' subscription status via billing service
 * @returns {Object} payload - API response to subscription creation
 */
export const updatePrimaryContactBySubscriptionId = (
  token,
  orgId,
  subscriptionId,
  primaryContactData
) => dispatch => {
  dispatch(updatePrimaryContactBySubscriptionIdRequest())
  const url = `${baseurl}/orgs/${orgId}/subscriptions/${subscriptionId}/contact`
  return request
    .put(url)
    .set('Authorization', `Bearer ${token}`)
    .send(primaryContactData)
    .end()
    .then(parseResponse)
    .then(payload =>
      dispatch(
        updatePrimaryContactBySubscriptionIdSuccess(subscriptionId, payload)
      )
    )
    .catch(err => dispatch(updatePrimaryContactBySubscriptionIdFailure(err)))
}
