import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetPasswordWithVerification } from '../../../../actions'
import { validateOnClient, validateOnServer } from './validate'
import { IrisTextField } from '../../../Elements/IrisFormFields'
import IrisButton from '../../../Elements/IrisButton'
import './ResetPasswordForm.css'

class ResetPasswordForm extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      resetPasswordWithVerification: PropTypes.func.isRequired,
    }).isRequired,
    onPasswordResetConfirm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  resetPassword = data => {
    const { token, username, actions, onPasswordResetConfirm } = this.props
    return actions
      .resetPasswordWithVerification(
        token,
        username,
        data.currentPassword,
        data.newPassword
      )
      .then(validateOnServer)
      .then(onPasswordResetConfirm)
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const submittingClass = submitting ? 'form-submitting' : ''
    return (
      <form
        className={`reset-password-form ${submittingClass}`}
        onSubmit={handleSubmit(this.resetPassword)}
      >
        <div className="form-fields-container">
          <div className="form-fields">
            <IrisTextField
              name="currentPassword"
              label="Current Password"
              type="password"
              fullWidth
            />
            <IrisTextField
              name="newPassword"
              label="New Password"
              type="password"
              fullWidth
            />
            <IrisTextField
              name="confirmNewPassword"
              label="Confirm New Password"
              type="password"
              fullWidth
            />
          </div>
        </div>
        <IrisButton
          buttonText="Reset Password"
          onClick={handleSubmit(this.resetPassword)}
        />
      </form>
    )
  }
}

const ResetPasswordReduxForm = reduxForm({
  form: 'resetPasswordForm',
  validate: validateOnClient,
})(ResetPasswordForm)

const mapStateToProps = state => ({
  token: state.auth.token,
  username: state.auth.user.username,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetPasswordWithVerification,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordReduxForm)
