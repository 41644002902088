import React from 'react'
import PropTypes from 'prop-types'
import './GridHeader.css'

const GridHeader = ({ columns, className = '' }) => (
  <div
    className={`
    grid-header
    grid-row
    ${className}
  `}
  >
    {columns.map(col => (
      <div key={col.label} className="cell-container">
        <p className={col.className}>{col.label}</p>
      </div>
    ))}
  </div>
)

GridHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
}

export default GridHeader
