import generate from 'project-name-generator'
import {
  industryList,
  countriesWithCorrespondingStates,
} from '../../../../util/data'

export const _generateRandomFormFields = () => {
  const fullName = __getRandomCapitalizedWords(2)
  const preferredName = fullName.split(' ')[0]
  const username = `@irisvr.com`
  const company = `${__getRandomCapitalizedWords(2)} Inc.`
  const title = `${__getRandomCapitalizedWords(1)} Manager`
  const industry = __selectRandomArrayElement(industryList)
  const country = __selectRandomArrayElement(
    Object.keys(countriesWithCorrespondingStates)
  )
  const state = __selectRandomArrayElement(
    countriesWithCorrespondingStates[country]
  )
  const city = `${__getRandomCapitalizedWords(1)} City`
  const phoneNumber = __getRandomPhoneNumber()
  const terms = true
  return {
    fullName,
    preferredName,
    username,
    company,
    title,
    industry,
    country,
    state,
    city,
    phoneNumber,
    terms,
  }
}

function __getRandomCapitalizedWords(numWords = 2) {
  return generate({ words: numWords })
    .spaced.split(' ')
    .map(w => w[0].toUpperCase() + w.slice(1))
    .join(' ')
}

function __selectRandomArrayElement(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}

function __getRandomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function __getRandomPhoneNumber() {
  return `${__getRandomIntFromInterval(100, 999)}-${__getRandomIntFromInterval(
    100,
    999
  )}-${__getRandomIntFromInterval(1000, 9999)}`
}
