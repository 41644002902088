/**
 * Component that renders the Loading, Success, or Failing
 * state based on async authentication status.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoadingComponent from './LoadingComponent'

class AsyncAuthComponent extends Component {
  static propTypes = {
    authenticators: PropTypes.shape({
      blockers: PropTypes.func.isRequired,
      background: PropTypes.func.isRequired,
    }).isRequired,
    successComponent: PropTypes.func.isRequired,
    failureComponent: PropTypes.func.isRequired,
  }

  state = { Component: LoadingComponent }

  componentWillMount() {
    const { authenticators, successComponent, failureComponent } = this.props

    // Display success component while background async
    // processes validates cached information
    this.setState({ Component: successComponent })

    authenticators
      .background()
      .catch(e => this.setState({ Component: failureComponent }))
  }

  render() {
    return <this.state.Component {...this.props} />
  }
}

export default AsyncAuthComponent
