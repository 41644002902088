import React from 'react'
import PropTypes from 'prop-types'
import './AddUserButtonContainer.css'

const AddUserButtonContainer = ({ addUser }) => (
  <div className="add-user-button-container">
    <button className="iris-cta-button add-user-button" onClick={addUser}>
      +
    </button>
  </div>
)

AddUserButtonContainer.propTypes = {
  addUser: PropTypes.func.isRequired,
}

export default AddUserButtonContainer
