import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  getCardBySubscriptionId,
  showModal,
} from '../../../../../../../actions'
import { connect } from 'react-redux'
import { _formatCardNumber } from '../../../../../../../util'
import creditCardImg from './creditcard.png'
import './BillingCard.css'

class BillingCard extends Component {
  static propTypes = {
    card: PropTypes.shape({
      id: PropTypes.string.isRequired,
      cardType: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      expiryMonth: PropTypes.number.isRequired,
      expiryYear: PropTypes.number.isRequired,
      last4: PropTypes.string.isRequired,
      maskedNumber: PropTypes.string.isRequired,
      address: PropTypes.shape({}).isRequired,
    }),
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    getCardBySubscriptionId: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.fetchCardInfo()
  }

  fetchCardInfo = () => {
    const { token, orgId, subscriptionId, getCardBySubscriptionId } = this.props
    getCardBySubscriptionId(token, orgId, subscriptionId)
  }

  displayUpdateCreditCardModal = () => {
    const { subscriptionId, card, showModal } = this.props
    showModal('editCreditCardModal', {
      subscriptionId,
      address: (card && card.address) || {},
    })
  }

  render() {
    const { card } = this.props
    const cardExists = card && card.id
    return (
      <div
        className="
        cell-container
        expanded-component-container
        billing-card-container
      "
      >
        <div className="cell-header-container">
          <p className="cell-header">Credit Card</p>
        </div>
        <div className="cell-content-container">
          {cardExists ? (
            <div className="card-representation-container">
              <img className="card-image" alt="card icon" src={creditCardImg} />
              <p className="masked-number">
                {_formatCardNumber(card.maskedNumber)}
              </p>
            </div>
          ) : (
            <p>No Card On File</p>
          )}
        </div>
        <div className="cell-cta-container">
          <a className="cell-cta" onClick={this.displayUpdateCreditCardModal}>
            {cardExists ? 'Update Credit Card' : 'Add Credit Card'}
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: state.auth.token,
  orgId: state.orgs.selectedOrg._id,
  card: state.orgs.cards[ownProps.subscriptionId],
})

export default connect(mapStateToProps, {
  getCardBySubscriptionId,
  showModal,
})(BillingCard)
