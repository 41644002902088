import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ListElement from './ListElement'
import { accessControlLogic, tabs } from './metadata'
import './Sidebar.css'

class Sidebar extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    userAccessType: PropTypes.string.isRequired,
  }

  state = {
    selectedTab: 'Prospect',
    hasIntegrationsEnabledInAdminPortal: false,
  }

  componentWillMount() {
    this.getSelectedTabFromLocation(this.props.location.pathname)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.getSelectedTabFromLocation(nextProps.location.pathname)
    }
  }

  componentDidMount() {
    const url = window.IRISVR_ENV.base_url + '/orgs'
    const token = this.props.token
    const opts = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    fetch(url, opts)
      .then(response => response.json())
      .then(result => {
        const data = result.data
        const orgData = data && data[0]
        if (orgData) {
          const integrationsEnabled = orgData.enable_integrations

          this.setState({
            hasIntegrationsEnabledInAdminPortal: integrationsEnabled,
          })
        }
      })
      .catch(err => console.error(err))
  }

  getSelectedTabFromLocation = pathname => {
    if (pathname) {
      const stripped = pathname.replace(/^\//, '').split('/')[0]
      const selectedTab = stripped.charAt(0).toUpperCase() + stripped.slice(1)
      this.selectTab(selectedTab)
    }
  }

  selectTab = name => this.setState({ selectedTab: name })

  render() {
    const { selectedTab } = this.state
    const { userAccessType, location } = this.props
    const visibleTabs = accessControlLogic[userAccessType || 'teamMember'].map(
      t => tabs[t]
    )

    const enabledVisibleTabs = this.state.hasIntegrationsEnabledInAdminPortal
      ? visibleTabs
      : visibleTabs.filter(tabData => tabData.name !== tabs.integrations.name) // Filter out integrations tab if not enabled for the user org in the admin portal

    return (
      <div className="sidebar">
        <ul className="menu-items">
          {enabledVisibleTabs.map(d => (
            <ListElement
              key={d.name}
              data={d}
              selectTab={this.selectTab}
              isSelected={selectedTab === d.name}
              location={location.pathname}
            />
          ))}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userAccessType: state.auth.userAccessType,
  token: state.auth.token,
})

export default connect(mapStateToProps)(Sidebar)
