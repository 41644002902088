import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  requestSignedUrlForUpload,
  uploadFileToSignedUrl,
  updateOrg,
} from '../../../../../actions'
import IrisTooltip from '../../../../Elements/IrisTooltip'
import LogoViewer from './LogoViewer'
import { _validateCompanyLogo } from './util'
import { _preloadImage } from '../../../../../util'
import './LogoUploadContainer.css'

class LogoUploadContainer extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    orgLogoUrl: PropTypes.string.isRequired,
    requestSignedUrlForUpload: PropTypes.func.isRequired,
    uploadFileToSignedUrl: PropTypes.func.isRequired,
    updateOrg: PropTypes.func.isRequired,
  }

  state = {
    isUploading: false,
    error: '',
  }

  displayFileBrowser = () => this.fileInput.click()

  onFileStage = e => {
    e.stopPropagation()
    e.preventDefault()
    const file = e.target.files[0]
    if (!file) return
    this.setState({ isUploading: true })
    const error = _validateCompanyLogo(file)
    this.setState({ error })
    if (error) return this.setState({ isUploading: false })
    const extension = file.type.split('/')[1]
    setTimeout(
      () =>
        this.requestSignedUrlForUpload(extension)
          .then(url => this.uploadLogoToSignedUrl(url, file))
          .then(this.updateOrgLogo)
          .then(() => this.setState({ isUploading: false }))
          .catch(error => this.setState({ error, isUploading: false })),
      1500
    )
  }

  requestSignedUrlForUpload = extension => {
    const { token, orgId, requestSignedUrlForUpload } = this.props
    return requestSignedUrlForUpload(token, orgId, extension).then(res => {
      if (res.type === 'REQUEST_SIGNED_URL_FOR_UPLOAD_FAILURE') {
        return Promise.reject(res.payload.error.error_message)
      }
      return Promise.resolve(res.payload)
    })
  }

  uploadLogoToSignedUrl = (url, file) => {
    return this.props.uploadFileToSignedUrl(url, file).then(res => {
      if (res.type === 'UPLOAD_FILE_TO_SIGNED_URL_FAILURE') {
        return Promise.reject(res.payload.error)
      }
      return Promise.resolve(res.payload)
    })
  }

  updateOrgLogo = fileLocation => {
    const { token, orgId, updateOrg } = this.props
    const imageUrl = `${fileLocation}?cached=${new Date().getTime()}`
    return updateOrg(token, orgId, { company_logo_url: imageUrl }).then(() =>
      _preloadImage(imageUrl)
    )
  }

  render() {
    const { error, isUploading } = this.state
    const { orgLogoUrl } = this.props

    return (
      <div className="logo-upload-container">
        <div className="label-container">
          <p className="label">Company Logo</p>
          <div className="tooltip-container">
            <IrisTooltip
              id="company-logo-tooltip"
              content="This logo will appear for Prospect Team customers in the top right-hand corner of any registered workstation. The recommended size is 200px by 50px."
              direction="top"
            />
          </div>
        </div>
        <div className="body-container">
          <div className="logo-container">
            <LogoViewer logoUrl={orgLogoUrl} isUploading={isUploading} />
          </div>
          <div className="cta-container">
            {error && <p className="error">{error}</p>}
            <input
              // Hidden uploader for triggering file browser
              style={{ display: 'none' }}
              ref={r => (this.fileInput = r)}
              type="file"
              onChange={this.onFileStage}
            />
            <a className="change-logo" onClick={this.displayFileBrowser}>
              Change logo
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, {
  requestSignedUrlForUpload,
  uploadFileToSignedUrl,
  updateOrg,
})(LogoUploadContainer)
