import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getOrgById,
  updateOrg,
  updateOrgBillingData,
} from '../../../../actions/orgs'
import LogoUploadContainer from './LogoUploadContainer'
import CompanySettingsFormContainer from './CompanySettingsFormContainer'
import IrisTitleContainer from '../../../Elements/IrisTitleContainer'
import './CompanySettings.css'

class CompanySettingsPage extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    org: PropTypes.shape({}).isRequired,
    getOrgById: PropTypes.func.isRequired,
    updateOrg: PropTypes.func.isRequired,
    updateOrgBillingData: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const { token, org, getOrgById } = this.props
    getOrgById(token, org._id)
  }

  updateGeneralOrgData = data => {
    const { token, org, updateOrg } = this.props
    return updateOrg(token, org._id, data)
  }

  updateOrgBillingData = data => {
    const { token, org, updateOrgBillingData } = this.props
    return updateOrgBillingData(token, org._id, data)
  }

  render() {
    const { org, token } = this.props
    return (
      <div className="company-settings-page app-inner-content">
        <div className="company-settings-view-container">
          <IrisTitleContainer title="Company Settings" />
          {org._id && (
            <div>
              <LogoUploadContainer
                token={token}
                orgId={org._id}
                orgLogoUrl={org.company_logo_url}
              />
              <CompanySettingsFormContainer
                orgData={org}
                updateGeneralOrgData={this.updateGeneralOrgData}
                updateOrgBillingData={this.updateOrgBillingData}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  org: state.orgs.selectedOrg,
})

export default connect(mapStateToProps, {
  getOrgById,
  updateOrg,
  updateOrgBillingData,
})(CompanySettingsPage)
