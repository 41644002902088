import React from 'react'
import PropTypes from 'prop-types'
import './Footer.css'

const Footer = ({ children, className = '' }) => (
  <div className={`page-footer ${className}`}>{children}</div>
)

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Footer
