import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import './IrisButton.css'

class IrisButton extends React.Component {
  state = {
    submitting: false,
    buttonStyles: {},
  }

  componentDidMount() {
    const { width, height } = this.button.getBoundingClientRect()
    this.setState({ buttonStyles: { width, height } })
  }

  onClickHandler = () => {
    this.setState({ submitting: true })
    return Promise.resolve(this.props.onClick()).then(() =>
      this.setState({ submitting: false })
    )
  }

  render() {
    const { submitting, buttonStyles } = this.state
    const { className, buttonText } = this.props

    return (
      <div className="iris-button-container">
        <button
          ref={b => (this.button = b)}
          style={buttonStyles}
          className={`iris-cta-button ${className}`}
          onClick={this.onClickHandler}
          disabled={submitting}
        >
          {submitting ? (
            <CircularProgress className="loading-gif" size={15} />
          ) : (
            buttonText
          )}
        </button>
      </div>
    )
  }
}

IrisButton.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default IrisButton
