import React from 'react'
import PropTypes from 'prop-types'
import './ConfirmAccountView.css'

const ConfirmAccountView = ({ username }) => (
  <div className="confirm-account-view-container iris-outline">
    <div className="confirm-account-view">
      <div className="form-title-container">
        <p className="form-title">Thank you for signing up</p>
      </div>
      <div className="body-container">
        <p className="body-text">
          Please check your email <span className="emphasis">{username}</span>
        </p>
        <p className="body-text">
          for a confirmation link to activate your subscription.
        </p>
        <p className="body-text spam-text">
          Can't find the email? Try checking your spam folder.
        </p>
      </div>
    </div>
  </div>
)

ConfirmAccountView.propTypes = {
  username: PropTypes.string.isRequired,
}

export default ConfirmAccountView
