import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './AdminMode.css'

class AdminMode extends Component {
  static propTypes = {
    isUsingViewAsFeature: PropTypes.bool.isRequired,
    username: PropTypes.string,
  }

  state = { visible: false }

  componentWillMount() {
    this.setState({ visible: this.props.isUsingViewAsFeature })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.isUsingViewAsFeature })
  }

  hideWarning = () => this.setState({ visible: false })

  render() {
    const { visible } = this.state
    const { username } = this.props

    if (!visible || !username) return null

    return (
      <div className="admin-mode">
        <p className="disclaimer-text">
          You are now logged in as &lt;{username}&gt;. Please proceed with
          caution!
        </p>
        <i className="material-icons close-icon" onClick={this.hideWarning}>
          close
        </i>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isUsingViewAsFeature: state.auth.isUsingViewAsFeature,
  username: state.user.data && state.user.data.username,
})

export default connect(mapStateToProps)(AdminMode)
