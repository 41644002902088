import React from 'react'
import PropTypes from 'prop-types'
import Reaptcha from 'reaptcha'
import './Recaptcha.css'

const Recaptcha = ({ onVerify }) => (
  <div className="recaptcha-container">
    <Reaptcha
      sitekey={window.IRISVR_ENV.google_recaptcha_site_key}
      onVerify={onVerify}
    />
  </div>
)

Recaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
}

export default Recaptcha
