import superagent from 'superagent'
import bluebird from 'bluebird'
import superagentPromise from 'superagent-promise'

// Helper for making AJAX requests
export const request = superagentPromise(superagent, bluebird)

// Helper for parsing response body
export const parseResponse = res => {
  // If it's an Iris Response
  if (res.body.meta) {
    // If the code is anything other than 0
    if (res.body.meta.code) {
      return Promise.reject(res.body.meta)
    }
    // If the code is 0, and the res is succesful
    return Promise.resolve(res.body.data)
  }
  // If it's an uncategorized error
  return Promise.reject(new Error('Fatal Server Error'))
}

// Helper for retrieving base URL
export const getBaseUrl = () => window.IRISVR_ENV.base_url
