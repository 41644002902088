import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  reduxForm,
  getFormSyncErrors,
  getFormSubmitErrors,
  formValueSelector,
} from 'redux-form'
import {
  IrisTextField,
  IrisDropdown,
  IrisCheckbox,
} from '../../../../Elements/IrisFormFields'
import IrisButton from '../../../../Elements/IrisButton'
import IrisTooltip from '../../../../Elements/IrisTooltip'
import {
  industryList,
  countriesWithCorrespondingStates,
} from '../../../../../util/data'
import TOCLabel from '../../TOCLabel'
import Recaptcha from './Recaptcha'
import { validateOnClient } from './validate'
import './SignupForm.css'

class SignupForm extends Component {
  static propTypes = {
    dummyValues: PropTypes.shape({}).isRequired,
    country: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    submitSucceeded: PropTypes.bool.isRequired,
    submitErrors: PropTypes.shape({}).isRequired,
    serverErrors: PropTypes.shape({}).isRequired,
    signupUser: PropTypes.func.isRequired,
  }

  state = {
    countries: [],
    states: [],
    devDummyMode: false,
    recaptchaVerified: false,
  }

  componentWillMount() {
    this.setState({ countries: Object.keys(countriesWithCorrespondingStates) })

    // Give precedence to initialValues
    const {
      dummyValues: { country: initialCountry, state },
    } = this.props
    if (initialCountry && state) {
      this.setState({ devDummyMode: true })
      this.onCountrySelect(null, initialCountry)
    } else {
      // If cached values are found, select on refresh
      const { country: cachedCountry } = this.props
      if (cachedCountry) this.onCountrySelect(null, cachedCountry)
    }
  }

  onFullNameBlur = (_, fullName) =>
    fullName && this.props.change('preferredName', fullName.split(' ')[0])

  onCountrySelect = (_, country) => {
    const states = countriesWithCorrespondingStates[country]
    if (states) this.setState({ states })
  }

  onVerify = () => this.setState({ recaptchaVerified: true })

  handleSubmit = data => {
    if (!this.state.recaptchaVerified) return
    return this.props.signupUser(data)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      submitFailed,
      submitSucceeded,
      submitErrors,
      serverErrors,
    } = this.props
    const { countries, states, devDummyMode, recaptchaVerified } = this.state

    const submittingClass = submitting ? 'form-submitting' : ''

    const termsError =
      submitFailed && (submitErrors.terms || serverErrors.terms)

    const verifyError =
      submitSucceeded && !recaptchaVerified && 'Verification required'

    return (
      <div className="signup-form-container">
        <form
          className={`signup-form iris-form iris-outline ${submittingClass}`}
          onSubmit={handleSubmit(this.handleSubmit)}
        >
          <div className="form-title-container">
            <p className="form-title">Create your IrisVR account</p>
          </div>
          <div className="form-fields-container">
            <div className="form-fields">
              <IrisTextField
                name="fullName"
                label="Full Name"
                autoFocus={!devDummyMode}
                onBlur={this.onFullNameBlur}
                fullWidth
              />
            </div>
            <div className="form-fields">
              <div className="tooltip-container">
                <IrisTooltip
                  id="preferred-name-tooltip"
                  content="How would you like to be addressed?"
                />
              </div>
              <IrisTextField
                name="preferredName"
                label="Preferred Name"
                fullWidth
              />
            </div>
            <div className="form-fields">
              <IrisTextField
                name="username"
                label="Work Email"
                fullWidth
                autoFocus={devDummyMode}
              />
            </div>
            <div className="form-fields half">
              <IrisTextField name="password" label="Password" type="password" />
              <IrisTextField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
              />
            </div>
            <div className="form-fields">
              <IrisTextField name="company" label="Company" fullWidth />
            </div>
            <div className="form-fields half">
              <IrisTextField name="title" label="Job Title" />
              <IrisDropdown
                data={industryList}
                fieldName="industry"
                label="Industry"
              />
            </div>
            <div className="form-fields half">
              <IrisDropdown
                data={countries}
                fieldName="country"
                label="Country"
                onChange={this.onCountrySelect}
              />
              <IrisDropdown
                data={states}
                fieldName="state"
                label="State / Region"
              />
            </div>
            <div className="form-fields half">
              <IrisTextField name="city" label="City" />
              <IrisTextField
                name="phoneNumber"
                label="Phone Number"
                fullWidth
              />
            </div>
            <div className="checkboxes-container">
              <IrisCheckbox
                fieldName="emailSubscription"
                labelContent="Keep me posted on IrisVR news and product updates"
              />
              <IrisCheckbox fieldName="terms" labelContent={<TOCLabel />} />
              {termsError && <span className="terms-error">{termsError}</span>}
            </div>
          </div>
          <Recaptcha onVerify={this.onVerify} />
          {verifyError && <span className="terms-error">{verifyError}</span>}
          <IrisButton
            className="signup-button"
            buttonText="Signup"
            onClick={handleSubmit(this.handleSubmit)}
          />
          <div className="login-container">
            <p className="login-text">
              Already have an account?{' '}
              <Link className="login-text-cta" to="/login">
                Log in
              </Link>
            </p>
          </div>
        </form>
      </div>
    )
  }
}

const SignupReduxForm = reduxForm({
  form: 'signupUserForm',
  validate: validateOnClient,
  enableReinitialize: true,
})(SignupForm)

const selector = formValueSelector('signupUserForm')

const SignupReduxFormWithError = connect((state, ownProps) => {
  const country = selector(state, 'country')
  return {
    initialValues: ownProps.dummyValues,
    country,
    submitErrors: getFormSyncErrors('signupUserForm')(state),
    serverErrors: getFormSubmitErrors('signupUserForm')(state),
  }
})(SignupReduxForm)

export default SignupReduxFormWithError
