import React from 'react'
import { connect } from 'react-redux'
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import { GetClient } from '../../client'
import { GET_FEATURE_FLAGS_QUERY } from './queries'
import ChecklistView from './checklist'
import { ErrorDisplay } from '../../../iris-ui/errors'
import { setDebugFeatureFlag, clearFeatureFlagsForUser } from './mutations'

var sampleRows = [
  {
    name: 'Feature 1',
    enabled: true,
    description: 'Feature1 description',
  },
  {
    name: 'Feature 2',
    enabled: false,
    description: 'Feature2 description',
  },
  {
    name: 'Feature 3',
    enabled: true,
    description: 'Feature3 description',
  },
  {
    name: 'Feature 4',
    enabled: false,
    description: 'Feature4 description',
  },
]

// Base component for the UI, configures ApolloProvider which provides props to children
const BaseView = props => (
  <div
    style={{
      position: 'absolute',
      left: 260,
      top: 60,
    }}
  >
    <div
      style={{
        marginLeft: 60,
        color: '#534DC9',
        marginTop: 48,
        marginRight: 48,
      }}
    >
      <h1>
        <div style={{ fontSize: 42 }}>Debug Feature Toggles</div>
      </h1>
    </div>
    <div
      style={{
        marginLeft: 60,
        marginRight: 48,
        marginBottom: 48,
      }}
    >
      <div>
        <p>
          These are toggles that enable developer features that will only affect
          you. Typically they will be used to force error states that might
          otherwise have lengthy setup/steps to reproduce.
        </p>
        <p>
          <b>WARNING:</b>
          At this time, the feature flags are stored on the instance and there
          is no gurantee they will persist the next time you hit this endpoint.
        </p>
      </div>
    </div>

    <div
      style={{
        minWidth: 800,
      }}
    >
      <BaseViewRaw
        token={props.token}
        refetch={props.refetch}
        data={props.data}
      />
    </div>
  </div>
)

// Base component for the UI, configures ApolloProvider which provides props to children
const BaseViewRaw = props => (
  <ApolloProvider client={GetClient({ token: props.token })}>
    <div>
      <div
        style={{
          position: 'absolute',
          top: -10,
          left: 10,
          cursor: 'default',
        }}
      >
        <h3>Debug Feature Flags</h3>
      </div>
      <button
        style={{
          borderRadius: 8,
          zIndex: 2,
          border: 0,
        }}
        onClick={_ =>
          clearFeatureFlagsForUser({
            onFailure: err => {
              console.error(err)
              props.refetch()
            },
            onSuccess: () => {
              props.refetch()
            },
          })
        }
      >
        Reset My Feature Flags to Defaults
      </button>
    </div>
    <ChecklistView
      entries={props.data}
      setFlagEnabled={payload =>
        setDebugFeatureFlag({
          name: payload.name,
          enabled: payload.enabled,
          onFailure: err => {
            console.error(err)
            props.refetch()
          },
          onSuccess: () => {
            props.refetch()
          },
        })
      }
    />
  </ApolloProvider>
)

const QueriedBaseView = props => {
  const { loading, error, data, refetch } = useQuery(GET_FEATURE_FLAGS_QUERY, {
    variables: {},
  })
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <ErrorDisplay error={error} />
  }

  return (
    <BaseView
      data={data.me.debug.featureFlags}
      token={props.token}
      refetch={refetch}
    />
  )
}
const QueriedBaseViewRaw = props => {
  const { loading, error, data, refetch } = useQuery(GET_FEATURE_FLAGS_QUERY, {
    variables: {},
  })
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <ErrorDisplay error={error} />
  }

  return (
    <BaseViewRaw
      data={data.me.debug.featureFlags}
      token={props.token}
      refetch={refetch}
    />
  )
}

export const DebugFeaturesToggleListRaw = connect(
  state => ({
    token: state.auth.token,
  }),
  dispatch => ({
    // Nothing to dispatch currently
  })
)(QueriedBaseViewRaw)

// Connect the Base UI to the redux store, since our app uses that as the primary data store
export const DebugFeaturesToggleList = connect(
  state => ({
    token: state.auth.token,
  }),
  dispatch => ({
    // Nothing to dispatch currently
  })
)(QueriedBaseView)

export default DebugFeaturesToggleList
