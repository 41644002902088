import {
  GET_ZENDESK_SSO_URL_REQUEST,
  GET_ZENDESK_SSO_URL_SUCCESS,
  GET_ZENDESK_SSO_URL_FAILURE,
} from '../../constants'
import { request, parseResponse, getBaseUrl } from '../util'
const baseurl = `${getBaseUrl()}/auth`

export const getZendeskSSOUrlRequest = () => ({
  type: GET_ZENDESK_SSO_URL_REQUEST,
})

export const getZendeskSSOUrlSuccess = payload => {
  return {
    type: GET_ZENDESK_SSO_URL_SUCCESS,
    payload,
  }
}

export const getZendeskSSOUrlFailure = error => {
  return {
    type: GET_ZENDESK_SSO_URL_FAILURE,
    payload: { error },
  }
}

export const getZendeskSSOUrl = user => dispatch => {
  dispatch(getZendeskSSOUrlRequest())
  const url = `${baseurl}/sso/zendesk`
  return request
    .post(url)
    .type('json')
    .send(user)
    .end()
    .then(parseResponse)
    .then(payload => dispatch(getZendeskSSOUrlSuccess(payload)))
    .catch(e => dispatch(getZendeskSSOUrlFailure(e)))
}
