import React from 'react'
import PropTypes from 'prop-types'
import IrisButton from '../../../Elements/IrisButton'

const ModalContent = ({ released, splitKey, renderCtaText, onClick }) => (
  <div>
    <div className="modal-title-container">
      <p className="modal-title">
        {released ? 'Workstation Key Released' : 'Release Workstation Key'}
      </p>
    </div>
    <div className="modal-body-container">
      <p className="body-content">
        {released
          ? 'The following key can be used to register a machine as an IrisVR Workstation'
          : 'This will de-register this machine as an IrisVR Workstation and will result in loss of files in the library.'}
      </p>
      {released && (
        <div className="workstation-key-container">
          {splitKey.map(k => (
            <p className="workstation-key" key={k}>
              {k}
            </p>
          ))}
        </div>
      )}
    </div>
    <IrisButton buttonText={renderCtaText()} onClick={onClick} />
  </div>
)

ModalContent.propTypes = {
  released: PropTypes.bool.isRequired,
  splitKey: PropTypes.array.isRequired,
  renderCtaText: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ModalContent
