import {
  GET_PERSONAL_USER_DATA_SUCCESS,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  VERIFY_TOKEN_SUCCESS,
  GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS,
  GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS,
  CONFIRM_USER_EMAIL_SUCCESS,
  CONFIRM_USER_EMAIL_FAILURE,
} from '../constants'
import { createReducer } from './util'

const initialState = {
  token: '',
  user: {
    _id: '',
    username: '',
  },
  isAuthenticated: false,
  isUsingViewAsFeature: false,
  userAccessType: '',
  confirmation: {
    status: false,
    error: '',
  },
}

export default createReducer(initialState, {
  [LOGIN_USER_REQUEST]: (state, payload) => {
    return { ...state }
  },
  [LOGIN_USER_SUCCESS]: (state, payload) => {
    return {
      ...state,
      token: payload.token,
      user: payload.user,
      isAuthenticated: true,
    }
  },
  [LOGIN_USER_FAILURE]: (state, payload) => {
    return initialState
  },
  [LOGOUT_USER]: (state, payload) => {
    return initialState
  },
  [CONFIRM_USER_EMAIL_SUCCESS]: (state, payload) => {
    return {
      ...state,
      confirmation: {
        status: true,
        error: '',
      },
    }
  },
  [CONFIRM_USER_EMAIL_FAILURE]: (state, payload) => {
    const { error } = payload
    return {
      ...state,
      confirmation: {
        status: false,
        error: error.error_message,
      },
    }
  },
  [VERIFY_TOKEN_SUCCESS]: (state, payload) => {
    return {
      ...state,
      token: payload.token,
      user: payload.user,
      isAuthenticated: true,
    }
  },
  [GET_PERSONAL_USER_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      user: {
        _id: payload.data._id,
        username: payload.data.username,
      },
    }
  },
  [GET_PERSONAL_USER_SUBSCRIPTION_STATUS_SUCCESS]: (state, payload) => {
    return { ...state, userAccessType: 'personalUser' }
  },
  [GET_USER_SUBSCRIPTION_STATUS_WITHIN_ORG_SUCCESS]: (state, payload) => {
    const { role } = payload
    let userAccessType = ''
    switch (role) {
      case 1:
        userAccessType = 'teamOwner'
        break
      case 2:
        userAccessType = 'teamAdmin'
        break
      default:
        userAccessType = 'teamMember'
        break
    }
    return { ...state, userAccessType }
  },
})
