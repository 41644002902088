import React, { Component } from 'react'
import PropTypes from 'prop-types'
import metadata from './metadata'
import { connect } from 'react-redux'
import {
  startPersonalUserTrial,
  startOrgUserTrial,
  getPersonalUserSubscriptionStatus,
  getUserSubscriptionStatusWithinOrg,
} from '../../../../../actions'
import IrisButton from '../../../../Elements/IrisButton'
import { _capitalize } from '../../../../../util'
import './TrialStartView.css'

class TrialStartView extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    getPersonalUserSubscriptionStatus: PropTypes.func.isRequired,
    getUserSubscriptionStatusWithinOrg: PropTypes.func.isRequired,
    startPersonalUserTrial: PropTypes.func.isRequired,
    startOrgUserTrial: PropTypes.func.isRequired,
  }

  state = { error: '' }

  startTrial = () => {
    const {
      token,
      userId,
      orgId,
      productType,
      userType,
      startPersonalUserTrial,
      startOrgUserTrial,
      getPersonalUserSubscriptionStatus,
      getUserSubscriptionStatusWithinOrg,
    } = this.props

    return userType === 'org'
      ? startOrgUserTrial(token, productType, orgId)
          .then(res => {
            this.handleTrialStartError(res, productType)
            return getUserSubscriptionStatusWithinOrg(token, orgId, userId)
          })
          .then(() => this.handleProspectDownload(productType))
          .catch(this.handleError)
      : startPersonalUserTrial(token, productType)
          .then(res => {
            this.handleTrialStartError(res, productType)
            return getPersonalUserSubscriptionStatus(token)
          })
          .then(() => this.handleProspectDownload(productType))
          .catch(this.handleError)
  }

  handleTrialStartError = (res, productType) => {
    if (
      res.type === 'START_ORG_USER_TRIAL_FAILURE' ||
      res.type === 'START_PERSONAL_USER_TRIAL_FAILURE'
    ) {
      const {
        error: { code, error_message: errorMessage },
      } = res.payload
      if (code === 701)
        throw new Error(
          `Our records indicate that your account has already had a free trial of ${_capitalize(
            productType
          )}. If you think this is an error please contact accounts@irisvr.com.`
        )
      throw new Error(errorMessage)
    }
  }

  handleError = e => this.setState({ error: e.message })

  handleProspectDownload = productType =>
    new Promise((resolve, reject) => {
      if (productType === 'prospect')
        window.location =
          'https://prospect-releases.irisvr.com/ProspectSetup.exe'
      resolve()
    })

  render() {
    const { error } = this.state
    const { productType } = this.props
    const data = metadata[productType]
    return (
      <div className="trial-start-view-container">
        <div className="image-container">
          <img src={data.trialImage} alt={data.altText} />
        </div>
        <div className="description-container">
          <p>{data.description}</p>
        </div>
        <IrisButton buttonText={data.ctaText} onClick={this.startTrial} />
        {error && (
          <div className="error-container">
            <p className="error">{error}</p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  userId: state.user.data._id,
  orgId: state.orgs.selectedOrg._id,
})

export default connect(mapStateToProps, {
  startPersonalUserTrial,
  startOrgUserTrial,
  getPersonalUserSubscriptionStatus,
  getUserSubscriptionStatusWithinOrg,
})(TrialStartView)
