import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '../../../Shared/Grid'
import GridHeader from '../../../Shared/Grid/GridHeader'
import MemberLicenseGridRowList from './MemberLicenseGridRowList'
import {
  _extractRelevantMetadata,
  _atLeastOneWorkstationIsActive,
} from './util'

class MembersGrid extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    workstations: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  }

  state = { members: [] }

  componentWillMount() {
    const { members, workstations } = this.props
    this.extractRelevantMetadata(members, workstations)
  }

  componentWillReceiveProps(nextProps) {
    const { members, workstations } = nextProps
    this.extractRelevantMetadata(members, workstations)
  }

  extractRelevantMetadata = (members, workstations) => {
    if (members.length) {
      const extractedMembers = _extractRelevantMetadata(members)
      if (workstations.length && _atLeastOneWorkstationIsActive(workstations)) {
        // If an org owns a workstation, all underlying members should be granted access ...
        extractedMembers.map(m => {
          // ... as long as they don't have a personal license assigned to them
          if (m.accessList === 'None') m.accessList = 'Workstation'
        })
      }
      this.setState({ members: extractedMembers })
    }
  }

  render() {
    const { token, orgId } = this.props
    const { members } = this.state

    return (
      <div className="members-grid-container">
        <Grid>
          <GridHeader
            columns={[
              {
                label: 'User',
                className: 'member-username',
              },
              {
                label: 'Role',
                className: 'member-role',
              },
              {
                label: 'Access',
                className: 'member-access-status',
              },
            ]}
          />
          <MemberLicenseGridRowList
            token={token}
            orgId={orgId}
            rowData={members}
            rowColumns={[
              {
                dataKey: 'username',
                className: 'user-username',
              },
              {
                dataKey: 'roleReadable',
                className: 'user-role',
              },
              {
                dataKey: 'accessList',
                className: 'user-access',
              },
            ]}
          />
        </Grid>
      </div>
    )
  }
}

export default MembersGrid
