import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addUserToOrg, updateUserRole } from '../../../actions/orgs/users'
import AddUserToOrgForm from './AddUserToOrgForm'

class AddUserToOrgModal extends Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    addUserToOrg: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
  }

  addUserToOrg = email => {
    const { token, orgId, addUserToOrg } = this.props
    return addUserToOrg(token, orgId, email)
  }

  updateUserRole = (role, userId) => {
    const { token, orgId, updateUserRole } = this.props
    return updateUserRole(token, orgId, userId, role)
  }

  render() {
    const { users } = this.props
    return (
      <div className="add-user-to-org-modal">
        <div className="modal-title-container">
          <p className="modal-title">Add User</p>
        </div>
        <div className="modal-body-container">
          <AddUserToOrgForm
            addUserToOrg={this.addUserToOrg}
            updateUserRole={this.updateUserRole}
            users={users}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: state.orgs.orgEntities.users,
})

export default connect(mapStateToProps, {
  addUserToOrg,
  updateUserRole,
})(AddUserToOrgModal)
