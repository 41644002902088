import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showModal } from '../../../../../actions/modals'
import GridRow from '../../../../Shared/Grid/GridRowList/GridRow'
import LicenseIcon from '../../../../Shared/Grid/GridRowList/LicenseGridRowList/LicenseIcon'

class AdminWorkstationLicenseGridRowList extends Component {
  static propTypes = {
    rowData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        iconType: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
      })
    ).isRequired,
    rowColumns: PropTypes.arrayOf(
      PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    showModal: PropTypes.func.isRequired,
  }

  copyWorkstationKey = workstation =>
    this.props.showModal('copyWorkstationKey', {
      workstationKey: workstation.workstationKey,
    })

  releaseWorkstationKey = workstation =>
    this.props.showModal('releaseWorkstationKey', {
      workstationId: workstation.entityId,
      workstationKey: workstation.workstationKey,
    })

  renameWorkstation = workstation =>
    this.props.showModal('renameWorkstation', {
      workstationId: workstation.entityId,
    })

  getMenuOptions = row => {
    const registrationOption = { label: '', onClick: null }
    if (row.workstationIsRegistered) {
      registrationOption.label = 'Release Workstation Key'
      registrationOption.onClick = this.releaseWorkstationKey.bind(null, row)
    } else {
      registrationOption.label = 'Register Workstation'
      registrationOption.onClick = this.copyWorkstationKey.bind(null, row)
    }
    const renameOption = {
      label: 'Rename Workstation',
      onClick: this.renameWorkstation.bind(null, row),
    }
    return [registrationOption, renameOption]
  }

  render() {
    const { rowData, rowColumns } = this.props
    return (
      <div className="row-data-list-container license-grid-row-list">
        {rowData.map(r => (
          <GridRow
            key={r.id}
            rowMeta={r}
            rowColumns={rowColumns}
            icon={() => <LicenseIcon iconType={r.iconType} />}
            menuOptions={this.getMenuOptions(r)}
          />
        ))}
      </div>
    )
  }
}

export default connect(null, { showModal })(AdminWorkstationLicenseGridRowList)
