const __validateFileType = type =>
  !['image/png', 'image/jpeg'].includes(type)
    ? `File must be .png, .jpg, or .jpeg`
    : ''

const __validateFileSize = size =>
  Math.ceil(size / 1000000) > 4
    ? 'File must not exceed 4MB. Please resize your image.'
    : ''

export const _validateCompanyLogo = file =>
  __validateFileType(file.type) || __validateFileSize(file.size) || ''
