import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  logoutUser,
  resetPasswordWithToken,
  fetchRelevantUserAndOrgData,
} from '../../../../actions'
import ResetPasswordForm from './ResetPasswordForm'
import { validateOnServer } from './ResetPasswordForm/validate'
import { _searchToJson } from '../../../../util'
import './ResetPassword.css'

class ResetPassword extends Component {
  static contextTypes = {
    router: PropTypes.shape({}).isRequired,
  }

  static propTypes = {
    token: PropTypes.string.isRequired,
    location: PropTypes.shape({}).isRequired,
    orgs: PropTypes.shape({}).isRequired,
    logoutUser: PropTypes.func.isRequired,
    resetPasswordWithToken: PropTypes.func.isRequired,
    fetchRelevantUserAndOrgData: PropTypes.func.isRequired,
  }

  state = {
    resetToken: '',
  }

  componentWillMount() {
    const { location, logoutUser } = this.props

    // Clear auth store
    logoutUser()

    // Examine URL query params
    const searchQueries = _searchToJson(location.search)
    this.setState({
      resetToken: searchQueries.token,
    })
  }

  resetPassword = data => {
    const { resetPasswordWithToken, fetchRelevantUserAndOrgData } = this.props
    const { resetToken } = this.state
    if (!data.password) return
    return (
      resetPasswordWithToken(resetToken, data.password)
        .then(validateOnServer)
        // Auto-fetch relevant data and redirect
        // upon successful password reset
        .then(() => {
          const { token } = this.props
          return fetchRelevantUserAndOrgData(token)
        })
        .then(() => {
          const { orgs } = this.props
          // Handle single org auto-selection
          if (orgs.length <= 1) {
            this.context.router.history.push('/prospect')
          } else if (orgs.length > 1) {
            this.context.router.history.push('/orgs')
          }
        })
    )
  }

  render() {
    return (
      <div className="reset-password-with-token-page">
        <ResetPasswordForm resetPassword={this.resetPassword} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  orgs: state.orgs.orgs,
})

export default connect(mapStateToProps, {
  logoutUser,
  resetPasswordWithToken,
  fetchRelevantUserAndOrgData,
})(ResetPassword)
